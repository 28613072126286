import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import EsfirusSelect from '@ui/Select/EsfirusSelect';
import DatePickerUI from '../../../../../components/ui/DatePickerUI/DatePickerUI';

import { isAdmin, isSupervisor } from '@services/hooks/UserHook';
import { usersService } from '@services/users';
import { TIPOS_ESTADOS_PEDIDOS } from '../../constants';

import "./ListadoPedidosFilters.scss"
import { getInternalReference } from '@helpers/companyData';

const ListadoPedidosFilters = ({ filters, setFilters }: any) => {
  const empresas = useSelector((state) => (state as any).empresas).list
  const userCanSupervise = isAdmin() || isSupervisor()
  const [empleadosList, setEmpleadosList] = useState([])

  // Opciones del filtro de estado
  const statusOptions = Object.values(TIPOS_ESTADOS_PEDIDOS).map(status => ({ label: status, value: status }))

  // Función que recoge todos los empleados de la BBDD.
  const fetchEmpleados = async () => {
    try {
      const resp = await usersService.getAll()
      const empleados = resp?.data?.data ?? []
      const empleadosWithData = empleados.map((empleado: any) => ({ ...empleado, data: JSON.parse(empleado.data) }))
      const empleadosOptions = empleadosWithData.map((empleado: any) => {
        const idEmpresaInterna = empleado.data.internal[0].empresa
        const idEmpleadoInterno = empleado.data.internal[0].empleado
        const empresaObject = empresas.find((empresa: any) => empresa.Empresa === idEmpresaInterna)

        const label = `${empleado.nombre} ${empleado.apellidos} - ${empresaObject.Nombre}`
        return ({
          label,
          value: idEmpleadoInterno,
        })
      })
      setEmpleadosList(empleadosOptions)
    } catch (error) {
      setEmpleadosList([])
    }
  }

  useEffect(() => {
    if (userCanSupervise) {
      fetchEmpleados()
    } else {
      const internalEmployeeId = getInternalReference()
      if (filters.empleado !== internalEmployeeId) {
        setFilters((prevFilters: any) => ({ ...prevFilters, empleado: internalEmployeeId }))
      }
    }
  }, [empresas])

  return (
    <div className='ef-pedidos-filtros'>
      <div className="ef-left">
        {/* Filtro de empresa */}
        {userCanSupervise && (
          <EsfirusSelect
            placeholder='Seleccionar Empresa'
            options={empresas.map((e: any) => ({ label: e.Nombre, value: e.Empresa }))}
            value={filters.empresa}
            change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, empresa: e }))}
          />
        )}

        {/* Filtro de fecha inicio */}
        <DatePickerUI
          className='datePickerFilter'
          value={filters.dateFrom}
          handleChange={newDate => {
            const dateSelected = moment({ day: newDate.$D, month: newDate.$M, year: newDate.$y })
            const isCorrect = !filters.dateTo || dateSelected.isBefore(filters.dateTo)
            if (isCorrect) setFilters((prevFilters: any) => ({ ...prevFilters, dateFrom: dateSelected }))
          }}
        />

        {/* Filtro de fecha fin */}
        <DatePickerUI
          className='datePickerFilter'
          value={filters.dateTo}
          handleChange={newDate => {
            const dateSelected = moment({ day: newDate.$D, month: newDate.$M, year: newDate.$y })
            const isCorrect = !filters.dateFrom || dateSelected.isAfter(filters.dateFrom)
            if (isCorrect) setFilters((prevFilters: any) => ({ ...prevFilters, dateTo: dateSelected }))
          }}
        />

        {/* Filtro de empleado */}
        {userCanSupervise && (
          <EsfirusSelect
            options={empleadosList}
            value={filters.empleado}
            change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, empleado: e }))}
            placeholder="Todos los Empleados"
          />
        )}

        {/* Filtro de estado */}
        <EsfirusSelect
          options={statusOptions}
          value={filters.status}
          change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, status: e }))}
          placeholder="Todos los Estados"
        />
      </div>
    </div>
  );
}

export default ListadoPedidosFilters;