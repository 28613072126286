import {Perfil} from './Perfil'
import { PerfilProvider } from './context/Perfil.context'

const PerfilWithProvider = (props) => {
    
    return (
        <PerfilProvider>
            <Perfil {...props} />
        </PerfilProvider>
    )

}
export default PerfilWithProvider