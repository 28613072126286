import React, { createContext, useState, useEffect } from 'react'
const PerfilContext = createContext()

const PerfilProvider = ({ children }) => {
    
    const [state, setState] = useState({
        datos_usuario: {
            email: '',  
            nombre: '',  
            apellidos: '',         
            rol: '',   
            activo: 1 
        },
        extra_external : [
            {
                empresa : '',
                empleado : '',
            }
        ],
        extra_internal : [
            {
                empresa : '',
                empleado : '',
            }
        ] 
        
    })  

    return (
        <PerfilContext.Provider value={{ state, setState}}>
            {children}
        </PerfilContext.Provider>
    )
}

export { PerfilContext, PerfilProvider }
