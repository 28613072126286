import { createSlice } from "@reduxjs/toolkit";
import { articulosGastosServiciosService } from "@services/articulos-gastos-servicios";

const initial = {
  list: [],
};


export const articulosGastosServiciosSlice = createSlice({
  name: "articulosGastosServicios",
  initialState: { ...initial },
  reducers: {
    resetArticulos: (state, action: any) => {
      state.list = [...initial.list];
    },
    setarticulosGastosServicios: (state, action: any) => {
      state.list = [...action.payload] as any;
    },
  },
});

export default articulosGastosServiciosSlice.reducer;
export const { setarticulosGastosServicios, resetArticulos } =
  articulosGastosServiciosSlice.actions;
