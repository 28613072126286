import { ChangeEvent, ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react"

export interface EsfirusTablePropsPagination {
  page: number
  sizePage: number
}

interface IntervalItemsProps {
  initial: number
  final: number
}

export type HandleChangePaginationType = (data: EsfirusTablePropsPagination) => void

interface UseHandlerPaginationTableProps {
  customPagination?: EsfirusTablePropsPagination
  totalItems: number
  handleChangePagination?: HandleChangePaginationType
}

interface UseHandlerPaginationTableResult {
  isFirstPage: boolean
  isLastPage: boolean
  intervalItems: IntervalItemsProps
  sizePage: number
  goNextPage: MouseEventHandler<HTMLButtonElement>;
  goBeforePage: MouseEventHandler<HTMLButtonElement>;
  handleChangePageSize: ChangeEventHandler<HTMLSelectElement>;
}

const useHandlerPaginationTable = ({ customPagination, totalItems, handleChangePagination }: UseHandlerPaginationTableProps): UseHandlerPaginationTableResult => {
  const [pagination, setPagination] = useState<EsfirusTablePropsPagination>(customPagination ?? { page: 0, sizePage: 50 })
  const [intervalItems, setIntervalItems] = useState<IntervalItemsProps>({ initial: 1, final: 50 })
  const [totalPages, setTotalPages] = useState<number>(0)

  useEffect(() => {
    const newTotalPages = Math.ceil(totalItems / pagination.sizePage)

    const newInitial = pagination.page * pagination.sizePage + 1
    const theoricFinal = (pagination.page + 1) * pagination.sizePage
    const newFinal = theoricFinal > totalItems ? totalItems : theoricFinal

    setIntervalItems({ initial: newInitial, final: newFinal })
    setTotalPages(newTotalPages)
  }, [pagination, totalItems])

  const goNextPage = () => {
    const newPaginationData = { ...pagination, page: pagination.page + 1 }
    setPagination(newPaginationData)

    if (!!handleChangePagination) handleChangePagination(newPaginationData)
  }

  const goBeforePage = () => {
    const newPaginationData = { ...pagination, page: pagination.page - 1 }
    setPagination(newPaginationData)

    if (!!handleChangePagination) handleChangePagination(newPaginationData)
  }

  const handleChangePageSize = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    const newPaginationData = { ...pagination, page: 0, sizePage: Number(value) }
    setPagination(newPaginationData)

    if (!!handleChangePagination) handleChangePagination(newPaginationData)
  }

  return {
    isFirstPage: pagination.page === 0,
    isLastPage: pagination.page === (totalPages - 1),
    intervalItems: intervalItems,
    sizePage: pagination.sizePage,
    goNextPage,
    goBeforePage,
    handleChangePageSize
  }
}

export default useHandlerPaginationTable