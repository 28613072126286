import moment from "moment"
import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

import ListadoPedidosFilters from "./BBs/ListadoPedidosFilters/ListadoPedidosFilters"
import ListadoPedidosTable from "./BBs/ListadoPedidosTable/ListadoPedidosTable"

import { pedidosService } from "@services/pedidos"
import Loader from "@components/ui/Loader/Loader"
import { EsfirusTablePropsPagination, HandleChangePaginationType } from "@services/hooks/useHandlerPaginationTable"

const ListadoPedidos = () => {
  const [filters, setFilters] = useState({
    empresa: "",
    dateFrom: moment().startOf("day").add(-3, "month"),
    dateTo: moment().startOf("day").add(1, "day").add(-1, "second"),
    empleado: "",
    status: ""
  })

  const [pedidos, setPedidos] = useState<any[]>([]) // TODO pendiente de definir lo que viene del graphQL
  const [totalItems, setTotalItems] = useState<number>(0)
  const [loadingPedidos, setLoadingPedidos] = useState<boolean>(false)
  const [pagination, setPagination] = useState<EsfirusTablePropsPagination>({ page: 0, sizePage: 50 })

  useEffect(() => {
    let componentMounted = true

    const fetchPedidos = async () => {
      setLoadingPedidos(true)
      try {
        const resp = await pedidosService.getList({
          from: filters.dateFrom.unix(),
          to: filters.dateTo.unix(),
          company: filters.empresa,
          status: filters.status,
          empleado: filters.empleado,
          pagination: pagination
        })
        const newItems = resp?.data?.data?.items ?? []
        const newTotalItems = resp?.data?.data?.total_items ?? 0
        if (componentMounted) {
          setPedidos(newItems)
          setTotalItems(newTotalItems)
        }
      } catch {
        setPedidos([])
        setTotalItems(0)
      } finally {
        if (componentMounted) setLoadingPedidos(false)
      }
    }

    fetchPedidos()

    return () => {
      componentMounted = false
    }
  }, [filters, pagination])

  if (loadingPedidos) {
    return <Loader message="Cargando listado de pedidos" />
  }

  const handleChangePagination: HandleChangePaginationType = (newPaginationData) => {
    setPagination(newPaginationData)
  }

  return (
    <>
      <ListadoPedidosFilters filters={filters} setFilters={setFilters} />
      <ListadoPedidosTable
        pedidos={pedidos}
        pagination={pagination}
        totalItems={totalItems}
        handleChangePagination={handleChangePagination}
      />

      {/* Para cargar el modal de view y edit pedidos */}
      <Outlet />
    </>
  );
}

export default ListadoPedidos;
