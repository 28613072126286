import { useState } from "react";
import "./Header.scss";
import homeImage from "../../assets/images/home.png";
import logoImage from "../../assets/images/logo.png";
import { Badge, Link, Icon, Tooltip, IconButton } from "@mui/material";
import { Notifications, Logout } from '@mui/icons-material';
import { getFullName, getUserId, getUserRol, isEmpleado, isJefeObra } from "@services/hooks/UserHook";
import { useNavigate } from "react-router-dom";
import HeaderSettingsMenu from "./HeaderSettingsMenu";

import { getExternalData } from '@services/hooks/UserHook'
import { usersService } from "@services/users";


function Header() {
  const navigate = useNavigate();
  const externaData = JSON.parse(getExternalData());
  const userId = getUserId();
  const [notification, setNotification] = useState(externaData?.notification ?? 0);
  const [visibleTooltip, setVisibleTooltip] = useState(false);

  const isJefeDeObraUser = isJefeObra()
  const isEmpleadoUser = isEmpleado()

  const readBadge = () => {
    // TODO: Aitor, esto no supe si enviarlo al UserHook como unn setDataExternal, bueno dejo esto por aqui y comentamos
    localStorage.setItem("ef-user-external-data", JSON.stringify({ ...externaData, notification: 0 }) ?? "[]");
    setVisibleTooltip(true);
    setNotification(0);

    usersService.updateNotification({ id: userId, notification: 0 })

    setTimeout(() => {
      setVisibleTooltip(false);
    }, 5000)
  }

  // TODO: QUITAR, temporal desarrollo
  return (
    <header className="noPrint">
      <div className="header-container">
        <Link
          className="mt-20 ml-20 pointer"
          onClick={() => {
            //esto es para el logout
            // localStorage.clear();
            // navigate("/login");
            navigate("/home");
          }}
        >
          <img src={homeImage} alt="home" />
        </Link>


        <div className="spacer"></div>

        <Link className={notification ? "mt-20 mr-20 pointer" : "mt-20 mr-20"} >
          <Tooltip title={visibleTooltip ? "Tienes partes rechazados que revisar" : ""}>
            <Badge badgeContent={notification} color="error">
              <IconButton onClick={notification === 1 ? readBadge : () => { }} >
                <Notifications sx={{ color: "white" }} />
              </IconButton>
            </Badge>
          </Tooltip>
        </Link>

        <div className="mt-20 mr-20">{getFullName()} <span className="font-bold">({getUserRol()})</span></div>
        {!isEmpleadoUser && !isJefeDeObraUser && (
          <HeaderSettingsMenu />
        )}
        <IconButton
          className="mt-20 mr-20"
          onClick={() => {
            //esto es para el logout
            localStorage.clear();
            navigate("/login");
          }}
        >
          <Logout sx={{ color: "white" }} />
        </IconButton>
      </div>
      <img className="header-logo" src={logoImage} alt="logo" />
    </header>
  );
}

export default Header;
