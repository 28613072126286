import { styled, useTheme } from "@mui/material/styles";
import { Button, ButtonProps, Fab, useMediaQuery } from "@mui/material";
import "./EsfirusButton.scss";
const CustomButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "width" && prop !== "fontSize",
})<CustomButtonProps>(({ theme, width, fontSize }) => ({
  fontFamily: "'Lato', sans-serif",
  borderRadius: 25,
  textTransform: "initial",
  fontSize: fontSize || 18,
  lineHeight: 1.3,
  width,
  boxShadow: "none",
}));

export default function EsfirusButton(props: CustomButtonProps) {
  const isMobile = useMediaQuery('(max-width: 599px) or (max-height: 399px)');

  if (isMobile && props.startIcon) {
    return (
      <Fab
        color={props.color}
        size="medium"
        className={props.variant || ""}
        aria-label="edit"
        onClick={props?.onClick}
      >
        {props.startIcon}
      </Fab>
    );
  }

  return (
    <CustomButton variant="contained" {...props}>
      {props.children}
    </CustomButton>
  );
}

interface CustomButtonProps extends ButtonProps {
  width?: number;
  fontSize?: number;
}
