import './Table.scss'
import EsfirusTable from '@components/ui/TableDays/EsfirusTable';

export default function EsfirusReportTable(props: EsfirusReportTableProps) {
    return (
        <EsfirusTable
            columns={props?.grid?.columns}
            rows={props?.grid?.rows as Array<any>}
            selectedDate={props?.grid?.selectedDate as any}
            headerStickyHeight={210}
        />
    )
}

export interface EsfirusReportTablePropsOption {
    label: string;
    value: any;
}
export interface EsfirusReportTableProps {
    placeholder?: string;
    grid?: any;
}