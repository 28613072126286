import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";
import "./ModalConfirmPedido.scss";

const ModalConfirmPedido = ({ open, setOpen, data }: any) => {
  const navigate = useNavigate()

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-confirm-pedido-success">
        <div className="header">
          <h5 className="text-center">
            Pedido generado correctamente
          </h5>
        </div>
        <div className="body">
          <div className="text-center ph-30">La id del pedido generado es el siguiente:</div>
          <div className="text-center ph-30">{data.purchase_order_id}</div>
          <div className="button-container">
            <EsfirusButtonNative
              click={() => navigate("/pedidos")}
              label="Ir al listado"
            ></EsfirusButtonNative>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalConfirmPedido