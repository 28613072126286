import styled from 'styled-components';
import { merge } from 'lodash';

export const customStyle = {
    Productos: {
        descripcion: {
            flexBasis: "100%",
            width: "100%",
            marginBottom: "10px"
        },
        imagen: {
            flexBasis: "100%",
            width: "100%",
            marginBottom: "10px"
        }
    }
}

const FieldsContainer = styled.div`
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        
    `

const customFieldsContainer = {

    Roles: styled.div`
        display: flex;
        justify-content: center;
        flex-direction: column;
        
        @media (min-width: 1200px) {
            padding-left: 10rem;
            padding-right: 10rem;
        }
    `
}


export const getAllSectionStyles = (modelData) => {
    const sections = modelData.sections.map((section) => getSectionSytle(modelData, section))
    return sections;
}


export const getSectionSytle = (modelData, section) => {
   
    return {
        ...section,
        fields: section.fields.map((field) => getAllFieldStyle(modelData, field)),
        FieldsContainer: getFieldsContainerStyle(modelData.plural),
    }
}

export const getAllFieldStyle = (modelData, field) => {
    const customStyle = getFieldStyle(modelData.plural, field.field);
    const apiStyle = field?.style;
    if (customStyle) {
        return { ...field, style: merge({ ...apiStyle }, { ...customStyle }) }
    }
    // return { ...field, FieldContainer: FieldContainer }
    return field
}

export const getFieldStyle = (plural, input) => {
    return customStyle?.[plural]?.[input];
}

export const getFieldsContainerStyle = (plural) => {
    return customFieldsContainer?.[plural] ? customFieldsContainer?.[plural] : FieldsContainer;

}

