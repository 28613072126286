import { MessageList } from "react-chat-elements"
import "react-chat-elements/dist/main.css"

import './chat.scss'
import { useState } from "react"
import axios from "axios"

import ReplayIcon from '@mui/icons-material/Replay';

const Chat = ({ token, url }) => {

  const ep = `${url}/modules/ia/chat`

  const [newMessage, setNewMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [isTyping, setIsTyping] = useState(false)

  const sendMessage = async (forceMessage = null) => {

    const localMessage = forceMessage ?? newMessage
    const msg = {
      position: "left",
      type: "text",
      title: "Yo",
      text: renderMessage(localMessage),
    }
    const newMessages = [...messages, msg]
    setMessages(newMessages)
    const config = {
      headers: { Authorization: token }
    };
    const bodyParameters = {
      message: localMessage
    }
    let responseMsg = null
    try {
      const { data } = await axios.post(ep, bodyParameters, config)
      responseMsg = {
        position: "right",
        type: "text",
        title: "Bot",
        text: parseResponse({ data: data?.data, headers: data?.headers }),
      }

    } catch (err) {
      if (err.response.data) {
        responseMsg = {
          position: "right",
          type: "text",
          title: "Bot",
          text: err.response.data.message,

        }

      }
    }
    if (responseMsg) {
      const newMessages2 = [...newMessages, responseMsg]
      setMessages(newMessages2)
    }
    setNewMessage('')
    setIsTyping(true)
  }

  const retryMessage = (message) => {
    sendMessage(message)
  }

  const renderMessage = (message) => {
    return (
      <div className="sentMessage">
        <div className='message'>{newMessage}</div>
        <div className='retry' onClick={() => retryMessage(newMessage)}><ReplayIcon fontSize="small" /></div>
      </div>
    )
  }

  const parseResponse = ({ data, headers }) => {
    let response = ''
    if (!Array.isArray(data)) {
      response = data
    } else if(data.length === 0 || !headers?.length){
      response = 'No se encontraron resultados, prueba con otra pregunta'
    } else {
  
      response =
        (<table>
          <thead>
            <tr>
              {headers.map((header, index) => {
                return <th key={'th-index' + index}>{header}</th>
              }
              )}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return <tr key={'tr-index' + index}>
                {headers.map((header, index) => {
                  return <td key={'td-index' + index}>{item[header]}</td>

                }
                )}

              </tr>
            })}
          </tbody>
        </table>)


    }
    return response
  }



  return (
    <div className='chat-container'>
      <MessageList
        className='message-list'
        lockable={true}
        toBottomHeight={'100%'}
        dataSource={messages}
      />
      <div className='send-box'>
        <textarea
          value={newMessage} // ...force the input's value to match the state variable...
          onChange={e => setNewMessage(e.target.value)}
          placeholder="Pregunta lo que quieras" />
        <button onClick={() => sendMessage()}>Enviar</button>
      </div>
    </div>
  )
}

export default Chat