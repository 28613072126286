import "./subheader.scss";
import EsfirusSelectCustom from "@ui/SelectCustom/EsfirusSelectCustom";
import EsfirusSelect from "@ui/Select/EsfirusSelect";
import EsfirusSearch from "@ui/Search/EsfirusSearch";
import { useDispatch, useSelector } from "react-redux";
import { setFilterByType, setSelectedDateFrom, setSelectedDateTo, setSelectedEmpleadoHome, setSelectedEmpresa, setSelectedTypeHour } from "@store/slices/partes";
import { useEffect, useState } from "react";
import { getAvailableCompanies } from "@helpers/companyData";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import 'dayjs/locale/es';

import EsfirusTextInput from "@components/ui/Text-input/EsfirusTextInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { canSupervise } from "@services/hooks/UserHook";
import { constants } from "@helpers/constants";

function PartesSubHeader(props: any) {
  const totalItemsAll = useSelector((state) => (state as any).partes).totalItemsAll;
  const totalItemsAbierto = useSelector((state) => (state as any).partes).totalItemsAbiertos;
  const totalItemsConfirmado = useSelector((state) => (state as any).partes).totalItemsConfirmados;
  const totalItemsValidados = useSelector((state) => (state as any).partes).totalItemsValidados;
  const totalItemsRechazados = useSelector((state) => (state as any).partes).totalItemsRechazados;
  const filterByType = useSelector((state) => (state as any).partes).filterByType;

  const empresas = useSelector((state) => (state as any).empresas).list;
  const dispatch = useDispatch();

  const { setFilterBySearch } = props;

  const [empresasDisponibles, setEmpresasDisponibles] = useState<any>([]);
  const [empresasList, setEmpresasList] = useState<any>([]);

  useEffect(() => {
    setEmpresasDisponibles(getAvailableCompanies());
    // dispatch(setSelectedEmpresa(getInternalCompany()));
  }, [])

  useEffect(() => {
    setEmpresasList(empresas.filter((e: any) => empresasDisponibles == "*" || empresasDisponibles.includes(e.Empresa)))
  }, [empresasDisponibles])

  return (
    <div className="ef-pates-subheader">
      <div className="ef-left">
        <EsfirusSelect
          key={"ef-select-company"}
          options={empresasList.map((e: any) => ({ label: e.Nombre, value: e.Empresa }))}
          value={props.selectedEmpresa}
          change={(e: any) => {
            if (e == "") {
              dispatch(setSelectedEmpresa(null as never))
            } else {
              dispatch(setSelectedEmpresa(
                empresas.find((_e: any) => _e.Empresa.toString() === e.toString()).Empresa
              ))
            }
          }}
          placeholder="Todas"
        />

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DatePicker
            value={props.selectedDateFrom}
            onChange={(newValue) => {
              const date = new Date(newValue.$y, newValue.$M, newValue.$D);
              dispatch(setSelectedDateFrom(date?.getTime() as never));
            }}
            renderInput={(params) => (
              <EsfirusTextInput fullWidth {...params} />
            )}

            inputFormat="DD/MM/YYYY"
            className="datePickerFilter"
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DatePicker
            value={props.selectedDateTo}
            onChange={(newValue) => {
              const date = new Date(newValue.$y, newValue.$M, newValue.$D);
              dispatch(setSelectedDateTo(date?.getTime() as never));
            }}
            renderInput={(params) => (
              <EsfirusTextInput fullWidth {...params} />
            )}

            inputFormat="DD/MM/YYYY"
            className="datePickerFilter"
          />
        </LocalizationProvider>




        {
          canSupervise() &&
          <EsfirusSearch change={
            (e: any) => dispatch(setFilterBySearch(e as never))
          } placeholder="Buscar" />

        }
        {
          canSupervise() &&
          <EsfirusSelect
            key={"ef-select-employee"}
            options={props?.empleadosList}
            value={props?.selectedEmpleado}
            change={(e: any) => {
              if (e == "") {
                dispatch(setSelectedEmpleadoHome(null as never))
              } else {
                dispatch(setSelectedEmpleadoHome(e))
              }
            }}
            placeholder="Todos los Empleados"
          />

        }


      </div>

      <div className="ef-right">
        <EsfirusSelectCustom
          key={"ef-select-paginator"}
          fill={true}
          value={filterByType}
          options={[
            { label: `Todos: ${totalItemsAll}`, value: "" },
            { label: `Abiertos: ${totalItemsAbierto}`, value: constants.estadoParte.abierto },
            { label: `Confirmados: ${totalItemsConfirmado}`, value: constants.estadoParte.confirmado },
            { label: `Validados: ${totalItemsValidados}`, value: constants.estadoParte.validado },
            { label: `Rechazados: ${totalItemsRechazados}`, value: constants.estadoParte.rechazado },
          ]}
          change={(e: any) => {
            dispatch(setFilterByType(e))
          }}
        />
        <div className="pagination">
          <span></span>
          <span></span>
        </div>
      </div>

    </div>
  );
}

export default PartesSubHeader;
