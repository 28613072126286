import Modal from "@mui/material/Modal";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";
import "./ModalValidating.scss";

interface ModalValidatingProps {
  open: boolean
  setOpen: (value: boolean) => void
  isValidating: boolean
}

const ModalValidating = ({ open, setOpen, isValidating }: ModalValidatingProps) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-confirm-validation-parte">
        <div className="header">
          <h5 className="text-center">
            Validación
          </h5>
        </div>
        <div className="body">
          <div className="text-center ph-30">{isValidating ? (
            "Validando parte/s, espere un momento por favor."
          ) : (
            "Parte/s validado/s correctamente."
          )}
          </div>

          <div className="button-container">
            <EsfirusButtonNative
              label="Cerrar"
              click={() => setOpen(false)}
              disabled={isValidating}
            ></EsfirusButtonNative>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalValidating;
