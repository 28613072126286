import cryptoJs from "crypto-js";
import { request, requestFile, requestImg } from "./request";
import { env } from "@helpers/env";
import { constants } from "@helpers/constants";

const encriptarContraseña = (pwd) => {
  var ciphertext = cryptoJs.AES.encrypt(pwd, "secret@q11").toString();
  return ciphertext;
};

export const loginRequest = (credentials) => {
  if (credentials.remember) {
    const password = encriptarContraseña(
      credentials.password,
      credentials.email
    );

    localStorage.setItem("email", credentials.email);
    localStorage.setItem("pw", password);
  } else {
    localStorage.removeItem("email");
    localStorage.removeItem("pw");
  }
  return request(`login/`, {
    method: "POST",
    body: JSON.stringify(credentials),
  })
    .then((response) => {
      return {
        ...response,
        token: response.token ?? "",
        user: response.data ?? {},
        // user: JSON.parse(atob(response.token.split(".")[1])).data,
      };
    })
    .catch((e) => {
      console.log("Error Login ", e);
    });
};

export const getRequest = (endpoint, justData = false, toMicrotech = false) => {
  const extra = toMicrotech ? { API_URL: constants.microtechEP } : {};

  return request(`${endpoint}`, {
    method: "GET",
    ...extra
  })
    .then((response) => {
      if (justData) return response.data;
      return {
        data: response.data ?? response,
      };
    })
    .catch((e) => {
      console.log("error getRequest");
      console.log(e);
      throw e;
    });
};

export const postRequest = (endpoint, data) =>
  request(`${endpoint}`, {
    method: "POST",
    body: JSON.stringify(data),
  })
    .then((response) => {
      return {
        ...response,
      };
    })
    .catch((e) => {
      console.log("error postRequest");
      console.log("e ", e);
      throw e;
    });

export const putRequest = (endpoint, data) =>
  request(`${endpoint}/${data.id}`, {
    method: "PUT",
    body: JSON.stringify(data),
  })
    .then((response) => {
      return {
        ...response,
      };
    })
    .catch((e) => {
      console.log("error putRequest");
      console.log("e ", e);
      return "ko";
    });

export const imageRequest =
  (endpoint) =>
    ({ id }) =>
      requestImg(`imagenes/get/${id}`);

export const findRequest =
  (endpoint, defaultFilter, itemsPerPage = 10) =>
    (
      { filter, pagination } = {
        pagination: { page: 1, itemsPerPage: itemsPerPage },
      }
    ) =>
      request(
        `${endpoint}?filter=${encodeURI(
          JSON.stringify({
            ...defaultFilter,
            ...{
              offset: (pagination.page - 1) * pagination.itemsPerPage,
              limit: pagination.itemsPerPage,
              filter,
            },
          })
        )}`
      );

export const countRequest =
  (endpoint, defaultFilter = {}) =>
    ({ filter }) =>
      request(
        `${endpoint}/count?filter=${encodeURI(
          JSON.stringify({
            ...defaultFilter,
            filter,
          })
        )}`
      );

export const upsertRequest = (endpoint, options) => (data) => {
  let method = "";
  let url = "";

  if (options.upsertOptions?.route) {
    endpoint = options.upsertOptions.route;
  }
  if (data?.id) {
    method = "PUT";
    url = `${endpoint}/${data.id}`;
  } else {
    method = "POST";
    url = `${endpoint}`;
  }

  return request(url, {
    method,
    body: JSON.stringify(data),
  });
};

export const findOneRequest = (endpoint, options) => {
  //Endpoint
  if (options.upsertOptions?.route) {
    endpoint = options.upsertOptions.route;
  }
  return ({ id }) => request(`${endpoint}/${id}`);
};

export const deleteRequest = (endpoint, options) => {
  if (options?.upsertOptions?.route) {
    endpoint = options.upsertOptions.route;
  }
  return (data) => {
    return request(`${endpoint}/${data.id}`, {
      method: "DELETE",
    });
  };
};

export const uploadFileRequest = (url) => (data) => {
  const formData = new FormData();
  formData.append("imagen", data.file);

  // Todo A este endpoint se encesita el idnegocio y el id
  return requestFile(`imagenes/upload`, {
    method: "POST",
    body: formData,
  });
  // return requestFile(`upload_image`)
};

// export const definitionRequest = ({endpoint, contest}) => () => {
//     const  context = React.useContext(AuthContext);

//    return  request(`${endpoint}manager/definitions/`);
// }

export const definitionsRequest = () => {
  return request("definitions/");
  // else return Promise.reject()
};

export const getRequests = (endpoint, options, nolimit = false) => {
  const API_URL = env.REACT_APP_API_URL;
  if (endpoint.indexOf("/") === 0) endpoint = endpoint.substr(1);
  // console.log("OPTIONS", options);
  return {
    findRequest: findRequest(
      endpoint,
      options?.findRequest?.defaultFilter,
      nolimit ? 10000 : 10
    ),
    imageRequest: imageRequest(endpoint),
    findOneRequest: findOneRequest(endpoint, options),
    upsertRequest: upsertRequest(endpoint, options),
    countRequest: countRequest(endpoint, options?.findRequest?.defaultFilter),
    deleteRequest: deleteRequest(endpoint, options),
    uploadFileRequest: uploadFileRequest(API_URL),
    // definitionRequest: definitionRequest(endpoint),
    mapFindResponse: (d, exclude = []) => {
      d = d.data;
      if (typeof d.map == "undefined") {
        exclude.forEach((el) => {
          if (d[el]) delete d[el];
        });
        if (d.description) {
          d.description = d.description.es;
        }
        return d;
      }
      return d.map((field) => {
        if (field.description) {
          field.description = field.description.es;
        }
        return field;
      });
    },
  };
};
