import { priceFormat } from '@helpers/utils';
import { isEmpleado } from '@services/hooks/UserHook';
import { useSelector } from 'react-redux';

import './EsfirusResume.scss'

const EsfirusResumeAsync = () => {
	const isUserEmpleado: boolean = isEmpleado()
	const configuration = useSelector((state) => (state as any).configurations.config)
	const resumeTotalItems = useSelector((state) => (state as any).partes).resumeTotalItems
	const resumeTotalHoras = useSelector((state) => (state as any).partes).resumeTotalHoras
	const resumeTotalImporte = useSelector((state) => (state as any).partes).resumeTotalImporte

	// El render depende de la configuración del backoffice.
	const configInOnlyAdmins = configuration.listado?.listadoParteShowResume === "only_admins"
	const configNobody = configuration.listado?.listadoParteShowResume === "nobody"
	const noRenderResume = configNobody || (configInOnlyAdmins && isUserEmpleado)
	if (noRenderResume) {
		return null
	}

	// Constantes del render.
	const horasDisplay = resumeTotalHoras.toFixed(2)
	const importeDisplay = priceFormat(resumeTotalImporte)

	return (
		<div className='ef-resume'>
			<div>Total Partes: {resumeTotalItems}</div>
			<div>Horas: {horasDisplay}</div>
			<div>Importe: {importeDisplay}</div>
		</div>
	)
}

export default EsfirusResumeAsync