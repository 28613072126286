import { createSlice } from "@reduxjs/toolkit";

const initial = {
  list: [],
};

export const CalendariosSlice = createSlice({
  name: "calendarios",
  initialState: { ...initial },
  reducers: {
    resetCalendarios: (state, action: any) => {
      state.list = [...initial.list];
    },
    setCalendarios: (state, action: any) => {
      const calendars = action.payload?.map((c: any) => {
        const inicio = new Date(c.FechaInicio + "T" + c?.HoraInicio?.toString().substring(0, 2) + ':' + c?.HoraInicio?.toString().substring(2, 4) + ":00");
        const fin = new Date(c.FechaFin + "T" + c?.HoraFin?.toString().substring(0, 2) + ':' + c?.HoraFin?.toString().substring(2, 4) + ":00");
        return {
          ...c,
          inicio: inicio.getTime(),
          fin: fin.getTime(),
        };
      });
      state.list = calendars as any;
    },
  },
});

export default CalendariosSlice.reducer;
export const { setCalendarios, resetCalendarios } = CalendariosSlice.actions;

