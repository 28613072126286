import { v4 as uuid } from 'uuid'
import "./FileUploader.scss"

interface FileUploaderProps {
  onFileSelect: (event: any) => void
  labelText?: string
}

const FileUploader = ({ onFileSelect, labelText }: FileUploaderProps) => {
  const inputId = uuid()

  return (
    <div>
      <input
        id={inputId}
        type="file"
        accept=".pdf, image/*"
        style={{ display: 'none' }}
        onChange={onFileSelect}
      />
      <label
        htmlFor={inputId}
        className='file-uploader'
      >
        {labelText || "Seleccionar archivo"}
      </label>
    </div>
  )
}

export default FileUploader