import { Modal } from "@mui/material";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";
import "./ModalGpsRequired.scss";

const ModalGpsRequired = ({ open, setOpen }: any) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-gps-required-error">
        <div className="header">
          <h5 className="text-center">
            Posición GPS obligatoria
          </h5>
        </div>
        <div className="body">
          <div className="text-center ph-30">No se puede realizar el fichaje porque el gps está desactivado y/o no se ha concedido los permisos. Antes de intentar fichar, porfavor actívelo y/o conceda los permisos necesarios.</div>
          <div className="button-container">
            <EsfirusButtonNative
              click={() => setOpen(false)}
              label="Entendido"
            ></EsfirusButtonNative>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalGpsRequired