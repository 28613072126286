import { getPartes } from "@store/slices/partes";
import { useSelector } from "react-redux";
import { isEmpleado } from "./UserHook";


const usePartesGet = () => {
    const configuration = useSelector((state) => state.configurations.config);

    const fetch = (offset, limit, estado, filters) => {
        const month = (isEmpleado() ? configuration?.listado?.listadoParteMeses : configuration?.listado?.listadoParteMesesAdmin) ?? 1;
        return getPartes(month, offset, limit, estado, filters)
    }

    return fetch
}

export default usePartesGet;