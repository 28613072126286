import HeaderNavigateButton from "@components/ui/HeaderNavigateButton"
import documentIcon from "@assets/images/icons/documentIcon.png"
import listIcon from "@assets/images/icons/listIcon.png"

import "./AvisosHeader.scss"

const AvisosHeader = () => {
  return (
    <div className="ef-avisos-header">
      <HeaderNavigateButton
        icon={documentIcon}
        label="Nuevo borrador"
        path="/avisos/nuevo"
        color="secondary"
      />
      <HeaderNavigateButton
        icon={listIcon}
        label="Borradores de avisos"
        path="/borradores-avisos"
        color="primary"
        variant="outlined"
      />
      <HeaderNavigateButton
        icon={listIcon}
        label="Avisos definitivos"
        path="/avisos"
        color="primary"
        variant="outlined"
      />
    </div>
  )
}

export default AvisosHeader