import { createSlice } from "@reduxjs/toolkit";

const initial = {
  data: {},
  resetPass: {
    pass: "",
    passCheck: "",
  },
  validations: {
    isPassShort: true,
    isNotSamePass: false,
    isValid: false,
    isEmpty: true,
  },
}

export const userSlice = createSlice({
  name: "user",
  initialState: { ...initial },
  reducers: {
    resetUser: (state, action: any) => {
      state.data = { ...initial.data };
      state.resetPass = { ...initial.resetPass };
      state.validations = { ...initial.validations };
    },
    setuser: (state, action: any) => {
      state.data = { ...action.payload } as any;
    },
    setresetpass: (state, action: any) => {
      state.resetPass = { ...action.payload } as any;
    },
    setvalidations: (state, action: any) => {
      state.validations = { ...action.payload } as any;
    },
  },
});

export default userSlice.reducer;
export const { setuser, setresetpass, setvalidations, resetUser } = userSlice.actions;
