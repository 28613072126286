/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { Box } from "@mui/material"
import moment from "moment"

import Header from "@components/Header/Header"
import FicharHeader from "@components/HeaderFichaje/header"
import EsfirusButton from "@components/ui/Button/EsfirusButton"
import ModalGpsRequired from "./BBs/ModalGpsRequired/ModalGpsRequired"

import { fichajesService } from "@services/fichajes"
import { configurationService } from "@services/configApp"
import { dateFormat } from "@helpers/utils"
import { emptyResourceRedirect } from "@helpers/navigate"
import { TIPOS_FICHAJES, getFichajeType, getPosition, registerFichaje, timeFormatted } from "./utils/functions"

import "./TrackHoras.scss"

const INIT_TIME_DIFF = { h: "00", m: "00", s: "00" }

export default function TrackHoras() {
  const navigate = useNavigate()

  const empresas = useSelector((state) => (state as any).empresas).list;
  const fichajeConfig = configurationService.getConfigFichaje()

  // Estados de control de entrada/salida
  const [lastFichaje, setLastFichaje] = useState<any>(null)
  const [fichado, setFichado] = useState(false)

  // Estados para contador de tiempo
  const [initTime, setInitTime] = useState<any>(null)
  const [currentTime, setCurrentTime] = useState<any>(null)
  const [timeDiff, setTimeDiff] = useState(INIT_TIME_DIFF)

  // Modal activators
  const [showModalGpsRequired, setShowModalGpsRequired] = useState(false)

  // Loaders
  const [isLoadingLastFichaje, setIsLoadingLastFichaje] = useState(false)
  const [isRegistering, setIsRegistering] = useState(false)

  const secondsCounter = useRef(null as any)

  // Carga del último fichaje
  useEffect(() => {
    loadLastFichaje()
    emptyResourceRedirect({ check: empresas.length === 0, path: "/time-tracking", navigate })
  }, [])

  // Actualizamos datos cuando cambie último fichaje
  useEffect(() => {
    if (!lastFichaje) {
      setFichado(false)
    } else {
      const lastFichajeType = getFichajeType(lastFichaje)
      const _fichado = lastFichajeType === TIPOS_FICHAJES.ENTRADA
      setFichado(_fichado)
    }
  }, [lastFichaje])

  // Seteamos hora inicial y contador dependiendo de si está fichado o no
  useEffect(() => {
    if (fichado) {
      secondsCounter.current = setInterval(() => setCurrentTime(moment()), 1000)
      setInitTime(fichado ? moment(lastFichaje.dateIn) : null)
      setCurrentTime(moment())
    } else {
      clearInterval(secondsCounter.current)
      setInitTime(null)
      setCurrentTime(null)
    }
  }, [fichado])

  // Seteamos la diferencia entre initDate y currentDate formateado
  useEffect(() => {
    if (initTime && currentTime) {
      const diff = Math.abs(currentTime.valueOf() - initTime.valueOf())
      const diffH = Math.floor(diff / 1000 / 60 / 60)
      const diffM = Math.floor(diff / 1000 / 60) - (diffH * 60)
      const diffS = Math.floor(diff / 1000) - (diffM * 60) - (diffH * 60 * 60)

      setTimeDiff({
        h: timeFormatted(diffH) as any,
        m: timeFormatted(diffM) as any,
        s: timeFormatted(diffS) as any
      })
    } else {
      setTimeDiff(INIT_TIME_DIFF)
    }

  }, [initTime, currentTime])

  const loadLastFichaje = async () => {
    setIsLoadingLastFichaje(true)

    try {
      const lastFichajeResp = await fichajesService.getLastFichaje()
      setLastFichaje(lastFichajeResp?.data?.data ?? null)
    } finally {
      setIsLoadingLastFichaje(false)
    }
  }

  const sendToRegister = async () => {
    setIsRegistering(true)
    const time = moment()

    try {
      const gps = await getPosition()
      const gpsCoords = gps?.coords ? { lat: gps?.coords?.latitude, long: gps?.coords?.longitude } as any : null

      const newFichajeResp = await registerFichaje(time, gpsCoords)
      setLastFichaje(newFichajeResp?.data?.data ?? null)
    } catch (error: any) {
      if (error.source === "getPosition") {
        if (fichajeConfig.gpsIsRequired) {
          setShowModalGpsRequired(true)
        } else {
          const gpsCoords = null
          const newFichajeResp = await registerFichaje(time, gpsCoords)
          setLastFichaje(newFichajeResp?.data?.data ?? null)
        }
      }
    } finally {
      setIsRegistering(false)
    }
  }

  if (isLoadingLastFichaje) {
    return (
      <>
        <Header />
        <FicharHeader />
        <div className="new-parte-container">
          <div className="new-parte-title text-center">
            <h4>Cargando último fichaje</h4>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Header />
      <FicharHeader />
      <div className="new-parte-container">
        <div className="new-parte-title text-center">
          <h4>Fichar</h4>
        </div>
        {lastFichaje && (
          <Box className="ml-20 mr-20 mt-40 text-center">
            <h5>
              <div>Último fichaje realizado</div>
              <div>Entrada: {dateFormat(lastFichaje.dateIn)}</div>
              <div>Salida: {lastFichaje.dateOut ? dateFormat(lastFichaje.dateOut) : "-"}</div>
            </h5>
          </Box>
        )}
        <Box className="ml-20 mr-20 mt-40 botonera">
          <h5>{timeDiff.h}:{timeDiff.m}:{timeDiff.s}</h5>
        </Box>
        <Box className="ml-20 mr-20 mt-40 botonera">
          <EsfirusButton
            className="new-parte-save-button"
            color={fichado ? "primary" : "secondary"}
            disabled={isRegistering}
            onClick={sendToRegister}
          >
            {fichado ? "Salida" : "Entrada"}
          </EsfirusButton>
        </Box>
      </div>

      {showModalGpsRequired && (
        <ModalGpsRequired open={showModalGpsRequired} setOpen={setShowModalGpsRequired} />
      )}
    </>
  )
}
