import axios from "axios"
import { apiConfig } from "./config"
import { Configuration } from "./swagger/configuration"

class ErpService {
  private accessToken = ""
  private basePath = ""

  constructor(_apiConfig: Configuration) {
    this.accessToken = (_apiConfig.accessToken || '') as string
    this.basePath = (_apiConfig.basePath || '') as string
  }

  async getSalesPricingArray(items = [], obra = {}) {
    try {
      const response = await axios({
        baseURL: this.basePath + '/erp/sales-pricing-array',
        headers: { Authorization: `Bearer ${localStorage.getItem("ef-token") as string}` },
        data: { items, obra },
        method: 'put'
      })

      return response
    } catch (error) {
      apiConfig?.catchError(error)
    }
  }

  async getSalesPricing(item = {}, obra = {}, quantity = 1, linea = {}) {
    try {
      const response = await axios({
        baseURL: this.basePath + '/erp/sales-pricing',
        headers: { Authorization: `Bearer ${localStorage.getItem("ef-token") as string}` },
        data: { item, obra, quantity, linea },
        method: 'put'
      })

      return response
    } catch (error) {
      apiConfig?.catchError(error)
    }
  }
}

export const erpService = new ErpService(apiConfig)
