import { useOnDraw } from "@services/hooks/DrawHook";
import "./EsfirusCanva.scss";

export default function EsfirusCanva(props: EsfirusCanvaProps) {
  const onDraw = (ctx: any, point: any, prevPoint: any) => {
    drawLine(prevPoint, point, ctx, "#075577", 5);
  };

  const drawLine = (start: any, end: any, ctx: any, color: any, width: any) => {
    start = start ?? end;
    ctx.beginPath();
    ctx.lineWidth = width;
    ctx.strokeStyle = color;
    ctx.moveTo(start.x, start.y);
    ctx.lineTo(end.x, end.y);
    ctx.stroke();

    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.arc(start.x, start.y, 2, 0, 2 * Math.PI);
    ctx.fill();
  };

  const { setCanvasRef, onMouseDown, onTouchStart } = useOnDraw(onDraw);

  return (
    <div id={props.id} className="signature">
      <canvas ref={setCanvasRef} onMouseDown={onMouseDown} onTouchStart={onTouchStart}></canvas>
    </div>
  );
}

interface EsfirusCanvaProps {
  onMouseDown?: any;
  ref?: any;
  id?: string;
}
