/* eslint-disable jsx-a11y/alt-text */
import "./ModalParte.scss";

import { useDispatch, useSelector } from "react-redux";
import { Paper, Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { resetLines } from "@store/slices/lines";
import { removeSelectedObra, updateSelectedObra } from "@store/slices/selectedObra";

import EsfirusButton from "@components/ui/Button/EsfirusButton";
import FormParte from "@components/FormParte/FormParte";
import { reportLinesService } from "@services/reportLines";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";
import { constants } from "@helpers/constants";
import { partesService } from "@services/partes";
import { useEffect, useState } from "react";
import ModalSignAndUpdate from "../modalSign/ModalSignAndUpdate";
import ModalMsg from "@screens/Pending/BBs/modalMsg/ModalMsg";
import { getRequest, putRequest } from "@screens/Backoffice/utils/requests";
import ModalValidating from "@screens/Pending/BBs/ModalValidating/ModalValidating";
import { getBase64FromCanvaDiv } from "@helpers/utils";
import usePartesGet from "@services/hooks/usePartesGet";
import moment from "moment";

interface ModalParteProps {
  data: any;
  open: boolean;
  setOpen: any;
  readOnly: boolean;
  validating?: boolean;
}

function ModalParte(props: ModalParteProps) {
  const dispatch = useDispatch();
  const getPartes = usePartesGet()

  const lineas = useSelector((state) => (state as any).lines).list;
  const filterByType = useSelector((state) => (state as any).partes.filterByType);
  const closedDays = useSelector((state) => (state as any).partes).closedDays;
  const markForRemove = useSelector((state) => (state as any).lines).markForRemove;
  const selectedobra = useSelector((state) => (state as any).selectedobra).obra;
  const presupuesto = useSelector((state) => (state as any).presupuestos).list;
  const capitulos = useSelector((state) => (state as any).capitulos).list;
  const configuration = useSelector((state) => (state as any).configurations.config);
  const [msg, setMsg] = useState<any>("");
  const [openMsg, setOpenMsg] = useState<any>(false);
  const [openSingByUser, setOpenSingByUser] = useState(false);
  const [dateInvalid, setDateInvalid] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const [solapamiento, setSolapamiento] = useState(0);

  const [isValidating, setIsValidating] = useState(false) // Para saber si se está validando el parte
  const [showModalValidating, setShowModalValidating] = useState(false) // Para mostrar el modal de validando un parte

  const obrasCheck = selectedobra?.obraCode && (selectedobra.Administracion || (!selectedobra.Administracion && (!selectedobra.SolPresupuesto || selectedobra.selectedPresupuesto?.length))) && selectedobra?.date && (typeof selectedobra.date === "number" ? moment(selectedobra.date).isValid() : selectedobra.date.isValid())

  useEffect(() => {
    const parteId = props?.data?.id
    const isValidating = props.validating

    const putIsValidating = (state: boolean) => putRequest("report/validating", { id: parteId, isValidating: state })

    if (isValidating) {
      window.addEventListener("unload", () => putIsValidating(false))
      putIsValidating(true)
    }

    return () => {
      if (isValidating) {
        window.removeEventListener("unload", () => putIsValidating(false))
        putIsValidating(false)
      }
    }
  }, [])

  useEffect(() => {
    const disabled = !obrasCheck || !lineas.length || reportLinesService.getValidatorBtn(selectedobra, lineas, dateInvalid) || solapamiento === 1
    setButtonDisabled(disabled)
  }, [obrasCheck, lineas, selectedobra, dateInvalid, solapamiento])

  const updateReport = async (validado?: number) => {

    const date = new Date(props?.data?.date);
    let markForAdd: any[] = []
    for (const line of lineas) {
      const lineParsed = reportLinesService.parseReportLine(line, date)
      let lineWithType = {
        ...line,
        ...lineParsed,
        idParte: props?.data?.id,
        validado,
      };


      if (!line.idLinea) {
        const lineAddedResp = await reportLinesService.postReportLine(lineWithType)

        const lineAddedId = lineAddedResp?.data?.data?.id ?? ""
        markForAdd = [...markForAdd, lineAddedId]
      } else {
        await reportLinesService.updateReportLine(lineWithType);
      }
    }

    const removeLines = markForRemove.map((l: any) => reportLinesService.deleteReportLine(l));
    await Promise.all(removeLines);
    //guardamos la cabecera, por si estamos validando // cambiando de estado validado
    let newCabecera = {
      ...props.data,
    }


    if (validado) {
      newCabecera.validado = validado;
    } else {
      newCabecera.validado = props.data.validado === constants.estadoParte.rechazado ? constants.estadoParte.confirmado : props.data.validado;
    }
    newCabecera.date = selectedobra.date;

    if (selectedobra.firma) newCabecera.firma = selectedobra.firma

    newCabecera.extra = typeof newCabecera.extra == "string" ? JSON.parse(newCabecera.extra) : (newCabecera.extra ?? {});
    const dateLocal = new Date(newCabecera.date).toLocaleDateString("es-ES").replace(/\//g, "-");

    newCabecera.extra = { ...newCabecera.extra, dateLocal }

    if (msg && msg !== "") {
      newCabecera.extra = { ...newCabecera.extra, msg }
    }
    if (selectedobra.observaciones) {
      newCabecera.extra = { ...newCabecera.extra, observaciones: selectedobra.observaciones }
    }
    if (props.validating) {
      newCabecera.extra = { ...newCabecera.extra, markForAdd, markForRemove: markForRemove, isValidating: false }
    }



    //TODO: lo ideal seria hacer esto, pero hay muchas vars que no vienen definidas (que estan en el componente de new, y no sabria moverlas ahora mismo)
    // await partesService.updateReport({...selectedobra, ...newCabecera}, newCabecera.lines, newCabecera.validado);
    await partesService.put(newCabecera);

    if (validado === constants.estadoParte.rechazado) partesService.rechazarParte(props?.data?.id, msg);

    // dispatch(updateParte({ id: props.data.id, parte: updatedParte } as any)); 
    dispatch(getPartes(0, 50, filterByType) as any);

  };

  const showUpdate = !props.readOnly && !props.validating

  const checkParteIsValidating = async (id: any) => {
    try {
      const response = await getRequest("report/" + id)
      const parte = response?.data ?? {}
      const parteExtra = typeof parte?.extra == "string" ? JSON.parse(parte?.extra) : parte?.extra
      const isValidating = parteExtra?.isValidating

      return isValidating
    } catch (error) {
      alert("No se pudo actualizar el parte por problemas técnicos. Vuelve a intentarlo en unos minutos.")
    }
  }

  const confirmar = async (estado?: any) => {
    const isValidating = await checkParteIsValidating(props?.data?.id)
    if (isValidating && !(estado == constants.estadoParte.rechazado) && !(estado == constants.estadoParte.validado)) {
      alert("No se puede actualizar el parte porque se está validando en este momento.")
      return
    }

    if (estado == constants.estadoParte.validado) {
      setShowModalValidating(true)
      setIsValidating(true)
    }

    try {
      await updateReport(estado);
      dispatch(resetLines());
      dispatch(getPartes(0, 50, filterByType) as any);
      props.setOpen(false);
    } finally {
      setIsValidating(false)
    }
  }

  const handleSaveFirma = () => {
    const dataURL = getBase64FromCanvaDiv("divCanva")

    dispatch(
      updateSelectedObra({
        ...selectedobra,
        firma: dataURL
      })
    )
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          dispatch(resetLines());
          dispatch(removeSelectedObra());
          props.setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="see-modal">
          <Paper
            elevation={3}
            sx={{
              maxWidth: 1048,
              margin: "auto",
              borderRadius: "25px",
              paddingBottom: "30px",
            }}
          >
            <div className="paper-header">
              {props.readOnly && <h5>Nº Parte {props.data._id}</h5>}
              {!props.readOnly && <h5>Editar Parte</h5>}
            </div>

            {
              selectedobra.validado && selectedobra.validado === 3 ?
                <Box className="ml-20 mr-20 mt-40 notificacion-rechazado">
                  {selectedobra.extra?.msg}
                </Box> : <></>
            }

            <Box className="ml-20 mr-20 mt-40">
              <FormParte
                selectedobra={selectedobra}
                presupuesto={presupuesto}
                capitulos={capitulos}
                lineas={lineas}
                editable={!props.readOnly}
                dateInvalid={dateInvalid}
                setDateInvalid={setDateInvalid}
                headerOk={obrasCheck}
                closedDays={closedDays}
                data={props?.data}
                setSolapamiento={setSolapamiento}
                isCreation={false}
              ></FormParte>


            </Box>

          </Paper>

          {configuration?.cabeceraParte?.partesFirmar && selectedobra?.Administracion && (
            <div className="signatureForm edit-firma-wrapper">
              <label>Firma</label>
              {(selectedobra?.firma ?? props.data?.firma) ? (
                <div className="signature">
                  <img src={selectedobra?.firma?.replace("''", "") ?? props.data?.firma?.replace("''", "")} srcSet="" />
                </div>
              ) : (
                <div>No hay firma registrada</div>
              )}
              {!props.readOnly && (
                <EsfirusButton
                  width={120}
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setOpenSingByUser(true)
                  }}>
                  Firmar parte
                </EsfirusButton>
              )}
            </div>
          )}

          <div className="button-container center">
            <EsfirusButton
              onClick={() => {
                dispatch(resetLines());
                dispatch(removeSelectedObra());
                props.setOpen(false);
              }}
              fullWidth
              color="primary"
            >
              Cerrar
            </EsfirusButton>
            {showUpdate && (
              <>

                <EsfirusButton
                  onClick={async () => {
                    await confirmar()
                  }}
                  fullWidth
                  color="primary"
                  disabled={buttonDisabled}
                >
                  Actualizar
                </EsfirusButton>
                {!configuration?.hideConfirmar &&
                  <EsfirusButton
                    onClick={async () => {
                      confirmar(constants.estadoParte.confirmado)
                    }}
                    fullWidth
                    color="primary"
                    disabled={buttonDisabled}
                  >
                    Confirmar
                  </EsfirusButton>
                }
              </>
            )}
            {props.validating && (
              <EsfirusButtonNative
                label={"Validar"}
                className={"validate"}
                disabled={buttonDisabled}
                click={async () => {
                  confirmar(constants.estadoParte.validado)
                }}
              />
            )}
            {props.validating && (
              <EsfirusButtonNative
                label={"Rechazar"}
                className={"rejected"}
                disabled={buttonDisabled}
                click={async () => {
                  setOpenMsg(true);
                }}
              />
            )}
          </div>
        </div>
      </Modal>
      <ModalSignAndUpdate onSign={() => handleSaveFirma()} setOpen={setOpenSingByUser} open={openSingByUser}></ModalSignAndUpdate>
      <ModalMsg open={openMsg} setOpen={setOpenMsg}
        onMsg={(m: string) => {
          setMsg(m)
        }}
        onSave={async () => {
          confirmar(constants.estadoParte.rechazado)
        }}></ModalMsg>

      {showModalValidating && (
        <ModalValidating
          open={showModalValidating}
          setOpen={setShowModalValidating}
          isValidating={isValidating}
        />
      )}
    </>
  );
}

export default ModalParte;
