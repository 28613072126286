import * as React from 'react';
import Modal from "@mui/material/Modal";
import lockImage from '../../assets/images/lock.png';
import EsfirusButton from "../ui/Button/EsfirusButton";
import EsfirusTextInput from "../ui/Text-input/EsfirusTextInput";
import { authService } from '@services/auth';
import { setuser } from "../../store/slices/user";
import { useDispatch, useSelector } from "react-redux";
import './ModalForgottenPassword.scss';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
interface ModalForgottenPasswordProps {
    open: boolean;
    setOpen: (value: boolean) => void;
}

const resetPass = async (email: string, opensnack: Function, opensnackerror: Function, setOpen: Function) => {
    if(!email || email === ''){
        opensnackerror(true);
        return;
    }

    try {        
        await authService.resetPassword(email);
        opensnack(true);
        setOpen(false);
    } catch (error) {
        opensnackerror(true);
    }
}

function ModalForgottenPassword({ open, setOpen }: ModalForgottenPasswordProps) {
    const [opensnack, setOpenSnack] = React.useState(false);
    const [opensnackErrorEmail, setOpensnackErrorEmail] = React.useState(false);
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };
    const user = useSelector((state) => (state as any).user);
    const dispatch = useDispatch();
    return (
        <>
            <Snackbar open={opensnack} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Hemos enviado un correo a tu cuenta con las instrucciones!
                </Alert>
            </Snackbar>
            <Snackbar open={opensnackErrorEmail} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Ha habido un error, vuelve a intentarlo más tarde.
                </Alert>
            </Snackbar>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='password-modal'>
                    <div className='header'>
                        <img src={lockImage} className="App-logo" alt="logo" />
                        <div className="text-center">¿Olvidaste tu contraseña?</div>
                    </div>
                    <div className='body'>
                        <div className="text-center ph-30">Introduce el email con el que te registraste</div>
                        <EsfirusTextInput className="mt-20" placeholder="Email" onChange={(e: any) => {
                            dispatch(
                                setuser({ ...user.data, email: e.target.value })
                            );
                        }} />
                        <EsfirusButton className="mt-20" width={290} fontSize={25} size='small' onClick={() => resetPass(user.data.email, setOpenSnack, setOpensnackErrorEmail, setOpen)}>Recuperar contraseña</EsfirusButton>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ModalForgottenPassword;