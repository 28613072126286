import { createSlice } from "@reduxjs/toolkit";

const initial = {
  header: false,
  list: [],
};

export const validationSlice = createSlice({
  name: "validationParte",
  initialState: { ...initial },
  reducers: {
    reset: (state, action: any) => {
      state = { ...initial };
    },
    setValidationHeader: (state, action: any) => {
      state.header = action.payload as any;
    },
    resetValidators: (state) => {
      state.header = false;
    },
  },
});

export default validationSlice.reducer;
export const { setValidationHeader, resetValidators, reset } = validationSlice.actions;
