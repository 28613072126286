import "./Table.scss";
import EsfirusTable from "@components/ui/Table/EsfirusTable";
import checkIcon from "@assets/svgs/check.svg";
import confirmedIcon from "@assets/images/lock.png";
import closeIcon from "@assets/svgs/close.svg";
import { rolesSupervisor } from "@helpers/constants";
import { EsfirusTablePropsPagination, HandleChangePaginationType } from "@services/hooks/useHandlerPaginationTable";

export default function EsfirusPartesTable(props: EsfirusPartesTableProps) {
  const { pagination, totalItems, handleChangePagination } = props

  const columns = [
    {
      name: "Empresa - Nº Parte",
      keymap: "_id",
      see: true,
      order: true,
      orderKeymap: "id",
      width: 150
    },
    {
      name: "Fecha",
      keymap: "date",
      parse: (d: string) => new Date(d).toLocaleDateString(),
      order: true,
      width: 90
    },
    {
      name: "T",
      keymap: "tipo",
      order: true,
      width: 20
    },
    {
      name: "Nº y Desc. Obra",
      keymap: "nombreObra2",
      order: true,
      orderKeymap: "nombreObra",
      width: 250
    },
    {
      name: "Empleado",
      keymap: "nombre_empleado",
      order: true,
      width: 150

    },
    {
      name: "Horario",
      keymap: "horaInicioFin",
      order: false,
      parse: (d: any, row: any) => {
        return <p>{row.horaInicio}<br />{row.horaFin}</p>
      },
      width: 60
    },
    //  {
    //   name: "Hora Inicio",
    //   keymap: "horaInicio",
    //   order: false,
    //   width: 60
    // },
    // {
    //   name: "Hora Fin",
    //   keymap: "horaFin",
    //   order: false,
    //   width: 60
    // },
    {
      name: "Horas",
      keymap: "horas",
      order: true,
      width: 60
    },
    {
      name: "Imp.",
      keymap: "importe",
      parse: (d: any) => {
        return d ? Number(d).toFixed(2).replace(".00", "").replace(".", ",") + "€" : "";
      },
      order: true,
      width: 50
    },
    {
      name: "Estado",
      keymap: "validadoImg",
      width: 60
    }
  ];

  const parseRows = (rows: any) => {
    return rows.map((el: any) => {
      const empresa: any = props.empresas?.find((e: any) => e.Empresa == el.empresa);
      return {
        ...el,
        validadoImg: setValidation(el.validado),
        _id: (empresa?.Nombre ?? el.empresa) + "-" + el.id,
        nombreObra2: `${el?.idObra} - ${el.nombreObra}`,

      };
    });
  };

  const setValidation = (type: number) => {

    const icons: any = {
      0: { icon: "", class: "", type },
      1: { icon: confirmedIcon, class: "confimed-icon", type },
      2: { icon: checkIcon, class: "validated-icon", type },
      3: { icon: closeIcon, class: "rejected-icon", type },
    };
    // return type
    return icons[type];
  };

  return (
    <EsfirusTable
      rows={parseRows(props?.partes)}
      deleteReport={props?.deleteReport}
      columns={columns}
      rowsCount={totalItems}
      pagination={pagination}
      customPageSizes={[10, 25, 50, 100]}
      withPagination={true}
      handleChangePagination={handleChangePagination}
    ></EsfirusTable>
  );
}

export interface EsfirusPartesTablePropsOption {
  label: string;
  value: any;
}

export interface EsfirusPartesTableProps {
  partes?: [];
  rol?: number;
  empresas?: [];
  deleteReport?: (id: string) => void;
  pagination?: EsfirusTablePropsPagination
  totalItems?: number
  handleChangePagination?: HandleChangePaginationType
}
