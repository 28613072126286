import HomeBackoffice from "../pages/home/home";

export const backHomeHeaders = {
    type: "Page",
    options: {
        name: "Home backoffice",
        route: {
            path: "/backoffice",
            to: "/backoffice",
            unAuth: false,
            auth: true,
            exact: true,
            component: (props) => {
                return (
                    <div style={{ width: "100%" }}>
                        <HomeBackoffice />
                    </div>
                )
            },
        },
    },
};

export const gotoAppHeaders = {
    type: "Page",
    options: {
        name: "Go to app",
        route: {
            path: "/gotoapp",
            to: "/gotoapp",
            unAuth: false,
            auth: true,
            exact: true,
            component: (props) => {
                window.location.href = "/splash"
                return (
                    <div style={{ width: "100%" }}>
                        <HomeBackoffice />
                    </div>
                )
            },
        },
    },
};