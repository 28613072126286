import { createSlice } from "@reduxjs/toolkit";

const initial = {
  list: [],
}

export const manoObraSlice = createSlice({
  name: "mano_obra",
  initialState: { ...initial },
  reducers: {
    resetManoObra: (state, action: any) => {
      state.list = [...initial.list];
    },
    setManoObra: (state, action: any) => {
      state.list = [...action.payload] as any;
    },
  },
});

export default manoObraSlice.reducer;
export const { setManoObra, resetManoObra } = manoObraSlice.actions;