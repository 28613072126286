import { apiConfig } from "./config";
import { ReportsApi } from "./swagger";

class ReportsService extends ReportsApi {
    accessToken = "";

    async get(nombre: string, fechaInicio: Date, tipoHora: number) {
        const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
        return this.reportGridGet({
            headers: { Authorization: accessToken }, params: {
                nombreEmpresa: nombre,
                fechaInicio,
                tipoHora
            }
        });
    }

    async getLastMonths(months: number = 3) {
        const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
        const timeDiff = new Date().getTimezoneOffset();

        return this.reportGridGet({
            headers: { Authorization: accessToken },
            params: { months, timeDiff }

        });
    }


    async getClosedDay() {
        const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
        return this.reportsGetClosedDay({
            headers: { Authorization: accessToken }
        });
    }

    async deleteReport(id: any) {
        const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
        return this.reportIdDelete(id, {
            headers: { Authorization: accessToken },
        });
    }


}

export const reportsService = new ReportsService(apiConfig);
