import React, { createContext, useState, useEffect } from 'react'
const ConfigContext = createContext()

const ConfigProvider = ({ children }) => {
    
    // Este set inicial en realidad es para pruebas, porque se configura a necesidad cuando carga la data que viene de la DB. Dejo para referencia de como se arma el state
    const [state, setState] = useState({})  

    return (
        <ConfigContext.Provider value={{ state, setState}}>
            {children}
        </ConfigContext.Provider>
    )
}

export { ConfigContext, ConfigProvider }
