import { createSlice } from "@reduxjs/toolkit";
import { fichajesService } from "@services/fichajes";
import { getDateUTC } from "@services/hooks/TimeHook";

const initial = {
  list: [],
};

export const TimerTrackingSlice = createSlice({
  name: "timeTracking",
  initialState: { ...initial },
  reducers: {
    setListadoFichajes: (state, action: any) => {
      state.list = [...action.payload] as any;
    }
  },
});

export default TimerTrackingSlice.reducer;
export const { setListadoFichajes } = TimerTrackingSlice.actions;

export const getTimeTrackList = (months: number = 1) => (dispatch: any) => {
  fichajesService
    .get(months)
    .then((res: any) => dispatch(setListadoFichajes((res.data as any)?.data as any)));
};
