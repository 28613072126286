import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Modal } from "@mui/material";

import EsfirusButton from "../../../../components/ui/Button/EsfirusButton";
import EsfirusTextInput from "../../../../components/ui/Text-input/EsfirusTextInput";

import "../ModalNewObra/ModalNewObra.scss";

export interface Proveedor {
  Ambito: number;
  Poblacion: string;
  Proveedor: number;
  RazonSocial: string;
}

interface ModalNewArticuloProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  selectProveedor: (proveedor: Proveedor) => void;
}

interface FilterInputProps {
  label: string;
  value: string;
  placeholder?: string;
  onChange: (e: any) => void
}

const FilterInput = ({ label, value, placeholder, onChange }: FilterInputProps) => {
  return (
    <Grid item md={6} xs={12}>
      <label>{label}</label>
      <EsfirusTextInput
        value={value || ""}
        onChange={onChange}
        placeholder={placeholder || label}
        fullWidth
      />
    </Grid>
  )
}

const ModalNewProveedor = ({ open, setOpen, selectProveedor }: ModalNewArticuloProps) => {
  const allProveedores = useSelector((state) => (state as any).proveedores).list;

  const [proveedoresList, setProveedoresList] = useState<any>([]);
  const [filterNameCode, setFilterNameCode] = useState('');
  const [currentProveedor, setCurrentProveedor] = useState<Proveedor>({
    Ambito: 0,
    Poblacion: "",
    Proveedor: 1,
    RazonSocial: ""
  });

  useEffect(() => {
    const collectData = async () => {

      const resultProveedores = allProveedores.
        filter((proveedor: Proveedor) => filterNameCode !== '' ? (proveedor?.Proveedor?.toString().toLowerCase()?.includes(filterNameCode.toLowerCase()) || proveedor?.RazonSocial?.toLowerCase()?.includes(filterNameCode.toLowerCase())) : proveedor)?.
        map((proveedor: Proveedor) => ({ ...proveedor, descripcion_list: `${proveedor.Proveedor} - ${proveedor.RazonSocial}` }));

      setProveedoresList(resultProveedores)
    }

    collectData()
  }, [allProveedores, filterNameCode])

  const acceptClose = () => {
    selectProveedor(currentProveedor);
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="new-obra-modal">
        <div className="header">
          <h5 className="text-center">Selecciona el proveedor</h5>
        </div>
        <div className="body">
          <p className="text-center-mobile">
            Filtra el listado de proveedores buscando por nombre o código:
          </p>

          <Grid container spacing={4} md={12}>
            <FilterInput label="Nombre o código" value={filterNameCode} onChange={(e) => setFilterNameCode(e.target.value)} />
          </Grid>

          <Grid container spacing={4} md={12}>
            <Grid className="mt-20" item md={12} xs={12}>

              <div className="obra-container">
                <div className="scroll">
                  {!proveedoresList.length && (
                    <div>No hay proveedores</div>
                  )}
                  {proveedoresList.map((proveedor: any, index: any) => {
                    return (
                      <div
                        key={`modal-new-articulo-articulo-${index}`}
                        className={
                          "obra-item" +
                          (currentProveedor.Proveedor === proveedor.Proveedor
                            ? " selected"
                            : "")
                        }
                        onClick={() => {
                          setCurrentProveedor(proveedor)
                        }}
                        onDoubleClick={() => {
                          acceptClose()
                        }}
                      >
                        {proveedor.descripcion_list || "--"}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Grid>

          </Grid>

          <div className="button-container">
            <EsfirusButton
              onClick={() => acceptClose()}
              fullWidth
              color="secondary"
            >
              Aceptar
            </EsfirusButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalNewProveedor;
