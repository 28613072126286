import { env } from '@helpers/env';
import { Configuration } from './swagger/configuration';

const dispatchGeneralErrorEvent = (messageError: string) => {
    const event = new CustomEvent("generalerror", { detail: { messageError } })
    document.dispatchEvent(event)
}

export const apiConfig: Configuration = {
    accessToken: localStorage.getItem('ef-token') || '',
    basePath: (env.REACT_APP_API_URL ? env.REACT_APP_API_URL.includes("http") ? env.REACT_APP_API_URL : "https://" + env.REACT_APP_API_URL : 'http://localhost:3001'),
    catchError(error: any) {
        // Mostrar modal de error siempre cuando haya un fallo de api.
        const messageError = error.response.data.displayMessage as string
        dispatchGeneralErrorEvent(messageError)

        if (error?.response?.data?.message?.includes("jwt ")) {
            localStorage.clear();
            window.location.href = "/";
        }

        console.error(error)
        throw error;
    }
}