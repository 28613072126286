import "./header.scss";
import EsfirusButton from "@ui/Button/EsfirusButton";
import EsfirusIcon from "@ui/icons/EsfirusIcon";
import documentIcon from "@assets/images/icons/documentIcon.png";
import userIcon from "@assets/images/icons/user.png";
import listIcon from "@assets/images/icons/listIcon.png";

import { useNavigate } from "react-router-dom";
import { isAdmin, isEmpleado, isJefeObra, isSupervisor } from "@services/hooks/UserHook";
import { removeSelectedObra } from "@store/slices/selectedObra";
import { useDispatch } from "react-redux";

function Header({ showResumen }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="ef-fichar-header noPrint">
      <EsfirusButton
        color="secondary"
        startIcon={<EsfirusIcon path={userIcon} />}
        onClick={() => {
          dispatch(removeSelectedObra());
          navigate("/time-tracking");
        }}
      >
        Fichar
      </EsfirusButton>

      < EsfirusButton
        color="primary"
        variant="outlined"
        startIcon={<EsfirusIcon path={listIcon} />}
        onClick={() => navigate("/time-report")}
      >
        Registro
      </EsfirusButton>

      < EsfirusButton
        color="info"
        startIcon={<EsfirusIcon path={userIcon} />}
        onClick={() => navigate("/time-month-report")}
      >
        Resumen mensual
      </EsfirusButton>


    </div >
  );
}

export default Header;
