import { useState } from "react";



export default function EsfirusTextInput(props: any) {
  const [clicked, setClicked] = useState(false);

  return (
    <div className={"line-text"+(clicked ? " clicked":"")}  onClick={() => setClicked(!clicked)}>
      {props.children}
    </div>
  )
 
}
