import Grid from "@mui/material/Grid";
import textoIcon from "@assets/svgs/lineasTexto.svg";
import manoDeObraIcon from "@assets/svgs/lineasManoDeObra.svg";
import { EsfirusInputTextNative } from "@ui/InputTextNative/EsfirusInputTextNative";
import EsfirusSelect from "@components/ui/Select/EsfirusSelect";
import EsfirusButton from "@components/ui/Button/EsfirusButton";

import { useEffect, useState, useContext } from "react";
import { PerfilContext } from './context/Perfil.context'
import { useNavigate, useParams } from 'react-router-dom';

import { getRequest, putRequest } from '../../utils/requests'

import { textos, types } from './helpers';

const Perfil = (props) => {
    const history = useNavigate();

    const { state, setState} = useContext(PerfilContext)
    const [roles, setRoles] = useState([]);

    const params = useParams();
    const [empresas, setEmpresas] = useState([]);

    const {
        id
    } = params //props?.match?.params;

    // Obsoleto
    const handleChange = (e, atribute) =>{

        const newState = {
            ...state,
            datos_usuario : {
                ...state?.datos_usuario,
                [atribute] : e                
            }                
        }
        setState(newState);
    }

    const actualizarConfig = async (forceState) => {
        const _state = forceState ?? state
        const dataUpdate = {
            id,
            ..._state?.datos_usuario
        };
        const updData = await putRequest('users', dataUpdate );
    }

    const fetchData = async () =>{
        const {data} = await getRequest(`users/${id}`)
        const extra = JSON.parse(data.data)
        const newState = {
            ...state,
            datos_usuario : {
                ...state?.datos_usuario,
                email: data?.email,  
                nombre: data?.nombre,  
                apellidos: data?.apellidos,         
                rol: data?.rol,   
                activo: data?.activo ?? 0,
                password: null,
            },          
            extra_external : extra?.external?.length>0 ? [...extra?.external] : [],
            extra_internal : extra?.internal?.length>0 ? [...extra?.internal] : [],
            originalRol: extra.originalRol,
        }        
        setState(newState);

        const {data : dataRoles} = await getRequest(`roles`);
        const newRoles = dataRoles?.map(rol=> ({ label: rol.rol, value: rol.id }) )
        setRoles(newRoles);

        // Obtenemos datos del cacho
        const {data : dataCache} = await getRequest(`cache`);
        if(dataCache?.empresas) setEmpresas(dataCache.empresas);      

    }

    useEffect(()=>{
        fetchData();
    }, []);

    const renderAtribute = (group, _keyName, disabled = false, index = null) =>{
        const _types = types?.find(e=>e.property === _keyName);        

        
        let _value = index !== null ? state?.[group][index]?.[_keyName] : state?.[group]?.[_keyName];
        if(_keyName ==='empresa' ){
            const _empresa = empresas?.find(e=>e.Empresa === _value);            
            if(_empresa) _value = _empresa?.Nombre;
        }

        switch( _types?.type?.toString() ){
            case "select":
                return (
                        <EsfirusSelect
                            disabled={disabled}
                            options={ _keyName === 'rol' ? roles?.filter(rol => rol.value == _value || rol.value == 1 || rol.value == state.originalRol) : _types?.options }
                            value={ _value }
                            change={(e) => {
                                handleChange(e, _keyName)
                            }}
                            placeholder="">
                        </EsfirusSelect> 
                    )              
            default :
                return (
                    <EsfirusInputTextNative                        
                        disabled={disabled}
                        value={ _value }
                        change={(e) =>
                            handleChange(e, _keyName)
                        }                                
                    ></EsfirusInputTextNative>
                )
        }
    }

    const handlerSave = async () => {
        if (state?.datos_usuario?.password && state.datos_usuario.password !== "") {
            if (window.confirm("La contraseña del usuario va a ser modificada, ¿estás seguro que deseas continuar?")) {
                await actualizarConfig()
            }
        } else {
            await actualizarConfig()
        }
    }

    return (
        <>            
            
            { 
                <>
                    
                        
                        <Grid
                            className="line-services-container"
                            container
                            spacing={1}
                            alignItems={"left"} 
                            style={{marginBottom: 50}}
                           
                            >
                            <Grid
                                className="flex-row"
                                item
                                xs={12}
                                md={2}
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                <img className="logo mr-10" src={manoDeObraIcon} alt="service" />
                                <h4>Datos del usuario</h4>
                            </Grid>
                            <Grid item xs={12} md={10} container spacing={3}>
                                {
                                    Object.keys(state?.datos_usuario).map((_keyName, _i) =>(
                                    <Grid item xs={12} md={8}>
                                        <label>{textos[_keyName] ?? _keyName}</label>                                                                                                                    
                                        {
                                            renderAtribute('datos_usuario',_keyName, _keyName !== "rol" && _keyName !== "password")
                                        }
                                        {_keyName === "password" && (
                                            <span>Si no desea cambiar la contraseña, déjelo vacío.</span>
                                        )}
                                    </Grid>
                                    ))
                                }
                            </Grid>
                            
                        </Grid>
                        
                        {
                            state?.extra_internal?.length>0 &&                         
                            <Grid
                                className="line-services-container"
                                container
                                spacing={1}
                                alignItems={"left"}  
                                style={{marginBottom: 50}}
                          
                                >
                                <Grid
                                    className="flex-row"
                                    item
                                    xs={12}
                                    md={2}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                >
                                    <img className="logo mr-10" src={manoDeObraIcon} alt="service" />
                                    <h4>Empresa Interna</h4>
                                </Grid>
                                <Grid item xs={12} md={10} container spacing={3}>
                                    {
                                        state?.extra_internal.map((obj, _i_extra) =>(                                        
                                            Object.keys(obj).map((_keyName, _i) =>(
                                                <Grid item xs={_keyName ==='empresa' ? 6 : 2} md={_keyName ==='empresa' ? 6 : 2} spacing={4}>
                                                    <label>{textos[_keyName] ?? _keyName}</label>                                                                                                                    
                                                    
                                                    {                                            
                                                        renderAtribute('extra_internal', _keyName, true, _i_extra)   
                                                    }

                                                </Grid>
                                            ))
                                        ))
                                    }
                                </Grid>
                                
                            </Grid>
                        }

                        {
                            state?.extra_external?.length>0 && 
                            <Grid
                                className="line-services-container"
                                container
                                spacing={1}
                                alignItems={"left"}      
                                style={{marginBottom: 50}}
                      
                                >
                                <Grid
                                    className="flex-row"
                                    item
                                    xs={12}
                                    md={2}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                >
                                    <img className="logo mr-10" src={manoDeObraIcon} alt="service" />
                                    <h4>Empresa Externa</h4>
                                </Grid>
                                <Grid item xs={12} md={10} container spacing={3}>
                                    {
                                        state?.extra_external.map((obj, _i_extra) =>(                                        
                                            Object.keys(obj).map((_keyName, _i) =>(
                                                <Grid item xs={_keyName ==='empresa' ? 6 : 2} md={_keyName ==='empresa' ? 6 : 2} spacing={4}>
                                                    <label>{textos[_keyName] ?? _keyName}</label>                                                                                                                    
                                                    
                                                    {                                            
                                                        renderAtribute('extra_external', _keyName, true, _i_extra)   
                                                    }

                                                </Grid>
                                            ))
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        }
                                                    
                        
                    

                    <Grid
                        className="line-services-container"
                        container
                        spacing={1}
                        alignItems={"left"}
                        justifyContent={"center"}   
                    >
                        <Grid
                            className="flex-row"
                            item                            
                            xs={5}
                            md={5}
                            alignItems={"center"}
                            justifyContent={"center"}                        
                        >
                            <div className="button-container center">
                                <EsfirusButton
                                    onClick={() => {
                                        history(-1)
                                    }}
                                    fullWidth
                                    color="primary"
                                    >
                                    Regresar
                                </EsfirusButton>
                                
                            </div>
                        </Grid>
                        <Grid
                            className="flex-row"
                            item                            
                            xs={5}
                            md={5}
                            alignItems={"center"}
                            justifyContent={"center"}                        
                        >
                            <div className="button-container center">
                                <EsfirusButton
                                    onClick={handlerSave}
                                    fullWidth
                                    color="primary"
                                >
                                    Actualizar
                                </EsfirusButton>
                            </div>
                        </Grid>
                    </Grid>
                </>                
            }            

        </>
    )

}

export { Perfil };