import { Box } from "@mui/material"
import FormPedido, { FormDataPedido } from "@components/FormParte/FormPedido"
import "./NuevoPedido.scss"
import { pedidosService } from "@services/pedidos"
import { useCallback, useEffect, useRef, useState } from "react"
import ModalConfirmPedido from "./BBs/ModalConfirmPedido/ModalConfirmPedido"

const NuevoPedido = () => {
  const [openModalConfirmPedido, setOpenModalConfirmPedido] = useState(false)
  const [dataPedidoCreated, setDataPedidoCreated] = useState(null)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const savingFailedCounter = useRef<number>(0)
  const lastFailedDataSent = useRef<FormDataPedido | null>(null)

  const sendFailedEmail = useCallback(async () => {
    if (savingFailedCounter.current === 0 || !lastFailedDataSent.current) return
    await pedidosService.sendFailedEmail(lastFailedDataSent.current)
  }, [savingFailedCounter.current, lastFailedDataSent.current])

  useEffect(() => {
    const handleUnload = () => sendFailedEmail()

    window.addEventListener("unload", handleUnload)

    return () => {
      window.removeEventListener("unload", handleUnload)
      sendFailedEmail()
    }
  }, [])

  // Función de manejo del save de datos
  const handleSave = async (data: FormDataPedido) => {
    setIsSaving(true)
    try {
      const resp = await pedidosService.create({ ...data, date: data.date.valueOf() })
      setDataPedidoCreated(resp?.data?.data)
      lastFailedDataSent.current = null
      setOpenModalConfirmPedido(true)
    } catch (error) {
      savingFailedCounter.current++
      lastFailedDataSent.current = { ...data, date: data.date.valueOf() }
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <>
      <div className="new-pedido-wrapper">
        <div className="new-pedido-title-wrapper">
          <h4>Pedido de compra</h4>
          <h5>Rellena el formulario para completar el pedido</h5>
        </div>
        <div className="new-pedido-body-wrapper">
          <Box className="ml-20 mr-20 mt-40">
            <FormPedido
              handleSave={handleSave}
              isSaving={isSaving}
              editable={true}
            />
          </Box>
        </div>
      </div>

      {/* Modales */}
      {openModalConfirmPedido && (
        <ModalConfirmPedido
          open={openModalConfirmPedido}
          setOpen={setOpenModalConfirmPedido}
          data={dataPedidoCreated}
        />
      )}
    </>
  )
}

export default NuevoPedido