import { useEffect, useState } from "react";
import Header from "@components/Header/Header";
import PartesHeader from "./BBs/header/header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isEmpleado } from "@services/hooks/UserHook";
import Chat from "@externalmodules/ia/chat.jsx";
import { env } from "@helpers/env";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const configuration = useSelector((state) => (state as any).configurations.config);

  const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
  const API_URL = env.REACT_APP_API_URL




  return (
    <>
      <Header />
      <div className="center">
        <PartesHeader showResumenButton={!isEmpleado() || configuration?.report?.reportCerrarDias}></PartesHeader>
        <Chat
          token={accessToken}
          url={API_URL}
        />
      </div>
    </>
  );
}

export default Home;
