import { createSlice } from "@reduxjs/toolkit";
import { categoriasService } from "@services/categorias";

const initial = {
  list: [],
}

export const categoriasSlice = createSlice({
  name: "categorias",
  initialState: { ...initial },
  reducers: {
    resetCategorias: (state, action: any) => {
      state.list = [...initial.list];
    },
    setcategorias: (state, action: any) => {
      state.list = [...action.payload] as any;
    },
  },
});

export default categoriasSlice.reducer;
export const { setcategorias, resetCategorias } = categoriasSlice.actions;


