import { useEffect, useRef, useState } from "react";
import "./EsfirusHourInput.scss";
import clock from "@assets/images/icons/clock.svg";
import { useOutsideClick } from "@services/hooks/outsideClickHook";
export default function EsfirusHourInput(props: EsfirusHourInputProps) {
  const [hour, setHour] = useState<string | null>(null);
  const [min, setMin] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  let hourRef: any = []
  hourRef[0] = useRef(null)
  hourRef[1] = useRef(null)
  hourRef[2] = useRef(null)
  hourRef[3] = useRef(null)
  hourRef[4] = useRef(null)
  hourRef[5] = useRef(null)
  hourRef[6] = useRef(null)
  hourRef[7] = useRef(null)
  hourRef[8] = useRef(null)
  hourRef[9] = useRef(null)
  hourRef[10] = useRef(null)
  hourRef[11] = useRef(null)
  hourRef[12] = useRef(null)
  hourRef[13] = useRef(null)
  hourRef[14] = useRef(null)
  hourRef[15] = useRef(null)
  hourRef[16] = useRef(null)
  hourRef[17] = useRef(null)
  hourRef[18] = useRef(null)
  hourRef[19] = useRef(null)
  hourRef[20] = useRef(null)
  hourRef[21] = useRef(null)
  hourRef[22] = useRef(null)
  hourRef[23] = useRef(null)
  hourRef[24] = useRef(null)

  let minRef: any = []
  minRef[0] = useRef(null)
  minRef[15] = useRef(null)
  minRef[30] = useRef(null)
  minRef[45] = useRef(null)

  const ref = useRef(null);

  useOutsideClick(ref, () => setOpen(false))

  useEffect(() => {
    if (props?.value) {
      const splitted = props.value.split(':');
      setHour(splitted[0]);
      setMin(splitted[1]);
    }
  }, [props]);


  useEffect(() => {
    let hourM = hour
    let minM = min
    if (open && (!hour || !min)) {
      hourM = hour ?? "08";
      minM = min ?? "00";
    }

    if (open) {
      hourRef[Number(hourM)]?.current?.scrollIntoView({ block: 'center' })
      minRef[Number(minM)]?.current?.scrollIntoView({ block: 'center' })
    }

  }, [hour, min, open]);

  return (
    <div style={props?.style ?? {}} className={`ef-timepicker ${props?.disabled ? ' disabled' : ''} ${props?.modified ? "ef-timepicker-modified" : ""}`}
      id={props.id}
      onFocus={(e: any) => {
        if (props?.focus) {
          e.preventDefault();
          props?.focus();
        }
      }}
    >
      <div
        className="placeHolder"
        onClick={() => {
          if (!props?.disabled) {
            setOpen(true);
          }
        }}
      >
        <div className="selectedhour">
          {hour ? hour.toString().padStart(2, '0').replace('null', '00') : '--'}
        </div>
        :
        <div className="selectedmin">
          {min ? min.toString().padStart(2, '0').replace('null', '00') : '--'}
        </div>
        <img src={clock} alt="" />
      </div>
      {open ? (
        <div className="selector" ref={ref}>
          <div className="hourlist">
            <div style={{ height: 44 }}></div>

            {Array.from(Array(25).keys()).map((h, index) => {
              const parsed = (index).toString().padStart(2, '0').replace('null', '00');
              return (
                <div
                  className={'slot' + (hour && hour === parsed ? ' selected' : '')}
                  ref={hourRef[index]}
                  onClick={() => {
                    setHour(parsed);
                    props.change(parsed + ':' + min)
                    if (min) {
                      setOpen(false);
                    }
                  }}
                >
                  {parsed}
                </div>
              );
            })}
            <div style={{ height: 44 }}></div>

          </div>
          <div className="minlist">
            <div style={{ height: 44 }}></div>
            {Array.from(Array(4).keys()).map((h, index) => {
              const parsed = (index * 15).toString().padStart(2, '0').replace('null', '00');
              return (
                <div
                  className={'slot' + (min && min === parsed ? ' selected' : '')}
                  ref={minRef[index * 15]}
                  onClick={() => {
                    setMin(parsed);
                    props.change(hour + ':' + parsed)
                    if (hour) {
                      setOpen(false);
                    }
                  }}
                >
                  {parsed}
                </div>
              );
            })}
            <div style={{ height: 44 }}></div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export interface EsfirusHourInputProps {
  id?: string;
  value?: any;
  change?: any;
  disabled?: boolean;
  click?: any;
  focus?: any;
  style?: any;
  modified?: any;
}
