import { setObras } from "@store/slices/obras";
import axios from "axios";
import { apiConfig } from "./config";
import { Configuration } from "./swagger/configuration";

class CacheService {
  accessToken = "";
  basePath = "";

  constructor(_apiConfig: Configuration) {
    this.accessToken = (_apiConfig.accessToken || '') as string;
    this.basePath = (_apiConfig.basePath || '') as string;
  }

  async getCache() {
    return await axios({
      baseURL: this.basePath + '/cache',
      headers: {
        Authorization: `Bearer ${localStorage.getItem("ef-token") as string}`
      },
      method: 'get'
    }).catch((error) => {
      apiConfig?.catchError(error);
    });
}
}

export const cacheService = new CacheService(apiConfig);
