import axios from "axios";
import { apiConfig } from "./config";
import { UsersApi } from "./swagger";

class UserService extends UsersApi {

    async updateNotification(data: any) {
        const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
        return this.userIdPutNotification(data.id, {
            headers: { Authorization: accessToken },
            data,
        });
    }

    async getAll() {
        try {
            const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;

            const response = await axios({
                baseURL: this.basePath + '/users/all',
                headers: { Authorization: accessToken },
                method: 'get'
            })

            return response
        } catch (error) {
            apiConfig?.catchError(error)
        }
    }

    async getListByPartesFilter({ estado, filters }: any) {
        try {
            const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;

            const response = await axios({
                baseURL: this.basePath + '/users/by_partes_filter',
                headers: { Authorization: accessToken },
                method: 'get',
                params: { estado, filters: JSON.stringify(filters ?? {}) }
            })

            return response
        } catch (error) {
            apiConfig?.catchError(error)
        }
    }
}

export const usersService = new UserService(apiConfig);
