import moment from "moment"
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Header from "@components/Header/Header";
import EsfirusResume from "@components/ui/Resume/EsfirusResume";
import TrackHorasResumenHeader from "./BBs/header/header";
import EsfirusTable from "@components/ui/TableDays/EsfirusTable";
import EsfirusButtonNative from '@components/ui/Button/EsfirusButtonNative';

import { isEmpleado } from "@services/hooks/UserHook";
import { emptyResourceRedirect } from '@helpers/navigate';
import { fetchMonthReports, getRowData } from "./utils/functions";
import { getCurrentMonth, getDaysOfMonth } from "@helpers/utils";

import backIcon from "@assets/images/icons/back-arrow.svg";

function TrackHorasResumen() {
  const navigate = useNavigate()
  const empresas = useSelector((state) => (state as any).empresas).list

  const [isLoadingDataGrid, setIsLoadingDataGris] = useState(false)
  const [fichajesByUser, setFichajesByUser] = useState([])
  const [grid, setGrid] = useState({ columns: [], rows: [] })
  const [filters, setFilters] = useState({
    empresa: "",
    month: `${getCurrentMonth().monthName} - ${getCurrentMonth().yearName}`,
    search: ""
  })

  useEffect(() => {
    refreshDataGrid()
    emptyResourceRedirect({ check: empresas.length === 0, path: "/time-month-report", navigate })
  }, [])

  useEffect(() => {
    generateGrid()
  }, [fichajesByUser, filters])

  const refreshDataGrid = async () => {
    setIsLoadingDataGris(true)
    try {
      const res = await fetchMonthReports()
      setFichajesByUser(res?.data?.data ?? [])
    } finally {
      setIsLoadingDataGris(false)
    }
  }

  const getColumnsDays = () => {
    const mesAnioSplit = filters.month.split(' - ')
    const month = mesAnioSplit[0]
    const year = mesAnioSplit[1]

    const daysInMonth = getDaysOfMonth(month, year)

    return daysInMonth.map((day: any) => ({ name: day, keymap: day, parse: (v: Number) => Number(v).toFixed(2).replace(".00", "") }))
  }

  const generateGrid = () => {
    const columnDays = getColumnsDays()
    const _grid: any = { columns: [], rows: [] }

    _grid.columns = [..._grid.columns, { name: 'Empleado', keymap: 'empleado' }]
    _grid.columns = [..._grid.columns, ...columnDays]
    _grid.columns = [..._grid.columns, { name: 'Total', keymap: 'total', parse: (v: Number) => Number(v).toFixed(2).replace(".00", "") }]

    const filteredBySearch = fichajesByUser.filter((p: any) => (p.nombre_empleado || "").toString().toLowerCase().includes(filters.search.toLowerCase()))
    _grid.rows = filteredBySearch.map((r: any) => getRowData(r, columnDays, filters)).filter((r: any) => r.total > 0)

    setGrid(_grid)
  }

  if (isLoadingDataGrid) {
    return (
      <>
        <Header />
        <div className="center ef-report">
          <div className="ef-report-title">
            <EsfirusButtonNative
              className={"tertiary"}
              label={window.innerWidth > 500 ? "Volver" : ""}
              icon={backIcon}
              iconClass={"icon-tertiary"}
              click={() => navigate(-1)}
            ></EsfirusButtonNative>
            <div className="title">
              <h4>Cargando datos</h4>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Header />
      <div className="center ef-report">
        <div className="ef-report-title">
          <EsfirusButtonNative
            className={"tertiary"}
            label={window.innerWidth > 500 ? "Volver" : ""}
            icon={backIcon}
            iconClass={"icon-tertiary"}
            click={() => navigate(-1)}
          ></EsfirusButtonNative>
          <div className="title">
            <h4>Resumen mensual de fichajes</h4>
            <p>{!isEmpleado()
              ? "Selecciona la empresa y el mes que quieras visualizar"
              : "Selecciona el mes que quieras visualizar"
            }</p>
          </div>
        </div>
        <TrackHorasResumenHeader filters={filters} setFilters={setFilters} />
        {window.innerWidth > 500 ? (
          <>
            {filters.month !== "" && (
              <EsfirusTable
                rows={grid.rows}
                columns={grid.columns}
                selectedDate={moment(filters.month).format("MM-YYYY")}
                disableOnClickCell={true}
              />
            )}
            <EsfirusResume partes={[]} reports={grid} resumenMensual={true} resumenHorasExtra={false} ></EsfirusResume>
          </>
        ) : (
          <div className="title-rotate">
            <p>Rota la pantalla para poder ver</p>
          </div>
        )}
      </div>
    </>
  );
}

export default TrackHorasResumen;