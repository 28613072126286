import moment from "moment";
import { configurationService } from "@services/configApp";
import { fichajesService } from "@services/fichajes";
import { isEmpleado } from "@services/hooks/UserHook";

export const fetchMonthReports = () => {
  const { listadoMeses, listadoMesesAdmin } = configurationService.getConfigFichaje()
  const months = (isEmpleado() ? listadoMeses : listadoMesesAdmin) ?? 1

  return fichajesService.getLastMonths(months)
}

export const getRowData = (fichajesByUser = [], columns = [], filters) => {
  const row = {
    empleado: fichajesByUser.nombre_empleado
  }

  const monthSelected = moment(filters.month, 'MMMM - YYYY')

  let sumValues = 0
  columns.forEach(date => {
    const res = fichajesByUser.fichajes.filter(r => {
      if (filters.empresa && filters.empresa !== "" && filters.empresa != r.empresa) {
        return false
      }

      const dateIn = moment(r.dateIn)
      const isCurrentDay = dateIn.year() === monthSelected.year() && dateIn.month() === monthSelected.month() && dateIn.date() == date.name
      return isCurrentDay
    });

    const sumHours = res.map(r => r.horas).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    const sumHoursRounded = Number(sumHours.toFixed(2))

    sumValues = sumValues + sumHoursRounded

    row[date.name] = { horas: sumHoursRounded }
  });

  return {
    ...row,
    id_empleado: fichajesByUser.id_empleado,
    total: sumValues
  }
}