import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Grid, InputAdornment } from "@mui/material";

import EsfirusIcon from "@components/ui/icons/EsfirusIcon";
import EsfirusTextInput from "@components/ui/Text-input/EsfirusTextInput";
import ModalNewObra from "@screens/NewParte/BBs/ModalNewObra/ModalNewObra";
import DatePickerUI from "@components/ui/DatePickerUI/DatePickerUI";
import ModalNewProveedor, { Proveedor } from "@screens/NewParte/BBs/ModalNewProveedor/ModalNewProveedor";

import { Obra, Capitulo, Presupuesto } from "@models/obra";
import { removeSelectedObra, updateSelectedObra } from "@store/slices/selectedObra";
import zoomIcon from "@assets/images/icons/zoomIcon.png";

const HeaderFormPedido = (props: any) => {
  const { formData, setFormData, dateIsInTheFuture } = props;

  const dispatch = useDispatch()
  const selectedobra = useSelector((state) => (state as any).selectedobra).obra
  const presupuesto = useSelector((state) => (state as any).presupuestos).list
  const capitulos = useSelector((state) => (state as any).capitulos).list

  const [openObraModal, setOpenObraModal] = useState(false)
  const [openProveedorModal, setOpenProveedorModal] = useState(false)

  const selectObra = async (obra: Obra) => {
    const { Administracion, obraCode } = obra;
    let capitulosByPresupuesto: Capitulo[] = [];
    let selectedPresupuesto = [];

    dispatch(removeSelectedObra());

    const presupuestosFiltered = getPresupuestosByObra(obra).map(
      (el: Presupuesto) => ({
        value: String(el.Empresa + "_" + el.Delegacion + "_" + el.Presupuesto + "_" + el.Anexo),
        label: String(el.Presupuesto + " - " + el.Descripcion),
      })
    );

    if (presupuestosFiltered.length === 1) {
      const _obj = getCapitulosByPresupuesto(presupuestosFiltered[0].value);
      capitulosByPresupuesto = _obj.capitulosByPresupuesto;
      selectedPresupuesto = _obj.selectedPresupuesto;
    }
    else if (presupuestosFiltered.length > 1) {
      const presupuestosIds = presupuestosFiltered.map((p: any) => p.value);
      const _capitulos: Capitulo[] = [...capitulos].filter(
        (el) => presupuestosIds.includes(el.Presupuesto.toString())
      );

      capitulosByPresupuesto = _capitulos.map((c: any) => ({
        ...c,
        ...formatCapituloLabel(c),
      }))
    }

    obra.tipo = Administracion ? 'A' : 'O';

    // Guardamos en reference el código del empleado de la empresa seleccionada según https://esfirus.atlassian.net/browse/PARTRAB-295?focusedCommentId=22588.
    obra.reference = obra.empleado?.toString() ?? ""

    dispatch(
      updateSelectedObra({
        ...selectedobra,
        ...obra,
        // presupuestos: presupuestosFiltered,
        // selectedPresupuesto,
        // capitulosByPresupuesto,
        blockedAdmin: Administracion,
      })
    );
  };

  const getPresupuestosByObra = (obra: any): Presupuesto[] => {
    return [...presupuesto].filter((el) => el.Obra == obra.obraCode && el.Empresa == obra.company && el.Delegacion == obra.delegacion);
  };

  const getCapitulosByPresupuesto = (id: string) => {
    const [Empresa, Delegacion, Presupuesto, Anexo] = id.split("_");

    const _capitulos: Capitulo[] = [...capitulos].filter(
      (el) => el.Presupuesto == Presupuesto && el.Empresa == Empresa && el.Delegacion == Delegacion && el.Anexo == Number(Anexo)
    );
    const selectedPresupuesto = [...presupuesto].filter(
      (el: Presupuesto) => el.Presupuesto === Number(Presupuesto) && el.Empresa == Number(Empresa) && el.Delegacion == Number(Delegacion) && el.Anexo == Number(Anexo)
    );


    const capitulosByPresupuesto: Capitulo[] = _capitulos?.map(
      (el: Capitulo) => ({
        ...el,
        disabled: !el?.Partida,
        ...formatCapituloLabel(el),
      })
    ).sort((a, b) => Number(a.Capitulo) - Number(b.Capitulo));
    return {
      selectedPresupuesto,
      capitulosByPresupuesto,
    };
  };

  const formatCapituloLabel = (capitulo: Capitulo) => {
    const finalcap: any = [];
    const fragmentos = capitulo.Capitulo.match(/.{1,3}/g);
    fragmentos?.forEach((el, k) => {
      const interestNumber = Number(el);
      const nextHaveNumber = Number(fragmentos.map((f, k2) => k2 > k ? f : "").join(""));
      if (interestNumber || nextHaveNumber) {
        finalcap.push(interestNumber);
      }
    });

    //insertar tabulador en prefix por cada elemento de finalcap
    let prefix = "";
    finalcap.forEach((el: any, k: any) => {
      prefix += k > 0 ? " - " : "";
    });

    const label = `cap ${finalcap.join(".")} ${capitulo.Descripcion}`;
    const labelPrefix = prefix;
    return { label, labelPrefix };
  };

  return (
    <>
      <Grid container spacing={{ xs: 1, md: 4 }} >
        <Grid item xs={12} sm={6} md={3}>
          <label>Empresa</label>
          <EsfirusTextInput disabled fullWidth value={formData?.company} />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <label>Obra</label>
          <EsfirusTextInput
            fullWidth
            aria-readonly
            value={formData?.obra?.description || formData?.obra?.name || formData?.obra?.Descripcion}
            size="small"
            inputProps={{ readOnly: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  className="pointer"
                  onClick={() => {
                    if (formData?.lineas.length === 0) {
                      setOpenObraModal(true);
                    }
                  }}
                >
                  <EsfirusIcon path={zoomIcon} />
                </InputAdornment>
              ),
            }}
            disabled={!props.editable || formData?.lineas.length !== 0}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <label>Proveedor</label>
          <EsfirusTextInput
            fullWidth
            aria-readonly
            value={formData?.proveedor?.RazonSocial}
            size="small"
            inputProps={{ readOnly: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  className="pointer"
                  onClick={() => {
                    if (formData?.lineas.length === 0) {
                      setOpenProveedorModal(true);
                    }
                  }}
                >
                  <EsfirusIcon path={zoomIcon} />
                </InputAdornment>
              ),
            }}
            disabled={!props.editable || formData?.lineas.length !== 0}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <label>Empleado</label>
          <EsfirusTextInput
            fullWidth
            disabled
            value={formData?.employee || ""}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <label>Fecha</label>
          <DatePickerUI
            value={formData.date}
            disabled={!props.editable}
            handleChange={newDate => {
              const dateSelected = newDate ?? moment()
              setFormData({ ...formData, date: dateSelected })
            }}
          />
          {dateIsInTheFuture && (
            <label style={{ color: 'red', width: '100%', textAlign: 'center' }}>
              No se pueden crear partes a futuro
            </label>
          )}
        </Grid>

        {/* El campo reference lleva el nombre del empleado según especificación en https://esfirus.atlassian.net/browse/PARTRAB-295?focusedCommentId=22588 */}
        {/* <Grid item xs={12} sm={6} md={4}>
          <label>Referencia</label>
          <EsfirusTextInput
            fullWidth
            disabled
            value={formData?.reference || ""}
          />
        </Grid> */}
      </Grid>

      {/* Modales de selección */}
      {
        openObraModal && <ModalNewObra
          open={openObraModal}
          setOpen={setOpenObraModal}
          selectObra={selectObra}
          onlyInternalCompany={true}
        />
      }
      {
        openProveedorModal && <ModalNewProveedor
          open={openProveedorModal}
          setOpen={setOpenProveedorModal}
          selectProveedor={(proveedor: Proveedor) => {
            setFormData({ ...formData, proveedor: proveedor })
          }}
        />
      }
    </>
  );
}

export default HeaderFormPedido