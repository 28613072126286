import Modal from "@mui/material/Modal";
import "./ModalSignAndUpdate.scss";
import checkSvg from "@assets/svgs/check.svg";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";
import EsfirusCanva from "@components/ui/Canva/EsfirusCanva";
import { partesService } from "@services/partes";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { configurationService } from "@services/configApp";
import { isAdmin } from "@services/hooks/UserHook";
import { getDateFromTime } from "@helpers/hours";
import { getExternalCompany, getExternalReference, getInternalCompany } from "@helpers/companyData";

interface ModalNewObraProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onSign: Function
}

function ModalSignAndUpdate({ open, setOpen, onSign }: ModalNewObraProps) {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="new-confirm-modal">
        <div className="header">
          <h5 className="text-center">
            <img src={checkSvg} className="check" alt="check" />
            Firmar
          </h5>
        </div>
        <div className="body">
          <EsfirusCanva id="divCanva"></EsfirusCanva>

          <div className="button-container">
            <EsfirusButtonNative
              click={() => {
                onSign()
                setOpen(false)
              }}
              label={"Firmar"}
            ></EsfirusButtonNative>
            <EsfirusButtonNative
              click={() => {
                setOpen(false)
              }}
              label={"Cancelar"}
            ></EsfirusButtonNative>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalSignAndUpdate;
