import { AxiosError } from "axios";
import { apiConfig } from "./config";
import { AuthEnAreaWebAppApi } from "./swagger/apis/auth-en-area-web-app-api";
import { Configuration } from "./swagger/configuration";

class AuthService extends AuthEnAreaWebAppApi {
  constructor(_apiConfig: Configuration) {
    super(_apiConfig);
  }

  async post(user: string, password: string) {
    try {

      const res = await this.loginPost({ data: { email: user, password } });
      const userdata: any = res.data;
      if (userdata?.ok) {
        localStorage.setItem("ef-token", userdata.token);
        localStorage.setItem("ef-user-email", userdata.data.email);
        localStorage.setItem("ef-user-name", userdata.data.nombre);
        localStorage.setItem("ef-user-full-name", userdata.data.nombre + " " + userdata.data.apellidos);
        localStorage.setItem("ef-user-id", userdata.data.id);
        localStorage.setItem("ef-user-rol", userdata.data.rol ?? 2);
        localStorage.setItem("ef-user-external-data", JSON.stringify(userdata.data.data) ?? "{}");
        return true;
      }
    } catch (error: any) {
      return { msg: error?.response?.data?.message };
    }


  }

  async resetPassword(email: string) {
    await this.authReestablecerPost({ data: { email } });
    return false;
  }

  async changePassword(token: string, password: string, email: string) {
    const res = await this.authEstablecerPasswordTokenPost(token, { data: { password }, params: { id: email } });
    return res;
  }
}

export const authService = new AuthService(apiConfig);
