
import { ConfigProvider } from './context/Config.context'
import { Config } from './Config'

const ConfigWithProvider = (props) => {
    return (
        <ConfigProvider>
            <Config {...props} />
        </ConfigProvider>
    )

}
export default ConfigWithProvider