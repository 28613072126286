import EsfirusSelect from '@ui/Select/EsfirusSelect';
import EsfirusSearch from '@ui/Search/EsfirusSearch';
import { useSelector } from 'react-redux';
import { configurationService } from '@services/configApp';
import { isAdmin, isEmpleado } from '@services/hooks/UserHook';
import { useEffect, useState } from 'react';
import { getPreviousMonths } from '@helpers/utils';

function TrackHorasResumenHeader({ filters, setFilters }: any) {
  const empresas = useSelector((state) => (state as any).empresas).list
  const userIsAdmin = isAdmin()
  const [monthOptions, setMonthOptions] = useState<any>([])

  useEffect(() => {
    collectMonthOptions()
  }, [])

  const collectMonthOptions = () => {
    const { listadoMeses, listadoMesesAdmin } = configurationService.getConfigFichaje()
    const months = (isEmpleado() ? listadoMeses : listadoMesesAdmin) ?? 1
    const previousMonths = getPreviousMonths(months)

    const options = previousMonths.map(month => {
      const value = `${month.monthName} - ${month.yearName}`
      return ({
        label: value,
        value: value
      })
    })

    setMonthOptions(options)
  }

  return (
    <div className='ef-pates-subheader'>
      <div className="ef-left">
        {/* Filtro de empresa */}
        {userIsAdmin && (
          <EsfirusSelect
            placeholder='Seleccionar Empresa'
            options={empresas.map((e: any) => ({ label: e.Nombre, value: e.Empresa }))}
            value={filters.empresa}
            change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, empresa: e }))}
          />
        )}

        {/* Filtro de mes */}
        <EsfirusSelect
          placeholder='Seleccionar mes'
          options={monthOptions}
          value={filters.month}
          change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, month: e }))}
        />

        {/* Filtro de búsqueda por nombre */}
        {userIsAdmin && (
          <EsfirusSearch
            placeholder="Buscar"
            change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, search: e }))}
          />
        )}
      </div>
    </div>
  );
}

export default TrackHorasResumenHeader;