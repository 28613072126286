import { createSlice } from "@reduxjs/toolkit";
import { capitulosService } from "@services/capitulos";

const initial = {
  list: [],
};

export const capitulosSlice = createSlice({
  name: "capitulos",
  initialState: { ...initial },
  reducers: {
    resetCapitulos: (state, action: any) => {
      state.list = [...initial.list];
    },
    setCapitulos: (state, action: any) => {
      state.list = [...action.payload] as any;
    },
  },
});

export default capitulosSlice.reducer;
export const { setCapitulos, resetCapitulos } = capitulosSlice.actions;


