import Sas from './Sas'

const headers = {
    type: 'Page',
    options: {
        name: 'Server',
        route: {
            path: '/backoffice/server',
            ro: '/backoffice/server',
            unAuth: false,
            auth: true,
            exact: true,
            component: (props) => {
                return <Sas />
            },
        },
    },
}

export default headers
