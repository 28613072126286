import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import EsfirusSelect from '@ui/Select/EsfirusSelect';
import DatePickerUI from '../../../../components/ui/DatePickerUI/DatePickerUI';

import { isAdmin, isSupervisor } from '@services/hooks/UserHook';
import { usersService } from '@services/users';

import "./ListadoAvisosFilters.scss"
import { getInternalReference } from '@helpers/companyData';
import { OPCIONES_USER_FOR_ME_LABELS } from '@screens/Avisos/constants';

const ListadoAvisosFilters = ({ filters, setFilters }: any) => {
  const empresas = useSelector((state) => (state as any).empresas).list
  const obras = useSelector((state) => (state as any).obras).list;
  const noticeStatuses = useSelector((state) => (state as any).notices).listStatuses
  const noticeTypes = useSelector((state) => (state as any).notices).listTypes

  const [empleadosList, setEmpleadosList] = useState([])

  const userCanSupervise = isAdmin() || isSupervisor()

  // Opciones del filtro de "para mí".
  const userForMeOptions = Object.entries(OPCIONES_USER_FOR_ME_LABELS).map(([value, label]) => ({ label, value }))

  // Función que recoge todos los empleados de la BBDD.
  const fetchEmpleados = async () => {
    try {
      const resp = await usersService.getAll()
      const empleados = resp?.data?.data ?? []
      const empleadosWithData = empleados.map((empleado: any) => ({ ...empleado, data: JSON.parse(empleado.data) }))
      const empleadosOptions = empleadosWithData.map((empleado: any) => {
        const idEmpresaInterna = empleado.data.internal[0].empresa
        const idEmpleadoInterno = empleado.data.internal[0].empleado
        const empresaObject = empresas.find((empresa: any) => empresa.Empresa === idEmpresaInterna)

        const label = `${empleado.nombre} ${empleado.apellidos} - ${empresaObject.Nombre}`
        return ({
          label,
          value: idEmpleadoInterno,
        })
      })
      setEmpleadosList(empleadosOptions)
    } catch (error) {
      setEmpleadosList([])
    }
  }

  useEffect(() => {
    if (userCanSupervise) {
      fetchEmpleados()
    } else {
      const internalEmployeeId = getInternalReference()
      if (filters.empleadoDestino !== internalEmployeeId || filters.empleadoOrigen !== internalEmployeeId || filters.empleadoResponsable !== internalEmployeeId) {
        setFilters((prevFilters: any) => ({
          ...prevFilters,
          empleadoDestino: internalEmployeeId,
          empleadoOrigen: internalEmployeeId,
          empleadoResponsable: internalEmployeeId
        }))
      }
    }
  }, [empresas])

  return (
    <div className='ef-avisos-filtros'>
      <div className="ef-left">
        {/* Filtro de combo para mí */}
        <EsfirusSelect
          placeholder='Seleccionar para mí'
          options={userForMeOptions}
          disableBlank
          value={filters.forMe}
          change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, forMe: e }))}
        />

        {/* Filtro de empresa */}
        {userCanSupervise && (
          <EsfirusSelect
            placeholder='Seleccionar empresa'
            options={empresas.map((e: any) => ({ label: e.Nombre, value: e.Empresa }))}
            value={filters.empresa}
            change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, empresa: e }))}
          />
        )}

        {/* Filtro de fecha inicio */}
        <DatePickerUI
          className='datePickerFilter'
          value={filters.dateFrom}
          handleChange={newDate => {
            const dateSelected = moment({ day: newDate.$D, month: newDate.$M, year: newDate.$y })
            const isCorrect = !filters.dateTo || dateSelected.isBefore(filters.dateTo)
            if (isCorrect) setFilters((prevFilters: any) => ({ ...prevFilters, dateFrom: dateSelected }))
          }}
        />

        {/* Filtro de fecha fin */}
        <DatePickerUI
          className='datePickerFilter'
          value={filters.dateTo}
          handleChange={newDate => {
            const dateSelected = moment({ day: newDate.$D, month: newDate.$M, year: newDate.$y })
            const isCorrect = !filters.dateFrom || dateSelected.isAfter(filters.dateFrom)
            if (isCorrect) setFilters((prevFilters: any) => ({ ...prevFilters, dateTo: dateSelected }))
          }}
        />

        {/* Filtro de cliente */}
        <EsfirusSelect
          options={[]}
          value={filters.customer}
          change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, customer: e }))}
          placeholder="Todos los clientes"
        />

        {/* Filtro de obra */}
        <EsfirusSelect
          options={obras.map((e: any) => ({ label: e.Descripcion, value: e.Obra }))}
          value={filters.obra}
          change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, obra: e }))}
          placeholder="Todas las obras"
        />

        {/* Filtro de empleado destino */}
        {userCanSupervise && (
          <EsfirusSelect
            options={empleadosList}
            value={filters.empleadoDestino}
            change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, empleadoDestino: e }))}
            placeholder="Todos los empleados destino"
          />
        )}

        {/* Filtro de empleado origen */}
        {userCanSupervise && (
          <EsfirusSelect
            options={empleadosList}
            value={filters.empleadoOrigen}
            change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, empleadoOrigen: e }))}
            placeholder="Todos los empleados origen"
          />
        )}


        {/* Filtro de empleado responsable */}
        {userCanSupervise && (
          <EsfirusSelect
            options={empleadosList}
            value={filters.empleadoResponsable}
            change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, empleadoResponsable: e }))}
            placeholder="Todos los empleados responsable"
          />
        )}

        {/* Filtro de estado */}
        <EsfirusSelect
          options={noticeStatuses.map((e: any) => ({ label: e.description, value: e.status }))}
          value={filters.status}
          change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, status: e }))}
          placeholder="Todos los estados"
        />

        {/* Filtro de tipo */}
        <EsfirusSelect
          options={noticeTypes.map((e: any) => ({ label: e.description, value: e.notification_type }))}
          value={filters.type}
          change={(e: any) => setFilters((prevFilters: any) => ({ ...prevFilters, type: e }))}
          placeholder="Todos los tipos"
        />
      </div>
    </div>
  );
}

export default ListadoAvisosFilters;