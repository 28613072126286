import { useState } from "react"

import SaasTabs from "./SaaSTabs"
import NewClientForm from "./NewClientForm"
import UpdateClientList from "./UpdateClientList"
import BuildsList from "./BuildsList"

import { TAB_OPTIONS } from "../constants"

const renderBody = ({ tabSelected = TAB_OPTIONS.NEW_CLIENT }) => {
  switch (tabSelected) {
    case TAB_OPTIONS.NEW_CLIENT:
      return <NewClientForm />
    case TAB_OPTIONS.BUILDS:
      return <BuildsList />
    case TAB_OPTIONS.UPDATE_CLIENT:
      return <UpdateClientList />
    default:
      return null
  }
}

const SaaSManager = () => {
  const [tabSelected, setTabSelected] = useState(TAB_OPTIONS.NEW_CLIENT)

  return (
    <div className="newClientWrapper">
      <SaasTabs tabSelected={tabSelected} setTabSelected={setTabSelected} />
      {renderBody({ tabSelected })}
    </div>
  )
}

export default SaaSManager