import Modal from "@mui/material/Modal";
import "./ModalMsg.scss";
import checkSvg from "@assets/svgs/close.svg";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";

interface ModalNewObraProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onSave: Function,
  onMsg: Function,
}

function ModalMsg({ open, setOpen, onSave, onMsg }: ModalNewObraProps) {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="new-confirm-modal-msg">
        <div className="header">
          <h5 className="text-center">
            <img src={checkSvg} className="check" alt="check" />
            Rechazar
          </h5>
        </div>
        <div className="body">

          <textarea onChange={(m: any) => onMsg(m.target.value)}></textarea>
          <div className="button-container">
          <EsfirusButtonNative
              click={() => {
                setOpen(false);
              }}
              label={"Cancelar"}
            ></EsfirusButtonNative>
            <EsfirusButtonNative
              click={() => {
                onSave();
                setOpen(false);
              }}
              label={"Enviar"}
            ></EsfirusButtonNative>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalMsg;
