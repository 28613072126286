const textos = {
    "email": "Email",
    "nombre": "Nombre",
    "apellidos": "Apellidos",
    "rol": "Rol",
    "activo": "Activo",
    "external_empresa" : "Empresa Externa",
    "external_empleado" : "Empleado Externo",
    "internal_empresa" : "Empresa Interna",
    "internal_empleado" : "Empleado Interno",
    "empresa" : "Empresa",
    "empleado" : "Empleado",
    "password": "Contraseña",
}

const _type_boolean = [{ label : "Si", value : 1 }, { label : "No", value : 0 }];

const types = [
    { 
        property : "activo",
        type : "select",
        options : _type_boolean
    },
    {   
        property : "rol",
        type : "select",
        options : []
    }
]

export { 
    textos,
    types
}
