import { useState } from 'react'

import Login from './BBs/Login';
import SaaSManager from './BBs/SaaSManager';

import './Sas.css'

const Sas = () => {
  const [loged, setLoged] = useState(false)

  return (
    <div className='sassWrapper'>
      <div className='titleSas'>Manager</div>
      <div className='bodySas'>
        {loged ? (
          <SaaSManager />
        ) : (
          <Login setLoged={setLoged}></Login>
        )}
      </div>
    </div>
  )
}

export default Sas
