import Modal from "@mui/material/Modal";
import "./ModalConfirm.scss";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";



interface ModalConfirmProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onClick: Function;
  textHeader: any;
  className?: string;
}

function ModalConfirm({ open, setOpen,  onClick, textHeader, className }: ModalConfirmProps) {

 


  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={className + " new-confirm-modal"}>

        <div className="header">
          <h5 className="text-center">
            {textHeader}
          </h5>
        </div>

        <div className="body">

          <div className="button-container">

            <EsfirusButtonNative
              click={onClick}
              label={"Confirmar"}
              className={className ?? "secondary"}
            ></EsfirusButtonNative>
            <EsfirusButtonNative
              click={() => {
                setOpen(false);
              }}
              label={"Cancelar"}
            ></EsfirusButtonNative>

          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalConfirm;
