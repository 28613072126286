export const constants = {
    OK: 0,
    roles: {
        admin: 1,
        empleado: 2,
        supervisor: 3,
        jefeObra: 4
    },
    lineaParte: {
        'manoDeObra': 1,
        'servicio': 2,
        'gasto': 3,
        'texto': 4,
        'articulo': 5,
    } as any,
    estadoParte: {
        abierto: 0,
        confirmado: 1,
        validado: 2,
        rechazado: 3
    },
    tipo_hora_varios: { NombreTipoHora: 'Varios', TipoHora: 1000 },
    tokenSas: "1e082c75bbe98c26867beb1fcc4e7840",
    microtechEP: "https://portalweb-api.microtech.es",
}

export const rolesSupervisor = [constants.roles.admin, constants.roles.supervisor, constants.roles.jefeObra]
