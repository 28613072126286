import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import EsfirusTable from "@components/ui/Table/EsfirusTable"
import { EsfirusTablePropsPagination, HandleChangePaginationType } from "@services/hooks/useHandlerPaginationTable";

interface ListadoAvisosTableProps {
  avisos: any[] // TODO pendiente de definir lo que viene del endpoint
  pagination: EsfirusTablePropsPagination
  totalItems: number
  handleChangePagination: HandleChangePaginationType
}

const ListadoAvisosBorradoresTable = ({ avisos, pagination, totalItems, handleChangePagination }: ListadoAvisosTableProps) => {
  const navigate = useNavigate()
  const empresas = useSelector((state) => (state as any).empresas).list
  const empleados = useSelector((state) => (state as any).empleados).list
  const noticeStatuses = useSelector((state) => (state as any).notices).listStatuses
  const noticeTypes = useSelector((state) => (state as any).notices).listTypes

  const columns = [
    {
      name: "Empresa - Delegación",
      keymap: "_id",
      see: true,
      order: true,
      width: 150,
      notShowEdit: true,
      showCrearParte: true,
      handleView: (row: any) => navigate(`/avisos/${row.unique_id}`),
      handleCrearParte: (row: any) => navigate({
        pathname: '/partes/nuevo',
        search: `?from_aviso=${row.unique_id}`
      }),
    }, {
      name: "Fecha",
      keymap: "date",
      parse: (v: any) => moment(v).format("DD-MM-YYYY"),
      order: true,
      width: 100
    }, {
      name: "Hora",
      keymap: "time",
      parse: (v: any) => moment.utc(Number(v) * 1000).format("HH:mm:ss"),
      order: true,
      width: 50
    }, {
      name: "Cliente",
      keymap: "customer", // TODO Avisos falta mapearlo para sacar el nombre. Puede ser business_name ?
      order: true,
      width: 50
    }, {
      name: "Empleado origen",
      keymap: "origin_employee_name",
      order: true,
      width: 100
    }, {
      name: "Empleado destino",
      keymap: "destination_employee_name",
      order: true,
      width: 100
    }, {
      name: "Empleado responsable",
      keymap: "employee_name",
      order: true,
      width: 100
    }, {
      name: "Estado",
      keymap: "status_name", // TODO Aviso o status o notification_status
      order: true,
      width: 100
    }, {
      name: "Tipo",
      keymap: "notification_type_name",
      order: true,
      width: 100
    }, {
      name: "Descripción",
      keymap: "description_r_791",
      order: true,
      width: 150,
      tooltipProperty: "description_tooltip"
    }
  ]

  const parseRows = (rows: any[] = []) => {
    return rows.map(row => {
      const empresaName = empresas.find((empresa: any) => empresa.Empresa == row.company)?.Nombre ?? ""
      const empleadoOrigenName = empleados.find((empleado: any) => empleado.Empresa == row.company && empleado.Empleado == row.origin_employee)?.Nombre ?? ""
      const empleadoDestinoName = empleados.find((empleado: any) => empleado.Empresa == row.company && empleado.Empleado == row.destination_employee)?.Nombre ?? ""
      const empleadoResponsableName = empleados.find((empleado: any) => empleado.Empresa == row.company && empleado.Empleado == row.employee)?.Nombre ?? ""
      const estadoName = noticeStatuses.find((estado: any) => estado.status == row.status)?.description ?? ""
      const tipoName = noticeTypes.find((tipo: any) => tipo.notification_type == row.notification_type)?.description ?? ""

      return ({
        _id: `${empresaName} - ${row.branch ?? ""}`,
        origin_employee_name: empleadoOrigenName,
        destination_employee_name: empleadoDestinoName,
        employee_name: empleadoResponsableName,
        status_name: estadoName,
        notification_type_name: tipoName,
        description_tooltip: `Descripción: ${row.description_r_791 || ""}\nComentarios: ${row.comment || ""}`,
        ...row
      })
    })
  }

  return (
    <EsfirusTable
      columns={columns}
      rows={parseRows(avisos)}
      rowsCount={totalItems}
      pagination={pagination}
      withPagination={true}
      handleChangePagination={handleChangePagination}
    />
  )
}

export default ListadoAvisosBorradoresTable