import { createSlice } from "@reduxjs/toolkit";

const initial = {
  listPriorities: [],
  listStatuses: [],
  listTypes: [],
};

export const noticesSlice = createSlice({
  name: "notices",
  initialState: { ...initial },
  reducers: {
    resetTipoHora: (state, action: any) => {
      state.listPriorities = [...initial.listPriorities];
      state.listStatuses = [...initial.listStatuses];
      state.listTypes = [...initial.listTypes];
    },
    setNoticesPriorities: (state, action: any) => {
      state.listPriorities = [...(action.payload ?? [])] as any;
    },
    setNoticesStatuses: (state, action: any) => {
      state.listStatuses = [...(action.payload ?? [])] as any;
    },
    setNoticesTypes: (state, action: any) => {
      state.listTypes = [...(action.payload ?? [])] as any;
    },
  },
});

export default noticesSlice.reducer;
export const { setNoticesPriorities, setNoticesStatuses, setNoticesTypes, resetTipoHora } = noticesSlice.actions;

