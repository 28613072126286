import { useEffect, useState } from 'react';
import './EsfirusResume.scss'

import { useSelector } from 'react-redux';
import { priceFormat } from '@helpers/utils';
import { isEmpleado } from '@services/hooks/UserHook';


export default function EsfirusResume(props: EsfirusResumeProps) {
    const isUserEmpleado = isEmpleado()
    const [toResume, setToResume] = useState({
        total: 0,
        totalHoras: 0,
        totalImporte: 0,
        totalHorasExtra: 0
    });



    const calcTotalHorasResume = (reportsResume: any) => {
        const calc = reportsResume.reduce((a: any, s: any) => {
            const ret =
                Object.keys(s).reduce((_a: any, _s: any) => {
                    const h = parseFloat(_s);
                    const hora = isNaN(h) ? _a[0] : _a[0] + s[h]?.horas;
                    const extra = isNaN(h) ? _a[1] : _a[1] + s[h]?.horasExtra;
                    return [hora, extra]
                }, [0, 0])
            return [a[0] + ret[0], a[1] + ret[1]]

        }, [0, 0])
        return calc;
    }

    useEffect(() => {
        const partesResume = props.partes.list || props.partes;
        const reportsResume = props.reports?.rows || props.reports;

        if (partesResume?.length > 0) {
            setToResume({
                total: partesResume.length,
                totalHoras: partesResume.reduce((a: any, s: any) => {
                    return a + s.horas;
                }, 0),
                totalImporte: partesResume.reduce((a: any, s: any) => {
                    return a + s.importe;
                }, 0),
                totalHorasExtra: 0
            })
        } else if (reportsResume?.length > 0) {
            const [totalHoras, totalHorasExtra] = calcTotalHorasResume(reportsResume)
            setToResume({
                total: reportsResume.length,
                totalHoras,
                totalHorasExtra,
                totalImporte: 0,

            })
        } else {
            setToResume({
                total: 0,
                totalHoras: 0,
                totalImporte: 0,
                totalHorasExtra: 0
            })
        }

    }, [props.partes, props.reports])

    // Para los usuarios tipo empleado no se les muestra ningún totalizador.
    if (isUserEmpleado) {
        return null
    }

    return <div className='ef-resume'>
        <div>Total {props.resumenMensual ? 'Empleados' : 'Partes'}: {toResume.total}</div>
        <div>Horas: {toResume.totalHoras.toFixed(2)}
        </div>
        {props.resumenHorasExtra && (
            <div>H. Extra: {toResume.totalHorasExtra}</div>
        )}
        {!props.resumenMensual && (
            <div>Importe: {priceFormat(toResume.totalImporte)}</div>
        )}

    </div>
}

export interface EsfirusResumeProps {
    partes?: any;
    reports?: any;
    resumenMensual?: boolean;
    resumenHorasExtra?: boolean;
}