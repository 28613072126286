import { useNavigate } from "react-router-dom";

const Module = ({ title, url, description }: ModuleProps) => {
  const navigate = useNavigate();

  return (
    <div className="modules__item" onClick={() => navigate(url)}>
      <div className="modules__item__title">{title}</div>
      {description && <div className="modules__item__description">{description}</div>}
    </div>
  )
}

export interface ModuleProps {
  title: string;
  url: string;
  description?: string;
}

export default Module