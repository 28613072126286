

const parseError = (res, error = null) => {
    if (error) {
        return res
            .json()
            .then(() => Promise.reject({ status: error.code, error: error.message }));
    }

    switch (res.status) {
        case 200:
            return res.json();
        case 401:
        case 403:
            localStorage.removeItem("token");
            localStorage.setItem("isLoggedIn", false);
            localStorage.setItem("user", {});
            window.location = "/";

        default:
            return res
                .json()
                .then((error) => Promise.reject({ status: res.status, error })); // ParseError
    }
};

export default parseError;
