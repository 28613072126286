import { useEffect, useState } from "react"

const useDebounce = (value, delay = 500) => {
  const [debounceValue, setDebounceValue] = useState(value)

  useEffect(() => {
    if (typeof delay !== 'number' || delay < 100) {
      console.error("Mal uso del debounce. El delay debe ser un número positivo mayor que 100")
      return
    }

    const timer = setTimeout(() => {
      setDebounceValue(value)
    }, delay)

    return () => clearTimeout(timer)
  }, [value, delay])

  return debounceValue
}

export default useDebounce