import { createSlice } from "@reduxjs/toolkit";

const initial = {
  list: [],
};

export const proveedoresSlice = createSlice({
  name: "proveedores",
  initialState: { ...initial },
  reducers: {
    resetProveedores: (state, action: any) => {
      state.list = [...initial.list];
    },
    setProveedores: (state, action: any) => {
      state.list = [...(action.payload ?? [])] as any;
    },
  },
});

export default proveedoresSlice.reducer;
export const { setProveedores, resetProveedores } = proveedoresSlice.actions;

