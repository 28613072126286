import "./Loader.scss"

interface LoaderProps {
  message?: string
  width?: string
  height?: string
}

const Loader = ({ message, width, height }: LoaderProps) => {
  return (
    <div className="loader-wrapper">
      <span style={{ width: width || "6px", height: height || "24px" }} className="loader-custom"></span>
      <span>{message || "Cargando"}</span>
    </div>
  )
}

export default Loader