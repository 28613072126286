import { useState } from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { Settings } from '@mui/icons-material';

import EsfirusDropdownMenu from "@components/ui/DropdownMenu/EsfirusDropdownMenu";
import ModalConfirm from "@screens/Backoffice/pages/Config/ModalConfirm/ModalConfirm";
import { getRequest } from "@screens/Backoffice/utils/requests";
import { isAdmin } from "@services/hooks/UserHook";

const HeaderSettingsMenu = () => {
  const configuration = useSelector((state) => (state as any).configurations.config);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [messageConfirmationModal, setMessageConfirmationModal] = useState("")
  const [loading, setLoading] = useState(false)

  const isAdminUser = isAdmin()
  const isByFTP = configuration?.ftp_method && configuration.ftp_method === "fichero"
  const hasPartesModule = configuration?.modulos?.partes

  const getDropdownOptions = (isAdmin: boolean, isByFTP: boolean, hasPartesModule: boolean) => {
    const options = [
      {
        label: "Refrescar datos del servidor",
        action: async () => {
          setOpenConfirmationModal(true)
          setMessageConfirmationModal("Al terminar de cargar, se refrescará la página automáticamente.")
          setLoading(true)

          await getRequest('config/refresh_data')

          setMessageConfirmationModal("")
          setLoading(false)
          const url = window.location.origin + "/splash"
          window.location.href = url
        }
      },
    ]

    if (isByFTP && hasPartesModule) {
      options.push(
        {
          label: "Forzar envío de ficheros",
          action: async () => {
            await getRequest('forceExport')
            setOpenConfirmationModal(true)
          }
        }
      )
    }

    if (isAdmin) {
      options.push(
        {
          label: "Ir al backoffice",
          action: async () => {
            const url = window.location.origin + "/backoffice"
            window.location.href = url
          }
        }
      )
    }

    return options
  }

  return (
    <>
      <EsfirusDropdownMenu
        className="mt-20 mr-20"
        Button={(props) => (
          <IconButton {...props}>
            <Settings sx={{ color: "white" }} />
          </IconButton>
        )}
        actions={getDropdownOptions(isAdminUser, isByFTP, hasPartesModule)}
      />
      <ModalConfirm open={openConfirmationModal} setOpen={setOpenConfirmationModal} msg={messageConfirmationModal} loading={loading} />
    </>
  );
}

export default HeaderSettingsMenu