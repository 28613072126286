import { createSlice } from "@reduxjs/toolkit";

const initial = {
  list: [],
};

export const empleadosSlice = createSlice({
  name: "empleados",
  initialState: { ...initial },
  reducers: {
    resetEmpleados: (state) => {
      state.list = [...initial.list];
    },
    setempleados: (state, action: any) => {
      state.list = [...action.payload] as any;
    },
  },
});

export default empleadosSlice.reducer;
export const { setempleados, resetEmpleados } = empleadosSlice.actions;
