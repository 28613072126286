import { constants } from "@helpers/constants";
import jwtDecode from "jwt-decode";

export const getTokenDecoded = (): EsfirusToken => {
  const token: string = localStorage.getItem("ef-token") || "";
  return jwtDecode(token);
};

export const canSupervise = (): boolean => {
  return isAdmin() || isSupervisor() || isJefeObra()
};

export const isAdmin = (): boolean => {
  return getTokenDecoded().rol === constants.roles.admin ? true : false;
};

export const isSupervisor = (): boolean => {
  return getTokenDecoded().rol === constants.roles.supervisor ? true : false;
};

export const isJefeObra = (): boolean => {
  return getTokenDecoded().rol === constants.roles.jefeObra ? true : false;
};

export const isEmpleado = (): boolean => {
  return getTokenDecoded().rol === constants.roles.empleado ? true : false;
}

export const isResponsable = (): boolean => {
  const user = getTokenDecoded()
  return user?.data?.responsable ?? false
}

export const getUserId = (): Number => {
  return Number(localStorage.getItem("ef-user-id"));
}

export const getExternalData = (): string => {
  return localStorage.getItem("ef-user-external-data") ?? "";
}

export const getUserRol = (): string => {
  const token = getTokenDecoded();
  const rol: any = {
    [constants.roles.admin]: "Administrador",
    [constants.roles.empleado]: "Empleado",
    [constants.roles.supervisor]: "Supervisor",
    [constants.roles.jefeObra]: "Jefe de Obra",
  };
  return rol[token.rol];
};

export const getUserName = (): string => {
  const token = getTokenDecoded();
  return token.nombre;
};

export const getFullName = (): string => {
  const token = getTokenDecoded();
  const nombre = token.nombre ?? "";
  const apellidos = token.apellidos ?? "";

  return `${nombre} ${apellidos}`
}

export const getCosteEmpleado = () => {
  const user = getTokenDecoded()
  return user?.data?.coste
}

export const getCategoriasByCompany = (): any[] => {
  const token = getTokenDecoded();
  const internal = token?.data?.internal?.map((d: any) => ({ empresa: d.empresa, categoria: d.categoria })) ?? []
  const external = token?.data?.external?.map((d: any) => ({ empresa: d.empresa, categoria: d.categoria })) ?? []
  return [...internal, ...external];
}

interface EsfirusToken {
  data: any;
  id: number;
  nombre: string;
  apellidos: string;
  email: string;
  rol: number;
  permisos: string;
  issued: string;
  iat: number;
  exp: number;
}
