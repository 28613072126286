import { createSlice } from "@reduxjs/toolkit";
import { presupuestosService } from "@services/presupuestos";

const initial = {
  list: [],
};

export const presupuestosSlice = createSlice({
  name: "presupuestos",
  initialState: { ...initial },
  reducers: {
    resetPresupuestos: (state, action: any) => {
      state.list = [...initial.list];
    },
    setpresupuestos: (state, action: any) => {
      state.list = [...action.payload] as any;
    },
  },
});

export default presupuestosSlice.reducer;
export const { setpresupuestos, resetPresupuestos } = presupuestosSlice.actions;

