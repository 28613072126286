import "./EsfirusInputTextNative.scss";
import TextareaAutosize from '@mui/base/TextareaAutosize';


function EsfirusInputTextNative(props: EsfirusInputPropNative) {
  return (
    <div className="ef-input-native">
      <input
        type={props?.type ?? 'text'}
        required={props?.required}
        disabled={props?.disabled}
        minLength={props?.min}
        onChange={(e: any) => props.change(e.target.value)}
        value={props.value}
      ></input>
    </div>
  );
}

function EsfirusInputTextAreaNative(props: EsfirusInputPropNative) {
  return (
    <div className="ef-input-native">
      <div className="scroll">
        <TextareaAutosize
          required={props?.required}
          disabled={props?.disabled}
          minLength={props?.min}
          onChange={(e: any) => props.change(e.target.value)}
          value={props.value}
        ></TextareaAutosize>
      </div>
    </div>
  );
}

function EsfirusInputNumberNative(props: EsfirusInputPropNative) {
  return (
    <div className={`ef-input-native ${props?.modified ? "ef-input-native-modified" : ""}`} style={{ backgroundColor: props.modified ? "red" : "transparent" }}>
      <input
        type="number"
        required={props?.required}
        disabled={props?.disabled}
        minLength={props?.min}
        onFocus={(e: any) => {
          e.preventDefault();
          props?.focus();
        }}
        onChange={(e: any) => props.change(e.target.value)}
        value={props.value}
      ></input>
    </div>
  );
}

interface EsfirusInputPropNative {
  required?: boolean;
  min?: number;
  value?: any;
  disabled?: boolean;
  change?: any;
  focus?: any;
  type?: any;
  modified?: any;
}

export { EsfirusInputTextNative, EsfirusInputNumberNative, EsfirusInputTextAreaNative };
