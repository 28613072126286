import Modal from "@mui/material/Modal";
import "./ModalConfirm.scss";
import checkSvg from "@assets/svgs/check.svg";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";
import EsfirusCanva from "@components/ui/Canva/EsfirusCanva";
import { partesService } from "@services/partes";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { configurationService } from "@services/configApp";
import { isAdmin } from "@services/hooks/UserHook";
import { getDateFromTime } from "@helpers/hours";
import { getExternalCompany, getExternalReference, getInternalCompany } from "@helpers/companyData";

interface ModalNewObraProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  data: any;
}

function ModalConfirm({ open, setOpen, data = {} }: ModalNewObraProps) {
  const navigate = useNavigate();
  const lineas = useSelector((state) => (state as any).lines).list;
  const selectedobra = useSelector((state) => (state as any).selectedobra).obra;

  const { partesValidar, partesConfirmar } =
    configurationService.getConfigCabeceraParte();

  const saveReport = (toSave: boolean) => {
    partesService
      .saveReportAndSign(selectedobra, lineas, data.validado, toSave)
      .then((res) => {
        setOpen(false);
        navigate("/partes");
      })
      .catch((err) => console.error(err));
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="new-confirm-modal-sign">
        <div className="header">
          <h5 className="text-center">
            <img src={checkSvg} className="check" alt="check" />
            Firmar
          </h5>
        </div>
        <div className="body">
          <EsfirusCanva id="divCanva"></EsfirusCanva>

          <div className="button-container">
            <EsfirusButtonNative
              click={() => {
                saveReport(true);
              }}
              label={"Guardar"}
            ></EsfirusButtonNative>
            <EsfirusButtonNative
              click={() => {
                saveReport(false);
              }}
              label={"Cancelar"}
              className="secondary"
            ></EsfirusButtonNative>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalConfirm;
