/* eslint-disable react-hooks/exhaustive-deps */
import "./NewParte.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import moment from "moment";

import { Box, Paper } from "@mui/material";

import Header from "@components/Header/Header";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";
import Loader from "@components/ui/Loader/Loader";

import ModalConfirm from "./BBs/ModalConfirm/ModalConfirm";
import backIcon from "@assets/images/icons/back-arrow.svg";
import { removeSelectedObra, updateSelectedObra } from "@store/slices/selectedObra";
import FormParte from "@components/FormParte/FormParte";
import EsfirusButton from "@components/ui/Button/EsfirusButton";
import { resetValidators } from "@store/slices/validation";
import { partesService } from "@services/partes";
import { reportLinesService } from "@services/reportLines";
import { constants } from "@helpers/constants";

import { getUserId } from "@services/hooks/UserHook";
import { configurationService } from "@services/configApp";
import { emptyResourceRedirect } from "@helpers/navigate";
import usePartesGet from "@services/hooks/usePartesGet";
import { avisosService } from "@services/avisos";
import { AvisosType } from "@screens/Avisos/constants";

export default function NewParte() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getPartes = usePartesGet()
  const { pathname, search } = useLocation()
  const [searchParams] = useSearchParams()

  const fromAvisoId = searchParams.get("from_aviso")
  const filterByType = useSelector((state) => (state as any).partes.filterByType);
  const validator = useSelector((state) => (state as any).validationParte);
  const lineas = useSelector((state) => (state as any).lines).list;
  const selectedobra = useSelector((state) => (state as any).selectedobra).obra;
  const presupuesto = useSelector((state) => (state as any).presupuestos).list;
  const capitulos = useSelector((state) => (state as any).capitulos).list;
  const empresas = useSelector((state) => (state as any).empresas).list;
  const configuration = useSelector((state) => (state as any).configurations.config);
  const { partesConfirmar, partesValidar } = configurationService.getConfigCabeceraParte();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({});

  const closedDays = useSelector((state) => (state as any).partes).closedDays;

  const [dateInvalid, setDateInvalid] = useState(true);

  const [solapamiento, setSolapamiento] = useState(0);
  const [alreadySaved, setAlreadySaved] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true)

  // Para cargar la data desde aviso.
  const [avisoData, setAvisoData] = useState<AvisosType | null>(null)
  const [loadingAvisoData, setLoadingAvisoData] = useState(false)

  const usuario = getUserId();

  useEffect(() => {
    const condition = empresas.length === 0
    emptyResourceRedirect({ check: condition, path: `${pathname}${search}`, navigate });


    if (!!fromAvisoId && !condition) {
      fetchAvisoData(fromAvisoId)
    }
  }, [fromAvisoId]);

  useEffect(() => {
    if (!!avisoData) {
      dispatch(updateSelectedObra({
        ...selectedobra,
        reference: `Desde aviso: ${avisoData.notification}`
      }))
    }
  }, [avisoData])

  const fetchAvisoData = async (fromAvisoId: string | number) => {
    setLoadingAvisoData(true)

    try {
      const resp = await avisosService.getByUniqueId(fromAvisoId)
      const data = resp?.data?.data ?? null
      setAvisoData(data)
    } catch (error) {
      setAvisoData(null)
    } finally {
      setLoadingAvisoData(false)
    }
  }

  const saveReport = async (validado?: number) => {
    try {
      await partesService.saveReport(selectedobra, lineas, validado)
      dispatch(getPartes(0, 50, filterByType) as any);
      navigate("/partes");
    } catch (error) {
      throw error
    }
  };

  const obrasCheck = selectedobra?.obraCode && (selectedobra.Administracion || (!selectedobra.Administracion && (!selectedobra.SolPresupuesto || selectedobra.selectedPresupuesto?.length))) && selectedobra?.date && (typeof selectedobra.date === "number" ? moment(selectedobra.date).isValid() : selectedobra.date.isValid())

  const _data = {
    usuario,
    date: selectedobra?.date
  }

  useEffect(() => {
    const disabled = !obrasCheck || !lineas.length || reportLinesService.getValidatorBtn(selectedobra, lineas, dateInvalid) || solapamiento === 1 || alreadySaved
    setButtonDisabled(disabled)
  }, [obrasCheck, lineas, selectedobra, dateInvalid, solapamiento, alreadySaved])

  const handlerSaveParte = async (estado?: any) => {
    try {
      if (typeof estado === 'undefined') {
        //si no forzamos el estado, debemos mirar las configs para saber si el parte se debe confirmar al guardar o no
        // validado = configuration?.hideConfirmar ? constants.estadoParte.confirmado : constants.estadoParte.abierto;
        //fix 206: ahora no forzamos el estado a confirmado, simplemente estara siempre abierto y no saldra el boton de confirmar, pasara de validado a confirmado
        estado = constants.estadoParte.abierto;
      }

      setAlreadySaved(true)

      if (configuration?.cabeceraParte?.partesFirmar && selectedobra?.Administracion) {
        setOpenConfirmModal(true)
        setConfirmModalData({ validado: estado })
      }
      else await saveReport(estado)
    } catch (error) {
      alert("Ha ocurrido un problema y no se ha podido guardar el parte.")
      console.error(error)
    }
  }

  if (!!fromAvisoId && loadingAvisoData) {
    return (
      <>
        <Header />
        <div className="new-parte-container">

          <div className="new-parte-title text-center">
            <h4>Parte de trabajo</h4>
            <h5>Rellena el formulario para completar el parte</h5>
          </div>
          <Loader message="Cargando datos del aviso" />
        </div>
      </>
    )
  }

  return (
    <>
      <Header />
      <div className="new-parte-container">

        <div className="new-parte-title text-center">
          <h4>Parte de trabajo</h4>
          <h5>Rellena el formulario para completar el parte</h5>
        </div>

        <Paper
          className="form-container-paper"
          elevation={3}
          sx={{
            maxWidth: 1048,
            margin: "auto",
            borderRadius: "25px",
            paddingBottom: "30px",
          }}
        >
          <div className="paper-header">
            <h5>Nuevo parte</h5>
          </div>

          <Box className="ml-20 mr-20 mt-40">
            <FormParte
              selectedobra={selectedobra}
              presupuesto={presupuesto}
              capitulos={capitulos}
              lineas={lineas}
              editable={true}
              dateInvalid={dateInvalid}
              setDateInvalid={setDateInvalid}
              closedDays={closedDays}
              data={_data}
              headerOk={obrasCheck}
              setSolapamiento={setSolapamiento}
              isCreation={true}
            ></FormParte>
          </Box>

          <Box className="ml-20 mr-20 mt-40 botonera">
            <EsfirusButton
              className="new-parte-save-button"
              color="primary"
              onClick={async () => {
                const estado = (!partesConfirmar && !partesValidar) ? constants.estadoParte.validado : constants.estadoParte.abierto
                await handlerSaveParte(estado)
              }}
              disabled={buttonDisabled}
            >
              {alreadySaved && <span style={{ height: 12, margin: 0 }} className="loader"></span>}
              Guardar
            </EsfirusButton>

            {!configuration?.hideConfirmar &&
              <EsfirusButton
                className="new-parte-save-button"
                color="primary"
                onClick={async () => {
                  await handlerSaveParte(constants.estadoParte.confirmado)
                }}
                disabled={buttonDisabled}
              >
                Confirmar
              </EsfirusButton>}
            <EsfirusButtonNative
              className={"tertiary"}
              label={"Volver"}
              icon={backIcon}
              iconClass={"icon-tertiary"}
              click={() => {
                dispatch(resetValidators());
                dispatch(removeSelectedObra());
                navigate("/partes");
              }}
            ></EsfirusButtonNative>
          </Box>

        </Paper>
      </div>
      <ModalConfirm open={openConfirmModal} setOpen={setOpenConfirmModal} data={confirmModalData} />
    </>
  );
}
