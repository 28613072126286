import { createTheme } from "@mui/material";

export default createTheme({
    palette: {
     primary: {
        main: '#075577',
        contrastText: '#ffffff'
     },
     secondary: {
        main: '#82d08e',
        contrastText: '#ffffff'
     },
     info: {
      main: '#59dcf4',
      contrastText: '#075577'
     },
     warning: {
      main: '#f8b96e',
      contrastText: '#ffffff'
     },
    }
});
  