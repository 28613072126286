import Modal from "@mui/material/Modal";
import "./ModalConfirm.scss";
import checkSvg from "@assets/svgs/check.svg";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";

interface ModalConfirmProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  msg?: string;
  loading?: boolean;
}

function ModalConfirm({ open, setOpen, msg, loading }: ModalConfirmProps) {

  return (
    <Modal
      open={open}
      onClose={!loading ? () => setOpen(false) : () => { }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="new-confirm-modal-sign">
        <div className="header">
          <h5 className="text-center">
            {!loading && <img src={checkSvg} className="check" alt="check" />}
            {loading ? "Actualizando datos" : "Datos actualizados"}
          </h5>
        </div>
        <div className="body">
          {loading && <span className="loader" style={{ color: "#075577" }}></span>}
          <div className="text-center ph-30">{msg}</div>
          {!loading && (
            <div className="button-container">
              <EsfirusButtonNative
                click={() => {
                  setOpen(false);
                }}
                label={"Aceptar"}
              ></EsfirusButtonNative>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ModalConfirm;
