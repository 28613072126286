import "./EsfirusSelect.scss";

const getOptions = (options?: Array<EsfirusSelectPropsOption>, style?: any, value?: any) => {
  return options?.map((o: EsfirusSelectPropsOption, index) => {
    let styled = style || {};
    if (o.disabled) {
      styled = { ...styled, fontWeight: "normal" };
    }

    return (
      <option style={styled} key={`ef-select-${index}`} selected={value == o.value} value={o.value} disabled={o.disabled !== undefined ? o.disabled : false}>
        {value !== o.value && o.labelPrefix}{o.label}
      </option>
    )
  }
  );
};

export default function EsfirusSelect(props: EsfirusSelectProps) {
  return (
    <div className={`ef-select ${props.modified ? "ef-select-modified" : ""}`} style={props.style || {}}>
      <select
        // className={props.fill ? "filled" : ""}
        className={props?.multiple ? props.fill ? "filled multiple" : "multiple" : props.fill ? "filled" : ""}
        onChange={(e: any) => props.change(e.target.value)}
        disabled={props?.disabled}
        required={props?.required}
        value={props?.value}
        multiple={props?.multiple ?? false}
      >
        {!props.disableBlank && <option style={props.optionsStyle || {}} value="">{props.placeholder}</option>}
        {getOptions(props.options, props.optionsStyle, props?.value)}
      </select>
    </div>
  );
}

export interface EsfirusSelectPropsOption {
  label: string;
  labelPrefix?: string;
  value: any;
  disabled?: any;
}
export interface EsfirusSelectProps {
  placeholder?: string;
  options?: Array<EsfirusSelectPropsOption>;
  disableBlank?: boolean;
  fill?: boolean;
  value?: any;
  change?: any;
  disabled?: boolean;
  required?: boolean;
  multiple?: boolean;
  style?: any;
  optionsStyle?: any;
  modified?: any;
}
