import HeaderNavigateButton from "@components/ui/HeaderNavigateButton"
import documentIcon from "@assets/images/icons/documentIcon.png"
import listIcon from "@assets/images/icons/listIcon.png"

import "./PedidosHeader.scss"

const PedidosHeader = () => {
  return (
    <div className="ef-pedidos-header">
      <HeaderNavigateButton
        icon={documentIcon}
        label="Nuevo pedido"
        path="/pedidos/nuevo"
        color="secondary"
      />
      <HeaderNavigateButton
        icon={listIcon}
        label="Listado de pedidos"
        path="/pedidos"
        color="primary"
        variant="outlined"
      />
    </div>
  )
}

export default PedidosHeader