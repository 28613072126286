import moment from "moment";
import "./subheader.scss";
import EsfirusSelect from "@ui/Select/EsfirusSelect";
import EsfirusSearch from "@ui/Search/EsfirusSearch";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAvailableCompanies, getInternalCompany } from "@helpers/companyData";
import { partesService } from "@services/partes";
import { setSelectedDateFrom, setSelectedDateTo, setSelectedEmpresa, setSelectedObra, setSelectedEmpleado, setSelectedResponsable, getPartes } from "@store/slices/partes";
import ModalMsg from "../modalMsg/ModalMsg";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import 'dayjs/locale/es';

import EsfirusTextInput from "@components/ui/Text-input/EsfirusTextInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { getDateUTC } from "@services/hooks/TimeHook";
import { isEmpleado } from "@services/hooks/UserHook";

function SubHeader(props: any) {
  const navigate = useNavigate();
  const empresas = useSelector((state) => (state as any).empresas).list;
  const obras = useSelector((state) => (state as any).obras).list;
  const dispatch = useDispatch();


  const [empresasDisponibles, setEmpresasDisponibles] = useState<any>([]);
  const [empresasList, setEmpresasList] = useState<any>([]);
  const [obrasList, setObrasList] = useState<any>([]);
  const { search, setFilterBySearch } = props;
  const [msg, setMsg] = useState<any>("");
  const [open, setOpen] = useState<any>(false);

  const isEmpleadoRol = isEmpleado()

  useEffect(() => {
    setEmpresasDisponibles(getAvailableCompanies());
    // selectEmpresa(getInternalCompany());
    setObrasList(obras)

  }, [])

  useEffect(() => {
    setEmpresasList(empresas.filter((e: any) => empresasDisponibles == "*" || empresasDisponibles.includes(e.Empresa)))
  }, [empresasDisponibles])

  const selectEmpresa = (empresa: any) => {
    dispatch(setSelectedEmpresa(empresa))
    //deseleccionamos la obra si estubiera filrada
    dispatch(setSelectedObra(null as never))


    dispatch(setSelectedEmpleado(null as never))
    dispatch(setSelectedResponsable(null as never))


    setObrasList(obras.filter((e: any) => e.Empresa == empresa))
  }

  return (
    <div className="ef-pates-subheader noPrint">
      <div className="ef-left">

        {!isEmpleadoRol && (
          <EsfirusSelect
            key={"ef-select-company"}
            options={empresasList.map((e: any) => ({ label: e.Nombre, value: e.Empresa }))}
            value={props.selectedEmpresa}
            change={(e: any) => {
              if (e == "") {
                selectEmpresa(null as never)
              } else {
                selectEmpresa(
                  empresas.find((_e: any) => _e.Empresa.toString() === e.toString()).Empresa
                )

              }
            }}
            placeholder="Todas"
          />
        )}



        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DatePicker
            value={props.selectedDateFrom ?? null}
            onChange={(newDate) => {
              const dateSelected = moment({ day: newDate.$D, month: newDate.$M, year: newDate.$y })
              const isCorrect = !props.selectedDateTo || dateSelected.isSameOrBefore(props.selectedDateTo)
              if (isCorrect) dispatch(setSelectedDateFrom(dateSelected.valueOf() as never))
            }}
            renderInput={(params) => (
              <EsfirusTextInput fullWidth {...params} />
            )}

            inputFormat="DD/MM/YYYY"
            className="datePickerFilter"
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DatePicker
            value={props.selectedDateTo ?? null}
            onChange={(newDate) => {
              const dateSelected = moment({ day: newDate.$D, month: newDate.$M, year: newDate.$y })
              const isCorrect = !props.selectedDateFrom || dateSelected.isSameOrAfter(props.selectedDateFrom)
              if (isCorrect) dispatch(setSelectedDateTo(dateSelected.valueOf() as never))
            }}
            renderInput={(params) => (
              <EsfirusTextInput fullWidth {...params} />
            )}

            inputFormat="DD/MM/YYYY"
            className="datePickerFilter"
          />
        </LocalizationProvider>

        {/*         
        <EsfirusSearch
          value={search}
          change={
            (e: any) => setFilterBySearch(e as never)
          }
          placeholder="Buscar" />
 */}

        {!isEmpleadoRol && (
          <EsfirusSelect
            key={"ef-select-employee"}
            options={props?.empleadosList}
            value={props?.selectedEmpleado}
            change={(e: any) => {
              if (e == "") {
                dispatch(setSelectedEmpleado(null as never))
              } else {
                dispatch(setSelectedEmpleado(e))
              }
            }}
            placeholder="Todos los Empleados"
          />
        )}
      </div>

    </div>
  );
}

export default SubHeader;
