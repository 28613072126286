import { useState } from "react"
import { constants } from "@helpers/constants"

const Login = ({ setLoged }) => {
  const token = constants.tokenSas
  const [userToken, setUserToken] = useState()

  const login = () => {
    //TODO: pasar a api el login
    setLoged(userToken === token)
  }

  return (
    <div className="loginWrapper">
      <input
        className="loginInput"
        value={userToken}
        type="password"
        placeholder="Token de acceso"
        onChange={e => setUserToken(e.target.value)}
      />
      <button className="loginButton" onClick={login}>Acceder</button>
    </div>
  )
}


export default Login