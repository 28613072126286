import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import EsfirusTable from "@components/ui/Table/EsfirusTable"
import { EsfirusTablePropsPagination, HandleChangePaginationType } from "@services/hooks/useHandlerPaginationTable";

interface ListadoAvisosTableProps {
  borradoresAvisos: any[] // TODO pendiente de definir lo que viene del endpoint
  pagination: EsfirusTablePropsPagination
  totalItems: number
  handleChangePagination: HandleChangePaginationType
}

const ListadoAvisosBorradoresTable = ({ borradoresAvisos, pagination, totalItems, handleChangePagination }: ListadoAvisosTableProps) => {
  const navigate = useNavigate()
  const empresas = useSelector((state) => (state as any).empresas).list
  const empleados = useSelector((state) => (state as any).empleados).list
  const noticeStatuses = useSelector((state) => (state as any).notices).listStatuses
  const noticeTypes = useSelector((state) => (state as any).notices).listTypes

  const columns = [
    {
      name: "Empresa - Delegación",
      keymap: "_id",
      see: true,
      order: true,
      width: 150,
      notShowEdit: true,
      handleView: (row: any) => navigate(`/borradores-avisos/${row.unique_id}`),
    }, {
      name: "Fecha",
      keymap: "draft_date", // TODO Aviso o notice_date
      parse: (v: any) => moment(v).format("DD-MM-YYYY"),
      order: true,
      width: 100
    }, {
      name: "Hora",
      keymap: "draft_time", // TODO Aviso o notice_time
      parse: (v: any) => moment.utc(Number(v) * 1000).format("HH:mm:ss"),
      order: true,
      width: 50
    }, {
      name: "Cliente",
      keymap: "customer_or_potencial_id", // TODO Avisos falta mapearlo para sacar el nombre. Puede ser business_name ?
      order: true,
      width: 50
    }, {
      name: "Empleado origen",
      keymap: "origin_worker_name",
      order: true,
      width: 100
    }, {
      name: "Empleado destino",
      keymap: "target_worker_name",
      order: true,
      width: 100
    }, {
      name: "Empleado responsable",
      keymap: "worker_id_name",
      order: true,
      width: 100
    }, {
      name: "Estado",
      keymap: "notice_status_name", // TODO Avisos o draft_status o notice_status
      order: true,
      width: 100
    }, {
      name: "Tipo",
      keymap: "notice_type_name",
      order: true,
      width: 100
    }, {
      name: "Descripción",
      keymap: "notice_description", // TODO Avisos definir qué muestra de serie y qué en el tooltip entre notice_description, draft_comments y comments.
      order: true,
      width: 150,
      tooltipProperty: "description_tooltip"
    }
  ]

  const parseRows = (rows: any[] = []) => {
    return rows.map(row => {
      const empresaName = empresas.find((empresa: any) => empresa.Empresa == row.company_id)?.Nombre ?? ""
      const empleadoOrigenName = empleados.find((empleado: any) => empleado.Empresa == row.company_id && empleado.Empleado == row.origin_worker)?.Nombre ?? ""
      const empleadoDestinoName = empleados.find((empleado: any) => empleado.Empresa == row.company_id && empleado.Empleado == row.target_worker)?.Nombre ?? ""
      const empleadoResponsableName = empleados.find((empleado: any) => empleado.Empresa == row.company_id && empleado.Empleado == row.worker_id)?.Nombre ?? ""
      const estadoName = noticeStatuses.find((estado: any) => estado.status == row.notice_status)?.description ?? ""
      const tipoName = noticeTypes.find((tipo: any) => tipo.notification_type == row.notice_type)?.description ?? ""

      return ({
        _id: `${empresaName} - ${row.branch_id ?? ""}`,
        origin_worker_name: empleadoOrigenName,
        target_worker_name: empleadoDestinoName,
        worker_id_name: empleadoResponsableName,
        notice_status_name: estadoName,
        notice_type_name: tipoName,
        description_tooltip: `Descripción: ${row.notice_description || ""}\nComentario borrador: ${row.draft_comments || ""}\nComentario general: ${row.comments || ""}`,
        ...row
      })
    })
  }

  return (
    <EsfirusTable
      columns={columns}
      rows={parseRows(borradoresAvisos)}
      rowsCount={totalItems}
      pagination={pagination}
      withPagination={true}
      handleChangePagination={handleChangePagination}
    />
  )
}

export default ListadoAvisosBorradoresTable