import { getAvailableCompanies, getInternalCompany } from "@helpers/companyData";
import { createSlice } from "@reduxjs/toolkit";
import { obrasService } from "@services/obras";

const initial = {
  list: [],
  fullList: [],
};

const getYearFecha = (date: string) => date.substring(0, 4)

const montarObrasExternas = (obras: any[], baseCompany: any) => {

  //   //objeto con las obras externas {
  //   //empresaBase+delegacion: {
  //   //empresaDestino1: obraBase1
  //   //empresaDestino2: obraBase2
  //   }
  // //}
  let obrasExternas = {}
  const year = new Date().getFullYear().toString()
  obras?.
    filter((_o: any) => _o.Empresa == baseCompany && _o.EmpresaDestino != "" && getYearFecha(_o.Fecha) == year)?.
    forEach((o: any) => {

      let elemento: any = obrasExternas[o.prefixId as keyof typeof obrasExternas] ?? {}
      obrasExternas = {
        ...obrasExternas,
        [o.prefixId]: {
          ...elemento,
          [o.EmpresaDestino]: o.Obra

        }
      }
    })
  return obrasExternas
}

export const ObraSlice = createSlice({
  name: "obras",
  initialState: { ...initial },
  reducers: {
    resetObras: (state, action: any) => {
      state.list = [...initial.list];
    },
    setObras: (state, action: any) => {
      const parsed = [...action.payload];

      const companys = getAvailableCompanies()
      const baseCompany = getInternalCompany()


      let obrasParsed =
        parsed?.
          map((o: any) => {
            //las obras tienen siempre el prefijo de empresa y delegacion (el id obra no es unico)
            const prefixId = o.Empresa + "_" + o.Delegacion

            return {
              ...o,
              prefixId,
              id: prefixId + "_" + o.Obra,
            }
          })

      state.fullList = obrasParsed as any;


      let obrasFiltered = obrasParsed?.filter((o: any) => companys == "*" || companys.includes(o.Empresa))
      //revisar duplicados de obras, son aquellas especiales para facturar los trabajos en otras empresas (solo debe haber 1 obra por empresa destino)
      // son de mi empresa base
      // tienen empresa destino igual
      // tienen el mismo año 
      //se debe indicar el error de duplicidad en la empresa destino
      let obrasDuplicated = obrasFiltered?.
        filter((o: any) => {
          if (o.Empresa == baseCompany && o.EmpresaDestino != "") {
            //buscamos las otras obras, son las que tienen el mismo prefijo y empresa destino pero distinto id y mismo año
            const otherObras = obrasFiltered.filter((_o: any) => _o.prefixId == o.prefixId && _o.EmpresaDestino == o.EmpresaDestino && _o.id != o.id && getYearFecha(o.Fecha) == getYearFecha(_o.Fecha))
            return otherObras.length
          }
          return false
        })?.map((o: any) => o.EmpresaDestino)

      const obrasExternas = montarObrasExternas(obrasFiltered, baseCompany)

      obrasFiltered = obrasFiltered?.
        map((o: any) => {
          const duplicated = obrasDuplicated?.includes(o.Empresa.toString())
          const prefixBase = baseCompany + "_" + o.Delegacion
          const ObrasExterna = obrasExternas?.[prefixBase as keyof typeof obrasExternas];
          const ObraExterna = ObrasExterna?.[o.Empresa as keyof typeof ObrasExterna] ?? null
          return {
            ...o,
            duplicated,
            ObraExterna
          }
        })

      state.list = obrasFiltered as any;
    },
  },
});

export default ObraSlice.reducer;
export const { setObras, resetObras } = ObraSlice.actions;

