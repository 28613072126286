import { apiConfig } from "./config";
import { Configuration } from "./swagger/configuration";
import { FichajesApi } from "./swagger/apis/fichajes-api";
import axios from "axios";
import { Moment } from "moment";

class FichajesService extends FichajesApi {
  accessToken = "";

  constructor(_apiConfig: Configuration) {
    super(_apiConfig);
  }

  get(months: number) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    const timeDiff = new Date().getTimezoneOffset();
    try {
      const resp: any = this.fichajesGet({ headers: { Authorization: accessToken }, params: { months, timeDiff } });
      return resp
    } catch (e) {

      return e
    }
  }

  getLastFichaje() {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    try {
      const resp: any = axios({
        baseURL: this.basePath + '/fichajes/last',
        headers: {
          Authorization: accessToken
        },
        method: 'get'
      })
      return resp
    } catch (e) {

      return e
    }
  }

  getLastMonths(months: number = 3) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    const timeDiff = new Date().getTimezoneOffset();

    return axios.request({
      method: "GET",
      url: this.basePath + "/fichajes/grid",
      headers: { Authorization: accessToken },
      params: { months , timeDiff }
    });
  }

  post(data: any) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`;
    return this.fichajePost({
      headers: { Authorization: accessToken },
      data,
    });
  }

  parseMomentDate(date: Moment) {
    const iso = date.toISOString(true)
    return iso.slice(0, 19).replace('T', ' ')
  }
}

export const fichajesService = new FichajesService(apiConfig);
