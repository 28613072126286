export const OPCIONES_USER_FOR_ME_FILTER = {
  DESTINADOS: "DESTINADOS",
  ORIGEN: "ORIGEN",
  RESPONSABLE: "RESPONSABLE"
} as const

export const OPCIONES_USER_FOR_ME_LABELS = {
  [OPCIONES_USER_FOR_ME_FILTER.DESTINADOS]: "Destinados a mí",
  [OPCIONES_USER_FOR_ME_FILTER.ORIGEN]: "Originados por mí",
  [OPCIONES_USER_FOR_ME_FILTER.RESPONSABLE]: "Soy responsable",
}

export interface AvisosType {
  notification?: number | string
  unique_id?: number | string
}