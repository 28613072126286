import { constants } from "./constants";

export const filterByCompany = (_partes: any, selectedEmpresa: any) => {
    if ((!selectedEmpresa || selectedEmpresa === 0) || selectedEmpresa === "") {
        return _partes;
    }

    return _partes.filter((p: any) => p.empresa == selectedEmpresa)
};

export const filterByObra = (_partes: any, selectedObra: any) => {
    if ((!selectedObra || selectedObra === 0) || selectedObra === "") {
        return _partes;
    }
    return _partes.filter((p: any) => p.idObra == selectedObra)
};

export const filterByDateFrom = (_partes: any, selectedDateFrom: any) => {
    if (!selectedDateFrom || selectedDateFrom === "") {
        return _partes;
    }

    var selectedDateFromOffset = new Date(selectedDateFrom);

    // var horasdif = Number(new Date(selectedDateFrom).getTimezoneOffset()) / 60;
    // selectedDateFromOffset.setHours(selectedDateFromOffset.getHours() + horasdif);

    return _partes.filter((p: any) => new Date(p.date || p.dateIn) >= selectedDateFromOffset)

};

export const filterByDateTo = (_partes: any, selectedDateTo: any) => {
    if (!selectedDateTo || selectedDateTo === "") {
        return _partes;
    }
    var selectedDateToOffset = new Date(selectedDateTo);
    // var horasdif = Number(new Date(selectedDateTo).getTimezoneOffset()) / 60;
    selectedDateToOffset.setHours(selectedDateToOffset.getHours() + 24); /// +24 es para incluir el dia en el hasta
    return _partes.filter((p: any) => new Date(p.date || p.dateIn) < selectedDateToOffset)
};

export const filterBySearch = (_partes: any, search: any) => {
    if (!search || search === "") {
        return _partes;
    }
    return _partes.filter((p: any) =>
        (p.nombre_empleado || "").toString()?.toLowerCase().includes(search?.toLowerCase()) ||
        (p.nombre_responsable || "").toString()?.toLowerCase().includes(search?.toLowerCase()) ||
        (p.nombreObra || "").toString()?.toLowerCase().includes(search?.toLowerCase()) ||
        (p.idObra || "").toString()?.toLowerCase().includes(search?.toLowerCase())
    )

};

export const filterByListado = (_partes: any, listadoConf: any) => {
    if (typeof listadoConf?.listadoParteShowClosed === "undefined" || listadoConf?.listadoParteShowClosed || localStorage.getItem("ef-user-rol") as any != constants.roles.empleado) {
        return _partes;

    }

    return _partes.filter((p: any) => p.validado !== constants.estadoParte.validado)
};

export const filterByConfig = (_partes: any, config: any) => {
    const filteredByListado = filterByListado(_partes, config.listado);
    return filteredByListado;
};

export const filterByEmpleado = (_partes: any, selectedEmpleado: any) => {

    if (!selectedEmpleado || selectedEmpleado === "") {
        return _partes;
    }

    return _partes.filter((p: any) => (p.usuario ?? p.user) == selectedEmpleado)
};

export const filterByResponsable = (_partes: any, selectedResponsable: any) => {
    if (!selectedResponsable || selectedResponsable === "") {
        return _partes;
    }

    return _partes.filter((p: any) => p.nombre_responsable == selectedResponsable)
};

export const filterPartes = (
    _partes: any,
    partes: any,
    selectedDateFrom: any,
    selectedDateTo: any,
    selectedEmpresa: any,
    selectedObra: any,
    search: any,
    selectedEmpleado: any,
    selectedResponsable: any,
    config: any,
    filterType: boolean = true,
) => {

    const filteredByConfig = filterByConfig(_partes, config);
    const filteredByCompany = filterByCompany(filteredByConfig, selectedEmpresa)
    const filteredByObra = filterByObra(filteredByCompany, selectedObra)
    const filteredByDateFrom = filterByDateFrom(filteredByObra, selectedDateFrom);
    const filteredByDateTo = filterByDateTo(filteredByDateFrom, selectedDateTo);
    const filteredByEmpleado = filterByEmpleado(filteredByDateTo, selectedEmpleado);
    const filteredByResponsable = filterByResponsable(filteredByEmpleado, selectedResponsable);

    const filteredBySearch = filterBySearch(filteredByResponsable, search);

    return filteredBySearch
};


export const filterFichajes = (
    _fichajes: any,
    fichajes: any,
    selectedDateFrom: any,
    selectedDateTo: any,
    selectedEmpresa: any,
    search: any,
    selectedEmpleado: any,
    config: any,
    filterType: boolean = true,
) => {

    const filteredByConfig = filterByConfig(_fichajes, config);
    const filteredByCompany = filterByCompany(filteredByConfig, selectedEmpresa)
    const filteredByDateFrom = filterByDateFrom(filteredByCompany, selectedDateFrom);
    const filteredByDateTo = filterByDateTo(filteredByDateFrom, selectedDateTo);
    const filteredByEmpleado = filterByEmpleado(filteredByDateTo, selectedEmpleado);

    const filteredBySearch = filterBySearch(filteredByEmpleado, search);

    return filteredBySearch
}