import { useEffect, useRef } from "react";

function useOnDraw(onDraw) {
  const canvasRef = useRef(null);
  const prevPointRef = useRef(null);
  const isDrawingRef = useRef(false);

  const mouseMoveListenerRef = useRef(null);
  const mouseUpListenerRef = useRef(null);
  const touchMoveListenerRef = useRef(null);
  const touchEndListenerRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const myDiv = document.getElementById("divCanva");

    canvas.width = myDiv.clientWidth;
    canvas.height = myDiv.clientHeight;

    const initMouseMoveListener = () => {
      const mouseMoveListener = (e) => {
        if (isDrawingRef.current) {
          const point = computePointInCanvas(e.clientX, e.clientY);
          const ctx = canvasRef.current.getContext("2d");
          if (onDraw) {
            onDraw(ctx, point, prevPointRef.current);
            prevPointRef.current = point;
          }
        }
      };
      mouseMoveListenerRef.current = mouseMoveListener;
      window.addEventListener("mousemove", mouseMoveListener);
    };

    const initMouseUpListener = () => {
      if (!canvasRef.current) {
        return;
      }
      const mouseUpListener = () => {
        isDrawingRef.current = false;
        prevPointRef.current = null;
      };
      mouseUpListenerRef.current = mouseUpListener;
      window.addEventListener("mouseup", mouseUpListener);
    };

    const initTouchMoveListener = () => {
      const touchMoveListener = (e) => {
        if (isDrawingRef.current && e.touches.length === 1) {
          const touch = e.touches[0];
          const point = computePointInCanvas(touch.clientX, touch.clientY);
          const ctx = canvasRef.current.getContext("2d");
          if (onDraw) {
            onDraw(ctx, point, prevPointRef.current);
            prevPointRef.current = point;
          }
        }
      };
      touchMoveListenerRef.current = touchMoveListener;
      window.addEventListener("touchmove", touchMoveListener);
    };

    const initTouchEndListener = () => {
      const touchEndListener = () => {
        isDrawingRef.current = false;
        prevPointRef.current = null;
      };
      touchEndListenerRef.current = touchEndListener;
      window.addEventListener("touchend", touchEndListener);
    };

    const computePointInCanvas = (clientX, clientY) => {
      if (canvasRef.current) {
        const boundingRect = canvasRef.current.getBoundingClientRect();
        return {
          x: clientX - boundingRect.left,
          y: clientY - boundingRect.top,
        };
      }
      return null;
    };

    const removeListeners = () => {
      if (mouseMoveListenerRef.current) {
        window.removeEventListener("mousemove", mouseMoveListenerRef.current);
      }
      if (mouseUpListenerRef.current) {
        window.removeEventListener("mouseup", mouseUpListenerRef.current);
      }
      if (touchMoveListenerRef.current) {
        window.removeEventListener("touchmove", touchMoveListenerRef.current);
      }
      if (touchEndListenerRef.current) {
        window.removeEventListener("touchend", touchEndListenerRef.current);
      }
    };

    initMouseMoveListener();
    initMouseUpListener();
    initTouchMoveListener();
    initTouchEndListener();

    return () => {
      removeListeners();
    };
  }, [onDraw]);

  const setCanvasRef = (ref) => {
    canvasRef.current = ref;
  };

  const onMouseDown = () => {
    isDrawingRef.current = true;
  };

  const onTouchStart = () => {
    isDrawingRef.current = true;
  };

  return { setCanvasRef, onMouseDown, onTouchStart };
}

export { useOnDraw };
