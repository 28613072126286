import { createSlice } from "@reduxjs/toolkit";

const initial = {
  config: {}
};

export const configSlice = createSlice({
  name: "configurations",
  initialState: { ...initial },
  reducers: {
    resetConfig: (state, action: any) => {
      state.config = { ...initial.config } as any;
    },
    setConfigurations: (state, action: any) => {
      const hideConfirmar = action.payload?.report?.reportCerrarDias || !action.payload?.cabeceraParte?.partesConfirmar;
      state.config = { ...action.payload, hideConfirmar } as any;
    },
  },
});

export default configSlice.reducer;
export const { setConfigurations } = configSlice.actions;
