import moment from "moment"
import { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"

import ListadoAvisosFilters from "../BBs/ListadoAvisosFilters/ListadoAvisosFilters"
import ListadoAvisosBorradoresTable from "./BBs/ListadoAvisosBorradoresTable/ListadoAvisosBorradoresTable"

import Loader from "@components/ui/Loader/Loader"
import { EsfirusTablePropsPagination, HandleChangePaginationType } from "@services/hooks/useHandlerPaginationTable"
import { avisosService } from "@services/avisos"
import { OPCIONES_USER_FOR_ME_FILTER } from "../constants"

const ListadoAvisosBorradores = () => {
  const [filters, setFilters] = useState({
    forMe: OPCIONES_USER_FOR_ME_FILTER.DESTINADOS,
    empresa: "",
    dateFrom: moment().startOf("day").add(-3, "month"),
    dateTo: moment().startOf("day").add(1, "day").add(-1, "second"),
    customer: "",
    obra: "",
    empleadoDestino: "",
    empleadoOrigen: "",
    empleadoResponsable: "",
    status: "",
    type: ""
  })

  const [borradoresAvisos, setBorradoresAvisos] = useState<any[]>([]) // TODO pendiente de definir lo que viene del endpoint
  const [totalItems, setTotalItems] = useState<number>(0)
  const [loadingBorradoresAvisos, setLoadingBorradoresAvisos] = useState<boolean>(false)
  const [pagination, setPagination] = useState<EsfirusTablePropsPagination>({ page: 0, sizePage: 50 })

  useEffect(() => {
    let componentMounted = true

    const fetchAvisosBorradores = async () => {
      setLoadingBorradoresAvisos(true)
      try {
        const resp = await avisosService.getListBorradores({
          from: filters.dateFrom.unix(),
          to: filters.dateTo.unix(),
          company: filters.empresa,
          customer: filters.customer,
          obra: filters.obra,
          originEmployee: filters.empleadoOrigen,
          targetEmployee: filters.empleadoDestino,
          responsibleEmployee: filters.empleadoResponsable,
          status: filters.status,
          type: filters.type,
          pagination: pagination
        })
        const newItems = resp?.data?.data?.items ?? []
        const newTotalItems = resp?.data?.data?.total_items ?? 0
        if (componentMounted) {
          setBorradoresAvisos(newItems)
          setTotalItems(newTotalItems)
        }
      } catch {
        setBorradoresAvisos([])
        setTotalItems(0)
      } finally {
        if (componentMounted) setLoadingBorradoresAvisos(false)
      }
    }

    fetchAvisosBorradores()

    return () => {
      componentMounted = false
    }
  }, [filters, pagination])

  if (loadingBorradoresAvisos) {
    return <Loader message="Cargando listado de borradores de avisos" />
  }

  const handleChangePagination: HandleChangePaginationType = (newPaginationData) => {
    setPagination(newPaginationData)
  }

  return (
    <>
      <ListadoAvisosFilters filters={filters} setFilters={setFilters} />
      <ListadoAvisosBorradoresTable
        borradoresAvisos={borradoresAvisos}
        pagination={pagination}
        totalItems={totalItems}
        handleChangePagination={handleChangePagination}
      />

      {/* Para cargar el modal de view y edit avisos */}
      <Outlet />
    </>
  );
}

export default ListadoAvisosBorradores;
