import Icon from "@mui/material/Icon";

interface EsfirusIconProps {
    path: string
}

function EsfirusIcon({path}: EsfirusIconProps) {
    return (
        <Icon sx={{
            width: 'auto',
            height: 'auto',
            display: 'flex'
        }}>
            <img src={path} alt='document'/>
        </Icon>
    );
}


export default EsfirusIcon;