import './Report.scss';
import Header from "@components/Header/Header";
import EsfirusResume from "@components/ui/Resume/EsfirusResume";
import ReportHeader from "./BBs/header/header";
import EsfirusReportTable from "./BBs/table/Table";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { isEmpleado } from "@services/hooks/UserHook";
import EsfirusButtonNative from '@components/ui/Button/EsfirusButtonNative';
import backIcon from "@assets/images/icons/back-arrow.svg";
import useReportsGet from '@services/hooks/useReportsGet';
import { constants } from '@helpers/constants';
import { configurationService } from '@services/configApp';
import { emptyResourceRedirect } from '@helpers/navigate';

function Report() {
    const navigate = useNavigate();
    const reports = useSelector((state) => (state as any).reports);
    const obras = useSelector((state) => (state as any).obras).list;
    const reportList = reports.list;
    const selectedDate = reports.selectedDate;
    const selectedTypeHour = reports.selectedTypeHour;

    const search = reports.search;
    const selectedEmpresa = reports.selectedEmpresa;
    const selectedMesAnio = reports.selectedMesAnio;

    const { resumenHorasExtra } = configurationService.getConfigReport();


    const dispatch = useDispatch();
    const getReports = useReportsGet();


    const partes = useSelector((state) => (state as any).partes);

    useEffect(() => {
        refreshDataGrid();
        emptyResourceRedirect({ check: obras.length === 0, path: "/partes/resumen", navigate });
    }, []);



    useEffect(() => {
        generateGrid()
    }, [reportList, selectedDate, selectedTypeHour, search, selectedEmpresa, selectedMesAnio, partes]);


    const refreshDataGrid = () => {
        dispatch(getReports() as any);
    }


    const [grid, setGrid] = useState({ columns: [], rows: [], selectedDate: selectedMesAnio });
    const getColumnsDays = () => {

        // const date = new Date(selectedDate);

        const mesAnioSplit = selectedMesAnio.split('-');
        const month = Number(mesAnioSplit[0]);
        const year = Number(mesAnioSplit[1]);
        const date = new Date(year, month - 1, 1);

        const totalDays = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

        return Array(totalDays).fill(0).map((day: any, index: number) => ({ name: `${index + 1}`, keymap: `${index + 1}` }));
    };

    const getRow = (report: any, columns: []) => {
        const row: any = {
            empleado: report.nombre_empleado
        };

        if (selectedMesAnio === '')
            return {
                ...row,
                closedDays: [],
                id_empleado: report.id_empleado
            }

        const mesAnioSplit = selectedMesAnio.split('-');
        const month = Number(mesAnioSplit[0]) - 1;
        const year = Number(mesAnioSplit[1]);

        let totalHours = 0
        columns.forEach((date: any, index) => {
            const day = Number.parseInt(date.name);
            const res = report.days.filter((r: any) => {
                if ((selectedTypeHour && r.tipo != selectedTypeHour) || (selectedEmpresa && r.empresa != selectedEmpresa)) {
                    return false;
                }

                const inicio = new Date(Number.parseInt(r.inicio) + (2 * 60 * 60 * 1000)); // TODO grande. Le sumamos 2 horas al time en ms porque los partes se están guardando con fecha 22:00h del día anterior.
                const isCurrentDay = inicio.getUTCFullYear() === year &&
                    inicio.getUTCMonth() === month &&
                    inicio.getUTCDate() === day;
                return isCurrentDay
            });

            //sumamos si llegan varias horas el mismo dia
            const sumHours = res.map((r: any) => r.horas).reduce(
                (accumulator: any, currentValue: any) => {
                    return accumulator + currentValue
                }, 0
            );
            const horasExtra = Math.max(0, sumHours - 8);

            let allConfirmed = res.filter((r: any) => r.estado === constants.estadoParte.validado).length === res.length

            totalHours = totalHours + sumHours

            row[`${index}`] = {
                horas: sumHours,
                horasExtra,
                allConfirmed
            }
        });

        const closedDays = partes?.closedDays?.filter((closed: any) => closed?.empleado === report.id_empleado)?.filter((closed: any) => {
            const _daySplit = closed?.day?.split('-');
            if (_daySplit?.lenght < 2) return false;
            const isCurrentDay = Number(_daySplit[0]) === year && Number(_daySplit[1]) - 1 === month;
            return isCurrentDay;
        })?.map(
            (closed: any) => {
                const _daySplit = closed?.day?.split('-');
                return Number(_daySplit[2]);
            }
        )
            .filter((value: any, index: any, array: any) => array.indexOf(value) === index);

        return {
            ...row,
            closedDays,
            id_empleado: report.id_empleado,
            total: totalHours
        }

    }

    const generateGrid = () => {

        if (selectedMesAnio === '') {
            setGrid({ columns: [], rows: [], selectedDate: selectedMesAnio });
            return false
        };



        const _grid: any = {
            columns: [
                {
                    name: 'Empleado',
                    keymap: 'empleado'
                },
            ],
            rows: [],
            selectedDate: selectedMesAnio
        };

        _grid.columns = _grid.columns.concat(getColumnsDays())

        if (isEmpleado()) {
            const userId = Number(localStorage.getItem("ef-user-id"));
            _grid.rows = reportList?.filter((r: any) => r.id_empleado === userId)?.map((r: any) => getRow(r, _grid.columns))
        } else {
            _grid.rows = reportList?.filter((p: any) => (p.nombre_empleado || "").toString().toLowerCase().includes(search.toLowerCase()))?.map((r: any) => getRow(r, _grid.columns)).filter((r: any) => r.total > 0)
        }

        setGrid(_grid);
    }



    return (
        <>
            <Header />
            <div className="center ef-report">
                <div className="ef-report-title">
                    <EsfirusButtonNative
                        className={"tertiary"}
                        label={window.innerWidth > 500 ? "Volver" : ""}
                        icon={backIcon}
                        iconClass={"icon-tertiary"}
                        click={() => {
                            navigate("/partes")
                        }}
                    ></EsfirusButtonNative>
                    <div className="title">
                        <h4>Resumen mensual</h4>
                        <p>Selecciona la empresa y el mes que quieras visualizar</p>
                    </div>
                </div>
                <ReportHeader selectedTypeHour={selectedTypeHour} selectedEmpresa={selectedEmpresa} selectedMesAnio={selectedMesAnio} admin={!isEmpleado()}></ReportHeader>
                {window.innerWidth > 500 ?
                    <>
                        <EsfirusReportTable grid={grid} ></EsfirusReportTable>
                        <EsfirusResume partes={[]} reports={grid} resumenMensual={true} resumenHorasExtra={resumenHorasExtra} ></EsfirusResume>
                    </>
                    :
                    <div className="title-rotate">

                        <p>Rota la pantalla para poder ver</p>
                    </div>
                }

            </div>
        </>
    );
}

export default Report;