import { getRequest } from "@screens/Backoffice/utils/requests"

export const checkResponsableObraIsIntern = async (obra: any) => {
  try {
    const filter = { empresa: obra?.company, empleado: obra?.responsible }
    const response = await getRequest(`users/find?filter=${JSON.stringify(filter)}`)

    const userExtraData = JSON.parse(response?.data?.data ?? "{}")

    const isIntern = userExtraData.internal?.some((par: { empleado: any; empresa: any }) => par.empresa === obra?.company && par.empleado === obra?.responsible);
    return isIntern
  } catch (error) {
    return false
  }
}