import Modal from "@mui/material/Modal";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";
import EsfirusTextInput from "@components/ui/Text-input/EsfirusTextInput";
import { useMemo, useState } from "react";

const MAX_LONG_DESCRIPTION = 255

const ModalConfirmar = ({ title, open, onClose, initialText, loading, msgLoading, finished, msgFinished, failed, msgFailed, action, isCreateBuild = false }) => {
  const [description, setDescription] = useState("")
  const descriptionCharactersCounter = useMemo(() => description.length, [description])
  const buttonDisabled = useMemo(() => descriptionCharactersCounter > MAX_LONG_DESCRIPTION, [descriptionCharactersCounter])

  const msg = useMemo(() => {
    if (failed) return msgFailed
    if (finished) return msgFinished
    if (loading) return msgLoading
    return initialText
  }, [initialText, loading, msgLoading, finished, msgFinished, failed, msgFailed])

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="new-confirm-modal-sign">
        <div className="header">
          <h5 className="text-center">
            {title}
          </h5>
        </div>
        <div className="body">
          {loading && <span className="loader" style={{ color: "#075577" }}></span>}
          {isCreateBuild && !loading && !finished && !failed && (
            <div className="new-confirm-modal-desc-wrapper">
              <div className="text-center">
                Escribe una pequeña descripción de la compilación
              </div>
              <EsfirusTextInput
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <div className="new-confirm-modal-desc-note-character-left">
                Carácteres restantes: {Math.max(MAX_LONG_DESCRIPTION - descriptionCharactersCounter, 0)}
              </div>
            </div>
          )}
          <div className="text-center ph-30">
            {msg}
          </div>
          <div className="button-container">
            {!finished && (
              <EsfirusButtonNative
                click={async () => await action(description)}
                disabled={loading || buttonDisabled}
                label={isCreateBuild ? "Crear" : "Sí"}
              />
            )}
            <EsfirusButtonNative
              className={finished ? "" : "tertiary"}
              click={() => onClose()}
              disabled={loading}
              label={isCreateBuild ? "Cancelar" : (finished ? "Cerrar" : "No")}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalConfirmar;
