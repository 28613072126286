import "./EsfirusButtonNative.scss";

export default function EsfirusButtonNative(props: EsfirusButtonNativeProps) {
  return (
    <div className="ef-button-nat">
      <button
        disabled={props?.disabled}
        className={`btn ${props?.className}`}
        onClick={(e) => {
          e.preventDefault();
          props?.click();
        }}
      >
        {props?.icon && (
          <img
            src={props?.icon}
            className={`icon ${props?.iconClass}`}
            alt="icon"
          />
        )}
        {props.label}
      </button>
    </div>
  );
}

export interface EsfirusButtonNativeProps {
  label: string;
  className?: string;
  onClick?: any;
  icon?: any;
  iconClass?: any;
  click?: any;
  disabled?: boolean;
}
