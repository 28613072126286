import { TAB_OPTIONS } from "../constants"

const SaasTab = ({ label = "", selected = false, onSelect = () => { } }) => {
  return (
    <div
      className={`tabOption ${selected ? "selected" : ""}`}
      onClick={onSelect}
    >
      {label}
    </div>
  )
}

const SaasTabs = ({ tabSelected = TAB_OPTIONS.NEW_CLIENT, setTabSelected = () => { } }) => {
  return (
    <div className="tabWrapper">
      <SaasTab
        label="Nuevo cliente"
        selected={tabSelected === TAB_OPTIONS.NEW_CLIENT}
        onSelect={() => setTabSelected(TAB_OPTIONS.NEW_CLIENT)}
      />
      <SaasTab
        label="Compilaciones"
        selected={tabSelected === TAB_OPTIONS.BUILDS}
        onSelect={() => setTabSelected(TAB_OPTIONS.BUILDS)}
      />
      <SaasTab
        label="Actualizar cliente"
        selected={tabSelected === TAB_OPTIONS.UPDATE_CLIENT}
        onSelect={() => setTabSelected(TAB_OPTIONS.UPDATE_CLIENT)}
      />
    </div>
  )
}

export default SaasTabs