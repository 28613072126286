import { IconButton } from "@mui/material";
import { RemoveCircle, DownloadForOffline } from "@mui/icons-material";
import FileUploader from "@components/ui/FileUploader/FileUploader"
import "./AdjuntosContainer.scss"
import { convertToBase64FromFileInput } from "@helpers/utils";
import { pedidosService } from "@services/pedidos";
import { FilePedido } from "@components/FormParte/FormPedido";

const AttachedFiles = ({ files = [], handleRemove = () => { }, handleDownload = () => { }, editable = false, isView = false }: any) => {
  const renderDeleteFileButton = editable
  const renderDownloadFileButton = isView

  return (
    <ul className="attached-files-wrapper">
      {files.map((file: any, index: any) => (
        <li key={index} className="attached-file">
          <span>{file.name}</span>
          {renderDeleteFileButton && !file.unique_id && (
            <IconButton
              onClick={() => handleRemove(index)}
            >
              <RemoveCircle sx={{ color: "#CD5C5C" }} />
            </IconButton>
          )}
          {(renderDownloadFileButton || file.unique_id) && (
            <IconButton
              onClick={() => handleDownload(file)}
            >
              <DownloadForOffline sx={{ color: "#075577" }} />
            </IconButton>
          )}
        </li>
      ))}
    </ul>
  )
}

const AdjuntosContainer = ({ formData, setFormData, editable, isView }: any) => {
  const handleAddFile = async (event: any) => {
    const file = event.target.files[0]
    if (!file) return

    if (file.size > 200000000) { // 200MB
      alert("No se pueden insertar archivos de más de 200MB.")
      return
    }

    const fileInBase64 = await convertToBase64FromFileInput(file, true)
    if (!fileInBase64) return

    const fileToInsert = {
      name: file.name,
      base64: fileInBase64,
    }
    setFormData({ ...formData, files: [...formData.files, fileToInsert] })
  }

  const handleRemoveFile = (indexToRemove: any) => {
    const newFiles = formData.files.filter((_: any, index: any) => index !== indexToRemove)
    setFormData({ ...formData, files: newFiles })
  }

  const handleDownload = async (file: FilePedido) => {
    if (!file.document_id || !file.unique_id) {
      alert("No se puede descargar este archivo.")
      return
    }

    try {
      const response = await pedidosService.downloadFile(file)

      const urlBlob = window.URL.createObjectURL(new Blob([response?.data]))
      const a = document.createElement('a')
      a.href = urlBlob
      a.download = file.name
      a.click()
      window.URL.revokeObjectURL(urlBlob)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="adjuntos-wrapper">
      <h5 className="text-center header-add">Archivos adjuntos del pedido</h5>
      {formData.files && formData.files.length > 0 ? (
        <AttachedFiles
          files={formData.files}
          handleRemove={handleRemoveFile}
          handleDownload={handleDownload}
          editable={editable}
          isView={isView}
        />
      ) : (
        <span>No hay archivos en el pedido.</span>
      )}
      {editable && (
        <FileUploader
          labelText="Seleccionar archivo"
          onFileSelect={handleAddFile}
        />
      )}
    </div>
  )
}

export default AdjuntosContainer