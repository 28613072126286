import { useState } from "react";
import "./EsfirusSelectCustom.scss";

const getOptions = (disabled: any, change: any, setOpen: any, options?: Array<EsfirusSelectCustomPropsOption>) => {
  return options?.map((o: EsfirusSelectCustomPropsOption, index) => (
    <div className="option" key={`ef-select-${index}`} onClick={() => {
      if (disabled) {
        return;
      }
      // o.value
      setOpen(false);
      change(o.value);
    }}>
      {o.label}
    </div>
  ));
};

export default function EsfirusSelectCustom(props: EsfirusSelectCustomProps) {
  const [open, setOpen] = useState(false);

  const optionSelected = props?.options?.find(option => option.value === props?.value)

  return (
    <div className="ef-select">
      <div
        className={`selector ${props.fill ? "filled" : ""} ${props?.disabled ? ' disabled' : ''}`}
        onClick={() => setOpen(!open)}
      >
        {optionSelected?.label || props?.value || props.placeholder}
      </div>
      {
        open && <div className="options">
          <div className="option" onClick={() => {
            setOpen(false);
            props.change("");
          }}>{props.placeholder}</div>
          {getOptions(props?.disabled, props?.change, setOpen, props.options)}
        </div>
      }

    </div>
  );
}

export interface EsfirusSelectCustomPropsOption {
  label: string;
  value: any;
}
export interface EsfirusSelectCustomProps {
  placeholder?: string;
  options?: Array<EsfirusSelectCustomPropsOption>;
  fill?: boolean;
  value?: any;
  change?: any;
  disabled?: boolean;
  required?: boolean;
}
