import { createSlice, current } from "@reduxjs/toolkit";
const initial: any = {
  list: [],
  markForRemove: []
};

export const linesSlice = createSlice({
  name: "lines",
  initialState: { ...initial },
  reducers: {
    addLine: (state, action) => {
      state.list = [...state.list, action.payload] as any;

    },
    removeLine: (state, action) => {
      const currentLines: any = [...state.list];
      const lineRemoved = currentLines.filter(
        (el: any) => el.index !== action.payload.index
      );

      state.list = [...lineRemoved] as any;
      if(action.payload.idLinea) state.markForRemove = [ ...state.markForRemove, action.payload.idLinea]
    },
    updateLine: (state, action) => {
      const currentLines: any = [...current(state).list];
      const updateIndex = currentLines.findIndex(
        (l: any) => l.index === action.payload.index
      );
      currentLines[updateIndex] = { ...action.payload };
      state.list = [...currentLines] as any;
    },
    resetLines: (state) => {
      state.markForRemove = [];
      state.list = [] as any;
    },
    resetMarkForRemove: (state) => {
      state.markForRemove = [];
    },
  },
});

export default linesSlice.reducer;
export const { addLine, updateLine, removeLine, resetLines, resetMarkForRemove } =
  linesSlice.actions;
