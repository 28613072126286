export const TAB_OPTIONS = {
  NEW_CLIENT: "new-client",
  UPDATE_CLIENT: "update-client",
  BUILDS: "builds",
}

export const status = {
  0: "Sin crear",
  1: "Creando Base de datos",
  2: "Creando servidores (3 minutos)",
  3: "Confirmado",
  4: "Reiniciando",
  5: "Reiniciado"
}