/* tslint:disable */
/* eslint-disable */
/**
 * Microtech partes API
 * API Library for SmartyBar application
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * ConfigsApi - axios parameter creator
 * @export
 */
export const ConfigsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a config by key
         * @param {string} key The key send by email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configKeyGet: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling configKeyGet.');
            }
            const localVarPath = `/configs/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a config by key
         * @param {string} key The config key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configKeyPut: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling configKeyPut.');
            }
            const localVarPath = `/config/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigsApi - functional programming interface
 * @export
 */
export const ConfigsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a config by key
         * @param {string} key The key send by email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configKeyGet(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await ConfigsApiAxiosParamCreator(configuration).configKeyGet(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs).catch((error) => {
                    configuration?.catchError(error);
                });;
            };
        },
        /**
         * 
         * @summary Update a config by key
         * @param {string} key The config key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configKeyPut(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await ConfigsApiAxiosParamCreator(configuration).configKeyPut(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs).catch((error) => {
                    configuration?.catchError(error);
                });;
            };
        },
        /**
         * 
         * @summary Returns a list of configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<any>> {
            const localVarAxiosArgs = await ConfigsApiAxiosParamCreator(configuration).configsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs).catch((error) => {
                    configuration?.catchError(error);
                });;
            };
        },
    }
};

/**
 * ConfigsApi - factory interface
 * @export
 */
export const ConfigsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Returns a config by key
         * @param {string} key The key send by email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configKeyGet(key: string, options?: AxiosRequestConfig): Promise<any> {
            return ConfigsApiFp(configuration).configKeyGet(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a config by key
         * @param {string} key The config key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configKeyPut(key: string, options?: AxiosRequestConfig): Promise<any> {
            return ConfigsApiFp(configuration).configKeyPut(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of configs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configsGet(options?: AxiosRequestConfig): Promise<any> {
            return ConfigsApiFp(configuration).configsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigsApi - object-oriented interface
 * @export
 * @class ConfigsApi
 * @extends {BaseAPI}
 */
export class ConfigsApi extends BaseAPI {
    /**
     * 
     * @summary Returns a config by key
     * @param {string} key The key send by email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigsApi
     */
    public async configKeyGet(key: string, options?: AxiosRequestConfig) : Promise<any> {
        return ConfigsApiFp(this.configuration).configKeyGet(key, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update a config by key
     * @param {string} key The config key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigsApi
     */
    public async configKeyPut(key: string, options?: AxiosRequestConfig) : Promise<any> {
        return ConfigsApiFp(this.configuration).configKeyPut(key, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Returns a list of configs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigsApi
     */
    public async configsGet(options?: AxiosRequestConfig) : Promise<any> {
        return ConfigsApiFp(this.configuration).configsGet(options).then((request) => request(this.axios, this.basePath));
    }
}
