import { useEffect, useState } from "react";
import moment from "moment";

import EsfirusSelect from "@components/ui/Select/EsfirusSelect";
import EsfirusButtonNative from "@components/ui/Button/EsfirusButtonNative";
import EsfirusTextInput from "@components/ui/Text-input/EsfirusTextInput";
import ModalConfirmar from "./ModalConfirmar";

import { getRequest, postRequest } from "@screens/Backoffice/utils/requests";

const NewClientForm = () => {
  // Datos del formulario.
  const [name, setName] = useState("");
  const [frontUrl, setFrontUrl] = useState("");
  const [apiUrl, setApiUrl] = useState("");
  const [buildSelected, setBuildSelected] = useState("");

  // Estados de validación de las rutas escritas.
  const [checkStatus, setCheckStatus] = useState({ front: false, api: false });

  // Confirmación de la creación.
  const [loadingCreateClient, setLoadingCreateClient] = useState(false);
  const [createClientFinished, setCreateClientFinished] = useState(false);
  const [createClientFailed, setCreateClientFailed] = useState(false);
  const [showModalCreateClient, setShowModalCreateClient] = useState(false);

  // Carga de builds.
  const [loadingBuilds, setLoadingBuilds] = useState(true);
  const [builds, setBuilds] = useState([]);

  useEffect(() => {
    fetchBuilds();
  }, []);

  const fetchBuilds = async () => {
    setLoadingBuilds(true);
    try {
      const compilacionesResponse = await getRequest("compilaciones");
      const compilaciones = compilacionesResponse.data;
      setBuilds(compilaciones);
    } catch (error) {
      setBuilds([]);
    } finally {
      setLoadingBuilds(false);
    }
  };

  const checkDNS = (type) => async () => {
    const url = type === "front" ? frontUrl : apiUrl;
    const res = await postRequest("modules/sas/domainip", { url });
    setCheckStatus({
      ...checkStatus,
      [type]:
        res.data.status === "success" && res.data.query === "212.227.110.135",
    });
  };

  const createClient = async () => {
    setLoadingCreateClient(true);
    try {
      const result = await postRequest("modules/sas/db", {
        name,
        frontUrl,
        apiUrl,
        version: buildSelected,
      });

      if (result.ok) {
        await postRequest("modules/sas/code", {
          name,
          frontUrl,
          apiUrl,
          version: buildSelected,
        });

        await postRequest("modules/sas/updatehash", {
          name,
          hash: buildSelected,
        });
      } else {
        throw new Error("Error al crear el cliente.");
      }
    } catch (error) {
      setCreateClientFailed(true);
    } finally {
      // Esperar 1 min antes de abrir la configuración. Generar certificado y levantar dockers
      setTimeout(() => {
        window.open("https://" + frontUrl + "?config=1");
        setLoadingCreateClient(false);
        setCreateClientFinished(true);
      }, 60 * 1000);
    }
  };

  // const reiniciar = async () => {
  //   setCreateStatus(4);
  //   const res = await getRequest('modules/sas/server/restart')
  //   console.log(res);
  //   if (res.data && typeof res.ok === 'undefined') {
  //     setCreateStatus(0);

  //   } else {
  //     alert("Error al reinicar launcher" + res.data.message)
  //   }
  // }

  return (
    <div className="newClientForm">
      <EsfirusTextInput
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Nombre"
      />
      <EsfirusTextInput
        fullWidth
        value={frontUrl}
        placeholder="Frontend URL"
        onChange={(e) => setFrontUrl(e.target.value)}
        onBlur={checkDNS("front")}
        valid={!!checkStatus.front}
      />
      <EsfirusTextInput
        fullWidth
        value={apiUrl}
        placeholder="Api URL"
        onChange={(e) => setApiUrl(e.target.value)}
        onBlur={checkDNS("api")}
        valid={!!checkStatus.api}
      />
      <EsfirusSelect
        style={{ maxWidth: "350px" }}
        options={builds.map((build) => {
          const label = `${build.hash} | ${moment(build.time).format(
            "DD-MM-YYYY"
          )}`;
          return { value: build.hash, label };
        })}
        change={(e) => setBuildSelected(e)}
        disabled={loadingBuilds}
        value={buildSelected}
        placeholder="Seleccionar la compilación"
      />
      <EsfirusButtonNative
        className="newClientForm-button"
        label="Crear"
        click={() => {
          if (
            name == "" ||
            frontUrl == "" ||
            apiUrl == "" ||
            buildSelected == ""
          ) {
            alert("Faltan datos a completar del formulario.");
            return;
          }

          if (!checkStatus["front"] || !checkStatus["api"]) {
            alert("Alguna de las URL's no apuntan a nuestro servidor.");
            return;
          }

          setShowModalCreateClient(true);
        }}
      />
      {/* <button className="redButton" onClick={() => reiniciar()}>Reiniciar launcher</button> */}

      {/* {!!createStatus &&
        <div className="newClientStateWrapper">
          {status[createStatus]}
          {createStatus === 3
            ? <a href={"https://" + frontUrl + "?config=1"} target="_blank">Configurar</a>
            : <CircularProgress style={{ width: 20, height: 20 }} />
          }
        </div>
      } */}

      {/* Modal de confirmación de creación de nueva build */}
      {showModalCreateClient && (
        <ModalConfirmar
          title="Creación de un nuevo cliente"
          open={showModalCreateClient}
          onClose={() => {
            setShowModalCreateClient(false);
            setLoadingCreateClient(false);
            setCreateClientFinished(false);
            setCreateClientFailed(false);
          }}
          initialText="¿Estás seguro que quieres crear un nuevo cliente?"
          loading={loadingCreateClient}
          msgLoading="Creando el nuevo cliente. Este proceso tardará unos minutos."
          finished={createClientFinished}
          msgFinished="Cliente creado correctamente."
          failed={createClientFailed}
          msgFailed="Algo ha salido mal, vuelve a intentarlo en unos minutos."
          action={async () => await createClient()}
        />
      )}
    </div>
  );
};

export default NewClientForm;
