import { styled } from "@mui/material/styles";
import { TextField, TextFieldProps } from "@mui/material";

const CustomTextField = styled(TextField)<TextFieldProps>(({ theme, modified, valid }: any) => ({
  borderRadius: 20,
  borderWidth: 1,
  border: modified ? "2px solid #D97938" : valid ? "2px solid #82d08e" : "1px solid #075577",
  backgroundColor: "white",
  "& .MuiOutlinedInput-root": {
    borderRadius: 20,
    borderWidth: 0,
    border: 0,
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    backgroundColor: "#efefef",
  },
  "& input": {
    textAlign: "center",
    color: "#075577",
  },
  "& fieldset": {
    border: 0,
  },
}));

export default function EsfirusTextInput(props: any) {
  return (
    <CustomTextField
      size="small"
      color="info"
      inputProps={props?.inputProps}
      {...props}
    >
      {props.children}
    </CustomTextField>
  );
}
