import axios, { AxiosResponse } from "axios"

import { apiConfig } from "./config"
import { Configuration } from "./swagger/configuration"
import { FilePedido, FormDataPedido } from "@components/FormParte/FormPedido"
import { EsfirusTablePropsPagination } from "./hooks/useHandlerPaginationTable"
import { delay } from "@helpers/utils"

interface GetFilters {
  unique_id: number | string
}

interface GetListFilters {
  from: number
  to: number
  company?: string | number
  status?: string
  empleado?: string
  pagination: EsfirusTablePropsPagination
}

class PedidosService {
  basePath = ""

  constructor(_apiConfig: Configuration) {
    this.basePath = (_apiConfig.basePath || '') as string
  }

  async get({ unique_id }: GetFilters) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`
    try {
      const resp = await axios({
        baseURL: this.basePath + "/pedidos/" + unique_id,
        headers: {
          Authorization: accessToken
        },
        method: "get",
      })
      return resp
    } catch (error) {
      apiConfig?.catchError(error)
    }
  }

  async getList({ from, to, company, status, empleado, pagination }: GetListFilters) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`
    try {
      const resp = await axios({
        baseURL: this.basePath + "/pedidos",
        headers: {
          Authorization: accessToken
        },
        method: "get",
        params: {
          from,
          to,
          company: company === "" ? null : company,
          status: status === "" ? null : status,
          empleado: empleado === "" ? null : empleado,
          limit: pagination.sizePage,
          page: pagination.page
        }
      })
      return resp
    } catch (error) {
      apiConfig?.catchError(error)
    }
  }

  async create(data: FormDataPedido, isLastRetry?: boolean): Promise<AxiosResponse<any, any>> {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`
    try {
      const resp = await axios({
        baseURL: this.basePath + "/pedidos",
        headers: {
          Authorization: accessToken
        },
        method: "post",
        data: data
      })
      return resp
    } catch (error) {
      if (isLastRetry) apiConfig?.catchError(error)

      // Hacemos un reintento después de tres segundos.
      await delay(20000)
      const resp = this.create(data, true)
      return resp
    }
  }

  async modify(data: FormDataPedido) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`
    try {
      const resp = await axios({
        baseURL: this.basePath + "/pedidos/" + data.unique_id,
        headers: {
          Authorization: accessToken
        },
        method: "put",
        data: data
      })
      return resp
    } catch (error) {
      apiConfig?.catchError(error)
    }
  }

  async downloadFile(file: FilePedido) {
    try {
      const resp = await axios({
        baseURL: this.basePath + "/pedidos/download",
        method: "get",
        responseType: "blob",
        params: {
          document_id: file.document_id,
          unique_id: file.unique_id,
          name: file.name
        }
      })
      return resp
    } catch (error) {
      apiConfig?.catchError(error)
    }
  }

  async sendFailedEmail(data: FormDataPedido) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`
    try {
      const resp = await axios({
        baseURL: this.basePath + "/pedidos/send-failed-email",
        headers: {
          Authorization: accessToken
        },
        method: "post",
        data: data
      })
      return resp
    } catch (error) {
      apiConfig?.catchError(error)
    }
  }
}

export const pedidosService = new PedidosService(apiConfig)
