import { useMatch, useNavigate } from "react-router-dom"
import { ButtonProps } from "@mui/material";

import EsfirusButton from "@ui/Button/EsfirusButton"
import EsfirusIcon from "@ui/icons/EsfirusIcon"

interface NavigateButtonProps extends ButtonProps {
  icon: string;
  path: string;
  label: string;
  exactPath?: boolean;
}

const HeaderNavigateButton = ({ icon, label, path, color = "primary", variant = "contained" }: NavigateButtonProps) => {
  const navigate = useNavigate()
  const match = useMatch(path)

  return (
    <EsfirusButton
      color={color}
      variant={variant}
      startIcon={<EsfirusIcon path={icon} />}
      onClick={() => navigate(path)}
      sx={{ boxShadow: !!match ? 10 : 0 }}
    >
      {label}
    </EsfirusButton>
  )
}

export default HeaderNavigateButton