import { MultiSelect, multiselect } from "@esfirus/n-backoffice";
import { Edit, Delete, Lock } from '@mui/icons-material';

function Welcome(props) {
	return <h4>Remplazando elementos desde el theme, {props.value}</h4>;
}

export const themedFields = {
	// "default": ({field, fieldHandleChange, format, state}) => {
	// return <Welcome value={format({ state, field })}/>
	// },
	// "textarea": ({ field, fieldHandleChange, format, state }) => {
	// 	return (
	// 		<textarea
	// 			value={format({ state, field })}
	// 			onChange={fieldHandleChange}
	// 			disabled={true}
	// 		/>

	// 	);
	// },
	"editIcon": <Edit sx={{ color: "#075577" }} />,
	"trashIcon": <Delete sx={{ color: "#075577" }} />,
	"lockIcon": <Lock sx={{ color: "#075577" }} />
}