// Styles
import HomeIcon from "@material-ui/icons/Home";
import AddBox from '@material-ui/icons/AddBox';
import BarChart from '@material-ui/icons/BarChart';
import Pencil from '@material-ui/icons/Edit';
import Trash from '@material-ui/icons/Delete';
import Menu from '@material-ui/icons/Menu';
import Cross from '@material-ui/icons/Close';
import styled from 'styled-components';




const primary = '#075577';
const secondary = '#fff';
const _default = '#222831';
const fontSecondary = '#5e5873';
const black = '#4B4B4B';
const disabled = '#9B9B9B';
const fontFamily = "'Montserrat',Helvetica,Arial,serif";
const fontFamilyB = "Helvetica,Arial,serif";
const borderRadius = '4px';
const padding = '10px 16px';
const fontSize = '14px';
const fontSizeInput = '.857rem';
const fontWeightInput = '400';
const fontColorInput = '#6e6b7b';
const fontColorTable = '#6e6b7b';
const width = '15px';



export const EditIcon = styled(Pencil)`
    fill: #998335;
    height: 100%;
    width: auto;
    padding: 0 15px;
`;

export const TrashIcon = styled(Trash)`
    fill: #fc4219;
    fill-rule: evenodd;
    height: 100%;
    width: auto;
    padding: 0 15px;
`;
const buttonsColors = {
    primary: {
        background: primary,
        text: secondary,
    },
    secondary: {
        background: "white",
        border: "1px solid " + primary,
        text: primary,
    },
    third: {
        background: secondary,
        text: '#247BC4',
    },
    default: {
        background: _default,
        text: 'white',
    },
};

const paginationColors = {
    active: {
        ...buttonsColors.primary,
    },
    default: {
        background: secondary,
        text: _default,
    },
    arrows: {
        fillColor: _default,
    },
};

export const colors = {
    primary,
    secondary,
    default: _default,
    black,
    disabled,
};

const theme = {
    display: "grid",
    backgroundColor: "#f8f8f8",
    fontFamily,
    borderRadius,
    ...colors,
    button: {
        fontFamilyB,
        borderRadius,
        padding,
        ...buttonsColors,
    },
    pagination: {
        fontFamily,
        borderRadius,
        borderColor: _default,
        borderWidth: '1px',
        padding: '8px',
        ...paginationColors,
        mobile: {
            borderRadius,
            borderColor: _default,
            borderWidth: '1px',
            padding: '8px 13px',
        },
    },
    childrenWrapper: {
        margin: "30px 0 0",
        width: "100%",
        // width: "calc(100% - 250px)",
        background: "transparent",
    },
    link: {
        fontFamily,
        fontSize,
        padding: '0',
        ...buttonsColors,
        default: {
            text: _default,
        },
    },
    input: {
        fontFamily,
        fontSize: '1rem',
        placeholderFontSize: fontSize,
        placeholderColor: fontSecondary,
        borderFocusColor: primary,
        borderRadius: '.357rem',
        backgroundColor: 'primary',
        padding: '.438rem 1rem',
        active: {
            borderColor: '#d8d6de',
            backgroundColor: 'transparent',
            color: fontColorInput,
            placeholderColor: _default,
            borderWidth: '1px',
        },
        disabled: {
            borderColor: '#d8d6de',
            backgroundColor: 'hsl(0,0%,95%)',
            color: 'hsl(0,0%,60%)',
            placeholderColor: _default,
            borderWidth: '1px',
        },
        checkbox: {
            width,
            borderWidth: '2px',
            borderRadius,
        },
        radio: {
            width,
        },
    },
    label: {
        fontFamily,
        color: fontSecondary,
        fontSize: fontSizeInput,
        fontWeight: fontWeightInput,
    },
    text: {
        color: primary,
        padding: '0',
        margin: '0',
    },
    modal: {
        fontFamily,
        borderRadius,
        padding: '10px 15px',
        maxWidth: '500px',
        blur: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        backgroundColor: secondary,
        backdropFilter: 'none',
        opacity: '1',
    },
    card: {
        backgroundColor: secondary,
        borderRadius: '0.5rem',
        padding: '10px 15px',
        boxShadow: '0 0 1rem 0.5rem rgba(34, 40, 49, 0.3)',
    },
    sidebar: {
        width: '250px',
        // position: "relative",
        backgroundColor: "white",
        icon: {
            fill: _default,
            '/': HomeIcon,

        },
        overflowY: "auto",
    },
    navBar: {
        width: "100%",
        position: "relative",
        right: "0",
        fontFamily,
        backgroundColor: '#ffffff',
        padding: '5px 10px',
        boxShadow: "0 4px 24px 0 rgba(34, 41, 47, .1)",
        margin: '0',
        borderRadius: '0.428rem',
        color: fontColorInput,
        profile: {
            fontFamily,
            color: '#6e6b7b',
            fontSize: '1rem',
        },
        accountMenu: {
            top: "39px",
            width: "100px"

        },
        imageProfile: {
            svg: {
                width: '80%',
                height: 'auto',
                fill: '#000',
            }
        },
        hamburguer: {
            fill: "black",
        },
    },
    mobileMenu: {
        menuIcon: {
            svg: Menu,
        },
        closeIcon: {
            svg: Cross,
        },

    },
    table: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '0 0 0 0',
        tr: {
            backgroundEven: "#ffffff",
        },
        td: {
            borderTop: "1px solid #ebe9f1;",
            padding: ".72rem 2rem",
            textAlign: "left",
            color: fontColorTable,
            fontSize: "1rem",
            fontWeight: "400",
        },
        th: {
            backgroundColor: primary + "2f",
            padding: ".72rem 2rem",
            color: fontColorTable,
            fontSize: ".857rem",
            textTransform: "uppercase",
            letterSpacing: "0.5px",
            fontWeight: "400",
            textAlign: "left",
            borderBottom: "0px",
        },
    },
    listItem: {
        fontSize: "1.1rem",
        fontWeight: fontWeightInput,
        color: fontColorInput,
        hover: {
            borderRight: "none",
            backgroundColor: primary,
            color: "#fff",
            paddingLeft: '1.4rem',
        },
        margin: "0 1.071rem",
        padding: "0.714rem 1.071rem",
        borderRadius: "4px",
        transition: 'opacity .9s, padding-left .5s, padding-left .5s',
        icon: [
            EditIcon,
            TrashIcon,
        ]
    },
    imageUploader: {
        backgroundTransparentHover: 'transparent',
        backgroundColorHover: 'white'
    },
    topHeaderBackoffice: {
        displayFlex: 'flex',
        flexRow: 'row',
        justify: 'flex-end',
        gap: '10px',
        width: 'max-content',
        height: 'auto',
        mtop: '0px !important',
        mbottom: '0px',
        colorTitle: '#FFFFFF',
        marginTitle: '0',
        textTransform: 'uppercase',
        backgroundColor: primary,
        fontSize: '26px',
        padding: '18px 36px'
    },
    buttonsBackoffice: {
        backgroundColor: '#ffa700 !important',
        fontWeight: 'bold',
        colorFont: '#2c2e34 !important',
        fontSize: '15px',
        textTransform: 'none'
    }


};

export default theme;
