import { createSlice } from "@reduxjs/toolkit";
import { empresasService } from "@services/empresas";

const initial = {
  list: [],
};

export const empresasSlice = createSlice({
  name: "empresas",
  initialState: { ...initial },
  reducers: {
    resetEmpresas: (state, action: any) => {
      state.list = [...initial.list];
    },
    setempresas: (state, action: any) => {
      state.list = [...action.payload] as any;
    },
  },
});

export default empresasSlice.reducer;
export const { setempresas, resetEmpresas } = empresasSlice.actions;
