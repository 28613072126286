import { apps, contexts } from '@esfirus/n-backoffice'
import { useContext, useState } from 'react'
import { definitionsRequest, getRequests } from './utils/requests'
import { merge } from 'lodash'
import { getAllSectionStyles } from './theme/customInputStyle'
import PerfilWithProvider from './pages/Perfil/PerfilWithProvider';
import ConfigWithProvider from './pages/Config/ConfigWithProvider';
import { headers as SasBackofficeHeader } from './pages/Sas/index';
import { themedFields } from './theme/inputMapper'
import { backHomeHeaders, gotoAppHeaders } from './utils/customPagesHeads'

const BaseApp = () => {
    const { App } = apps
    const { AuthContext } = contexts;
    const AuthCtx = useContext(AuthContext)

    const { login } = AuthCtx

    login({ token: localStorage.getItem("ef-token"), user: localStorage.getItem("ef-user-external-data") })

    const [headers, setHeaders] = useState([])

    const [extraComponents] = useState({ PerfilWithProvider, ConfigWithProvider })

    const getHeaders = async () => {


        return definitionsRequest().then(definitions => {
            const nextHeaders = Object.keys(definitions.data).reduce(
                (reducer, modelName) => {
                    return [
                        ...reducer,
                        merge({}, definitions.data[modelName], {
                            options: {
                                requests: getRequests(
                                    definitions.data[modelName].options.route,
                                    definitions.data[modelName].options
                                ),
                                getRequests: getRequests,
                            },
                            sections: getAllSectionStyles(
                                definitions.data[modelName]
                            ),
                        }),
                    ]
                },
                []
            )

            


            const backofficeHeaders = [
                backHomeHeaders,
                SasBackofficeHeader,

            ]

            let heads = [
                ...backofficeHeaders,
                ...nextHeaders,
                gotoAppHeaders,

            ]

            setHeaders(heads)
            return heads
        })
    }
    return <App
        headers={headers}
        getHeaders={getHeaders}
        Components={extraComponents}
        themedFields={themedFields}
    // includeRouter={true}
    // customElements={window?.location?.pathname?.indexOf(frontUrls.backoffice) >= 0 ? themedComponentsBackoffice : themedComponents}
    // customFunctions={customFunctions}
    />
}

export default BaseApp;