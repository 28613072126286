import { response } from "@esfirus/n-backoffice";
import parseError from "./parseError";
import { apiConfig } from "../../../services/config";
const { parseResponse } = response;

const request = (
    urlO,
    { API_URL = apiConfig.basePath, ...options } = {}
) => {
    let url = urlO.replace("backoffice/", "")
    return fetch(`${API_URL}/${url}`, {
        ...options,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("ef-token") || ""}`,
        },
    }).then((res) => {
        return parseResponse(res, parseError);

    });
}


const requestFile = (
    url,
    { API_URL = apiConfig.basePath, ...options } = {}
) =>
    fetch(`${API_URL}/${url}`, {
        ...options,
        headers: {
            // "Content-Type": "application/json",
            // "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("ef-token") || ""}`,
        },
    }).then((res) => {
        return parseResponse(res, parseError);
    });

const requestImg = (
    url,
    { API_URL = apiConfig.basePath, ...options } = {}
) => {

    return fetch(`${API_URL}/${url}`, {
        ...options,
        headers: {
            // "Content-Type": "application/json",
            // "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("ef-token") || ""}`,
        },
    })
}

export { request, requestFile, requestImg };
