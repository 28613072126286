import axios from "axios"

import { apiConfig } from "./config"
import { Configuration } from "./swagger/configuration"
import { EsfirusTablePropsPagination } from "./hooks/useHandlerPaginationTable"

interface GetListFilters {
  from: number
  to: number
  company?: string | number
  customer?: string | number
  obra?: string | number
  originEmployee?: string
  targetEmployee?: string
  responsibleEmployee?: string
  status?: string
  type?: string
  pagination: EsfirusTablePropsPagination
}

class AvisosService {
  basePath = ""

  constructor(_apiConfig: Configuration) {
    this.basePath = (_apiConfig.basePath || '') as string
  }

  async getByUniqueId(id: string | number) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`
    try {
      const resp = await axios({
        baseURL: this.basePath + `/avisos/${id}`,
        headers: {
          Authorization: accessToken
        },
        method: "get",
      })
      return resp
    } catch (error) {
      apiConfig?.catchError(error)
    }
  }

  async getListBorradores({ from, to, company, customer, obra, originEmployee, targetEmployee, responsibleEmployee, status, type, pagination }: GetListFilters) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`
    try {
      const resp = await axios({
        baseURL: this.basePath + "/avisos-borradores",
        headers: {
          Authorization: accessToken
        },
        method: "get",
        params: {
          from,
          to,
          company_id: company === "" ? null : company,
          client_id: customer === "" ? null : customer,
          work_id: obra === "" ? null : obra,
          origin_employee_id: originEmployee === "" ? null : originEmployee,
          target_employee_id: targetEmployee === "" ? null : targetEmployee,
          responsible_employee_id: responsibleEmployee === "" ? null : responsibleEmployee,
          status: status === "" ? null : status,
          type: type === "" ? null : type,
          limit: pagination.sizePage,
          page: pagination.page
        }
      })
      return resp
    } catch (error) {
      apiConfig?.catchError(error)
    }
  }

  async getList({ from, to, company, customer, obra, originEmployee, targetEmployee, responsibleEmployee, status, type, pagination }: GetListFilters) {
    const accessToken = `Bearer ${localStorage.getItem("ef-token") as string}`
    try {
      const resp = await axios({
        baseURL: this.basePath + "/avisos",
        headers: {
          Authorization: accessToken
        },
        method: "get",
        params: {
          from,
          to,
          company_id: company === "" ? null : company,
          client_id: customer === "" ? null : customer,
          work_id: obra === "" ? null : obra,
          origin_employee_id: originEmployee === "" ? null : originEmployee,
          target_employee_id: targetEmployee === "" ? null : targetEmployee,
          responsible_employee_id: responsibleEmployee === "" ? null : responsibleEmployee,
          status: status === "" ? null : status,
          type: type === "" ? null : type,
          limit: pagination.sizePage,
          page: pagination.page
        }
      })
      return resp
    } catch (error) {
      apiConfig?.catchError(error)
    }
  }
}

export const avisosService = new AvisosService(apiConfig)
