import { getRequest } from "@screens/Backoffice/utils/requests";
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { env } from "@helpers/env";

const CODE_VERSION = "code-version";
// hook to check the app version on route change
function useVersionCheck() {
  // check if a version update (refresh) needed on route change
  let location = useLocation();
  useLayoutEffect(() => {
    (async () => {
      // if there is an update available and no state passed to route
      const { data } = await getRequest(
        "modules/sas/dockers/" + (env.REACT_APP_NAME ?? "microtech"),
        false,
        true
      );
      const versionNeeded = data?.hash;

      const isDifferentVersion =
        versionNeeded && versionNeeded != localStorage?.getItem(CODE_VERSION);
      // console.log(
      //   "versionNeeded",
      //   versionNeeded,
      //   "isDifferentVersion",
      //   isDifferentVersion,
      //   location
      // );
      if (!location.state && isDifferentVersion) {
        localStorage.setItem(CODE_VERSION, versionNeeded);
        window.location.reload(); // refresh the browser
      }
    })();
  }, [location]);

  //TODO: usar esto cuando tengmaos el compilado con hash
  // return env.REACT_APP_VERSION
  return localStorage?.getItem(CODE_VERSION);
}

export default useVersionCheck;
