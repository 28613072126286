import { createSlice } from "@reduxjs/toolkit";
import { reportsService } from "@services/reports";

const initial = {
  list: [],
  selectedDate: new Date().getTime(),
  selectedTypeHour: 0,
  search: '', 
  selectedEmpresa: 0,
  selectedMesAnio: (new Date().getMonth()+1)+"-"+new Date().getFullYear(), 
};

export const reportSlice = createSlice({
  name: "reports",
  initialState: { ...initial },
  reducers: {
    resetReports: (state, action: any) => {
      state.list = [...initial.list];
    },
    setReports: (state, action: any) => {
      state.list = [...action.payload] as any;
    },
    setSelectedDate: (state, action: any) => {
      state.selectedDate = new Date(action.payload).getTime()
    },
    setSelectedTypeHour: (state, action: any) => {
      state.selectedTypeHour = action.payload
    },
    setFilterBySearch: (state, action: any) => {
      state.search = action.payload
    },
    setSelectedEmpresa: (state, action: any) => {
      state.selectedEmpresa = action.payload
    },
    setSelectedMesAnio: (state, action: any) => {
      state.selectedMesAnio = action.payload
    },
  },
});

export default reportSlice.reducer;
export const { setReports, setSelectedDate, setSelectedTypeHour, resetReports, setFilterBySearch, setSelectedEmpresa, setSelectedMesAnio } = reportSlice.actions;

export const getReports = (months:any = 3) => (dispatch: any) => {
  reportsService
    .getLastMonths(months)
    .then((res: any) => dispatch(setReports((res.data as any)?.data as any)));
};

export const deleteReport = (id: string) => (dispatch: any) => {
  return reportsService
    .deleteReport(id)
};

