import { isAdmin, isSupervisor } from "@services/hooks/UserHook";

export const getInternalCompany = () => {
    const data = localStorage.getItem('ef-user-external-data');
    if (!data || data === '') {
        return 1;
    }

    const parsed = JSON.parse(data);
    return parsed.internal[0].empresa;
}

export const getExternalCompany = () => {
    const data = localStorage.getItem('ef-user-external-data');
    const parsed = JSON.parse(data || '{}');
    if (!parsed.external || !parsed.external.length) {
        return null;
    }

    return parsed.external[0].empresa;
}

export const getInternalReference = () => {
    const data = localStorage.getItem('ef-user-external-data');
    const parsed = JSON.parse(data || '{}');
    if (!parsed.internal || !parsed.internal.length) {
        return null;
    }

    return parsed.internal[0].empleado;
}

export const getExternalReference = () => {
    const data = localStorage.getItem('ef-user-external-data');
    const parsed = JSON.parse(data || '{}');
    if (!parsed.external || !parsed.external.length) {
        return null;
    }

    return parsed.external[0].empleado;
}

export const getAvailableCompanies = (justAsociated = false) => {
    const data = localStorage.getItem('ef-user-external-data');
    if (!data || data === '') {
        return [];
    }

    const admin = !justAsociated && (isAdmin() || isSupervisor())
    if (admin) {
        return "*"
    }

    const parsed = JSON.parse(data);
    return [
        ...parsed.external.map((e: any) => e.empresa),
        ...parsed.internal.map((e: any) => e.empresa)
    ];
}

export const getReferenceByCompany = (company: string) => {
    const data = localStorage.getItem('ef-user-external-data');
    if (!data || data === '') {
        return;
    }

    const parsed = JSON.parse(data);
    const empresasUser = [...parsed.internal, ...parsed.external].flat()
    return empresasUser.find((d: any) => d.empresa == company)?.empleado || 0;
}