import styled, { css } from 'styled-components';


const globals = {
  margin: '5px'
};

export const Container = styled.div`
  width: 100%;
  background-color: #EBEEF4;
  display: flex;
  flex-direction: column;
  gap: 40px;
  box-sizing: border-box;
  padding: 40px;

  @media screen and (max-width: 1024px) {
    padding: 0 50px 34px 50px;
  };

  @media screen and (max-width: 1023px) {
    padding: 0 30px 34px 30px;
  };
`;

export const Banner = styled.img`
  margin-top: 45px;
  width: 100%;
  height: 215px;
  object-fit: scale;
`;

export const BaseText = styled.span`
  color: white;
`;

export const SubContainer1 = styled.div`
display: grid;
width: auto;
grid-column-gap: 40px;
grid-row-gap: 40px;
grid-template-columns: repeat(4, 1fr);
padding-top: 4em;
padding-bottom: 4em;

  @media screen and (max-width: 1024px) {
    grid-column-gap: 17px;
    padding-top: 6em;
    padding-bottom: 2em;
  };

  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  };
`;

export const SubContainer1TitleWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const SubContainer1TitlePart1 = styled(BaseText)`
  font-size: 25px;
  color: black;
`;

export const SubContainer1TitlePart2 = styled(BaseText)`
  font-size: 20px;
  color: #92949A;
  margin-top: 6px;
`;

export const SubContainer2 = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(277px, 1fr));
  grid-row-gap: 20px;
  grid-column-gap: 19px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  };
`;

export const SubContainer2Title = styled(BaseText)`
  font-size: 18px;
  font-weight: bold;
  color: black;
  grid-column: 1/-1;
  margin: 21px 0 9px 0;
`;

///////////////////////////////////////////////////////////////////////////////

export const Item1 = styled.div`
  width: calc(100% - 6px);
  height: 413px;
  padding: 17px 12px 7.8px 24px;
  margin-right: 6px;
  border-radius: 5px;
  overflow: hidden;
  grid-area: item1;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: grid;
  grid-column-gap: 15.7px;
  grid-template-columns: repeat(6, min-content) 1fr;
  grid-template-rows: repeat(2, min-content) 29.5px repeat(2, min-content) 1fr;
  grid-template-areas:
    'title1 title1 title1 title1 title1 title1'
    'title2 title2 title2 title2 title2 title2'
    'line   line   line   line   line   line  '
    'icon1  icon2  icon3  icon4  icon5  icon6 '
    'button button button button button button'
    'desc   desc   desc   desc   desc   desc  '
  ;

  @media screen and (max-width: 1024px) {
    padding: 8px 5.6px 4px 22px;
    width: 100%;
    margin: 0;
    grid-template-rows: repeat(2, min-content) 31.3px repeat(2, min-content) 1fr;
  };

  @media screen and (max-width: 1023px) {
    height: 290px;
    padding: 19.4px 12px 9.2px 23px;
  };
`;

export const Item1Title1 = styled(BaseText)`
  font-size: 13px;
  grid-area: title1;
  opacity: 0.8;
  z-index: 1;
`;

export const Item1Title2 = styled(BaseText)`
  font-size: 18px;
  font-weight: bold;
  grid-area: title2;
  margin: 6px 0 0 0;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    font-size: 20px;
    margin: 4px 0 0 0;
  };

  @media screen and (max-width: 1023px) {
    font-size: 18px;
  };
`;

export const Item1HomeImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0 12px 7.8px 0;
  width: 43%;
  z-index: 0;

  @media screen and (max-width: 1024px) {
    margin: 0 5.6px 4px 0;
    height: 234px;
    width: auto;
  };

  @media screen and (max-width: 1023px) {
    height: calc(100% - 36.6px);
    margin: 27.4px 12px 9.2px 0;
  };
`;

export const Item1Line = styled.hr`
  height: 1px;
  width: 142.8%;
  background-color: white;
  grid-area: line;
  border: none;
  margin: 0;
  position: absolute;
  opacity: 0.8;
  margin: 11.5px 0 17px 0;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    margin: 10.8px 0 19.5px 0;
    width: 100%;
  };
`;

export const Item1Icon = styled.img`
  grid-area: ${({ itemPosition }) => `icon${itemPosition}`};
  height: 20px;
  width: min-content;
  z-index: 1;
`;

export const Item1Description = styled(BaseText)`
  grid-area: desc;
  margin-top: 16px;
  font-size: 12px;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    font-size: 13px;
    margin-top: 14px;
  };

  @media screen and (max-width: 1023px) {
    font-size: 10px;
  };
`;

export const item1ButtonStyles = css`
  font-size: 18px;
  grid-area: button;
  margin-top: 42px;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
    font-size: 15px;
  };

  @media screen and (max-width: 1023px) {
    font-size: 18px;
  };
`;

///////////////////////////////////////////////////////////////////////////////

export const Item2 = styled.div`
  width: 100%;
	height: 170px;
  background-color: gray;
  background: rgb(44,46,52);
  background: linear-gradient(135deg, rgba(44,46,52,1) 52%, rgba(103,103,105,1) 100%);
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 17px 0 17px 12px;
  gap: 9px;
  border-radius: ${globals.margin};

  @media screen and (max-width: 1024px) {
    padding: 9px 12px 13.5px 12px;
    gap: 11px;
  };

  @media screen and (max-width: 1023px) {
    height: 141px;
    padding: 22px 15px;
    gap: 9px;
  };
`;

export const Item2Img = styled.img`
  position: absolute;
  bottom: 16px;
  right: 0;

  @media screen and (max-width: 1024px) {
    bottom: 0;
  };

  @media screen and (max-width: 1023px) {
    height: 68%;
    bottom: 14px;
  };
`;

export const Item2Title = styled(BaseText)`
  font-size: 25px;
  font-weight: bold;
  z-index: 2;

  @media screen and (max-width: 1023px) {
    font-size: 13px;
  };
`;

export const Item2OverflowWrapper = styled.div`
  width: 56.56%;
  line-height: 1;
  z-index: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    width: 66.67%;
  };

  @media screen and (max-width: 1023px) {
    width: 45.73%;
    color: #FFFFFF;
  };
`;

export const Item2Description = styled(BaseText)`
  font-size: 12px;

  @media screen and (max-width: 1023px) {
    font-size: 10px;
  };
`;

export const item2ButtonStyles = css`
  z-index: 2;
  margin-top: auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  };

  @media screen and (max-width: 1023px) {
    width: 45.73%;
  };
`;

///////////////////////////////////////////////////////////////////////////////

export const Item3 = styled.div`
  width: 100%;
	height: 157px;
  background-color: white;
  border-radius: ${globals.margin};
  border: rgba(218,224,236,1) solid 1px;
  padding: 11px 17.4px 13.6px 11px;
  box-sizing: border-box;
  position: relative;
`;

export const Item3Title = styled(BaseText)`
  font-size: 14px;
  font-weight: bold;
  color: black;
`;

export const Item3Description = styled(BaseText)`
  font-size: 12px;
  color: black;
  margin-top: 10px;
`;

export const Item3OverflowWrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2;
`;

export const Item3Content = styled.div`
  width: 52%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const Item3Image = styled.img`
  position: absolute;
  right: 13.6px;
  bottom: 11px;
`;

export const item3ButtonStyles = css`
  margin-top: auto;
`;

///////////////////////////////////////////////////////////////////////////////

export const Box = styled.div`
  margin: 20px auto;
  padding: 10px;
  display:flex;
  flex-direction: column;
  width:100%;
  align-items: center;
`;
export const Title = styled.p`
  font-size: 24px;
  width: 100%;
  margin: 10px auto;
  background: red;
  color: white;
  text-align: center;
`;
export const Circle = styled.button`
  width: ${(p) => p.circleNum ? p.circleNum : '50px'};
  height: ${(p) => p.circleNum ? p.circleNum : '50px'};
  background: orange;
  border-radius: 50%;
  outline:none;
  border:none;
  cursor:pointer;
`;
export const Subtitle = styled.p`
  font-size: 22px;
  font-weight: 500;
  margin: 0;
`;
export const Text = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin: 0 3px;
  text-align: center;
  min-width: 100px;
`;

export const Button = styled.button`
  background: orange;
  border: none;
  font-weight: 600;
  padding: 2px 10px;
  width: auto;
  outline: inherit;
  cursor: pointer;
  margin-bottom: 6px;
`;

export const WrapButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
`;
export const WrapLast = styled.div`
  width: 100%;
`;

export const Divider = styled.div`
  min-width:40%;
  height: 1px;
  background: orange;
  margin-bottom:15px;
`;
export const WrapCircles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  max-width:500px;
  margin: 0 auto 1rem auto;
`;

