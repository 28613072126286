import './EsfirusSearch.scss'

export default function EsfirusSearch(props: EsfirusSearchProps) {
    return <div className='ef-search'>
        <input
            value={props.value}
            placeholder={props.placeholder} onChange={(e) => {
                if (props.change) {
                    props.change(e.target.value);
                }
            }
            }>
        </input>
    </div>
}

export interface EsfirusSearchPropsOption {
    label: string;
    value: any;
}
export interface EsfirusSearchProps {
    placeholder?: string;
    value?: string;
    change?: Function
}