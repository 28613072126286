import { createSlice } from "@reduxjs/toolkit";
import { tipoHoraService } from "@services/tipoHora";

const initial = {
  list: [],
  listByEmpleado: [],
};

export const ObraSlice = createSlice({
  name: "tipoHora",
  initialState: { ...initial },
  reducers: {
    resetTipoHora: (state, action: any) => {
      state.list = [...initial.list];
      state.listByEmpleado = [...initial.listByEmpleado];
    },
    setHoras: (state, action: any) => {
      state.list = [...action.payload] as any;
    },
    setHorasByEmpleado: (state, action: any) => {
      state.listByEmpleado = [...(action.payload ?? [])] as any;
    },
  },
});

export default ObraSlice.reducer;
export const { setHoras, setHorasByEmpleado, resetTipoHora } = ObraSlice.actions;

