import { getReports } from "@store/slices/reports";
import { useSelector } from "react-redux";
import { isEmpleado } from "./UserHook";


const useReportsGet = () => {
    const configuration = useSelector((state) => state.configurations.config);
    const month = (isEmpleado() ? configuration?.report?.reportMeses : configuration?.report?.reportMesesAdmin) ?? 1;
    const fetch = () => getReports(month)
    return fetch
}

export default useReportsGet;