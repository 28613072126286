import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Modal } from "@mui/material";

import EsfirusButton from "../../../../components/ui/Button/EsfirusButton";
import EsfirusTextInput from "../../../../components/ui/Text-input/EsfirusTextInput";
import { Articulo } from "@components/FormParte/BBs/LineasArticulo/LineasArticulo";

import "../ModalNewObra/ModalNewObra.scss";

interface ModalNewArticuloProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  selectArticulo: (articulo: Articulo) => Promise<void>;
}

interface FilterInputProps {
  label: string;
  value: string;
  placeholder?: string;
  onChange: (e: any) => void
}

const FilterInput = ({ label, value, placeholder, onChange }: FilterInputProps) => {
  return (
    <Grid item md={6} xs={12}>
      <label>{label}</label>
      <EsfirusTextInput
        value={value || ""}
        onChange={onChange}
        placeholder={placeholder || label}
        fullWidth
      />
    </Grid>
  )
}

function ModalNewArticulo({ open, setOpen, selectArticulo }: ModalNewArticuloProps) {
  const gastosServicios = useSelector((state) => (state as any).articulosGastosServicios).list;
  const empresas = useSelector((state) => (state as any).empresas).list;
  const selectedobra = useSelector((state) => (state as any).selectedobra).obra;

  const [articulosList, setArticulosList] = useState<any>([]);

  const [filterDescription, setFilterDescription] = useState('');
  const [filterArticulo, setFilterArticulo] = useState('');
  const [filterReferencia, setFilterReferencia] = useState('');
  const [filterAuxiliar, setFilterAuxiliar] = useState('');
  const [filterMarca, setFilterMarca] = useState('');

  const [currentArticulo, setCurrentArticulo] = useState<Articulo>({
    Articulo: "",
    Ambito: 0,
    Descripcion: "",
    Auxiliar: "",
    Marca: "",
    Gasto: false,
    Coste: 0,
    Publicable: false,
    Referencia: "",
    Servicio: false,
    Tarifa: 0,
    data_sales_pricing: {}
  });

  useEffect(() => {
    const collectData = async () => {
      const empresaSelected = empresas.find((emp: any) => emp.Empresa === selectedobra.company)
      const articulos: Articulo[] = gastosServicios.filter(
        (el: Articulo) => !el.Gasto && !el.Servicio && el.Ambito == empresaSelected.Ambito
      ) ?? [];

      const resultArticulos = articulos.
        filter((articulo: Articulo) => filterDescription !== '' ? articulo?.Descripcion?.toLowerCase()?.includes(filterDescription.toLowerCase()) : articulo)?.
        filter((articulo: Articulo) => filterArticulo !== '' ? articulo?.Articulo?.toString()?.toLowerCase()?.includes(filterArticulo?.toLowerCase()) : articulo)?.
        filter((articulo: Articulo) => filterReferencia !== '' ? articulo?.Referencia?.toString()?.toLowerCase()?.includes(filterReferencia?.toLowerCase()) : articulo)?.
        filter((articulo: Articulo) => filterAuxiliar !== '' ? articulo?.Auxiliar?.toString()?.toLowerCase()?.includes(filterAuxiliar?.toLowerCase()) : articulo)?.
        filter((articulo: Articulo) => filterMarca !== '' ? articulo?.Marca?.toString()?.toLowerCase()?.includes(filterMarca?.toLowerCase()) : articulo).
        map((articulo: Articulo) => ({ ...articulo, descripcion_list: `${articulo.Articulo} - ${articulo.Descripcion} - ${articulo.Marca}` }));

      setArticulosList(resultArticulos)
    }

    collectData()
  }, [gastosServicios, filterDescription, filterArticulo, filterReferencia, filterAuxiliar, filterMarca])

  const acceptClose = async () => {
    await selectArticulo(currentArticulo);
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="new-obra-modal">
        <div className="header">
          <h5 className="text-center">Selecciona el articulo</h5>
        </div>
        <div className="body">
          <p className="text-center-mobile">
            Filtra el listado de articulos mediante los siguientes campos:
          </p>

          <Grid container spacing={4} md={12}>
            <FilterInput label="Descripción" value={filterDescription} onChange={(e) => setFilterDescription(e.target.value)} />
            <FilterInput label="Articulo" value={filterArticulo} onChange={(e) => setFilterArticulo(e.target.value)} />
            <FilterInput label="Referencia" value={filterReferencia} onChange={(e) => setFilterReferencia(e.target.value)} />
            <FilterInput label="Auxiliar" value={filterAuxiliar} onChange={(e) => setFilterAuxiliar(e.target.value)} />
            <FilterInput label="Marca" value={filterMarca} onChange={(e) => setFilterMarca(e.target.value)} />
          </Grid>

          <Grid container spacing={4} md={12}>
            <Grid className="mt-20" item md={12} xs={12}>

              <div className="obra-container">
                <div className="scroll">
                  {!articulosList.length && (
                    <div>No hay artículos</div>
                  )}
                  {articulosList.map((articulo: any, index: any) => {
                    return (
                      <div
                        key={`modal-new-articulo-articulo-${index}`}
                        className={
                          "obra-item" +
                          (currentArticulo.Articulo === articulo.Articulo
                            ? " selected"
                            : "")
                        }
                        onClick={() => {
                          setCurrentArticulo(articulo)
                        }}
                        onDoubleClick={async () => {
                          await acceptClose()
                        }}
                      >
                        {articulo.descripcion_list || "--"}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Grid>

          </Grid>

          <div className="button-container">
            <EsfirusButton
              onClick={async () => await acceptClose()}
              fullWidth
              color="secondary"
            >
              Aceptar
            </EsfirusButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalNewArticulo;
