import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from './style';
import { getUserName } from '@services/hooks/UserHook';
import Module from '@screens/Modules/Module';


const homeItems = [

  {
    title: 'Usuarios',
    description: 'Localiza, crea, edita y borra usuarios de forma ágil y cómoda tus usuarios',
    url: '/backoffice/users'
  },

  {
    title: 'Configuraciones',
    description: 'Configuraciones completas de la aplicación',
    url: '/backoffice/configs'
  }

]

// const Card2 = ({ itemPosition, url, img, title, description }) => {
//   const history = useNavigate();


//   const linkTo = (url) => {
//     history(url)
//   };

//   return (
//     <S.Item2
//       itemPosition={itemPosition}
//       style={{ cursor: url ? 'pointer' : 'default' }}
//       onClick={() => url ? linkTo(url) : undefined}
//     >
//       <S.Item2Title>{title}</S.Item2Title>
//       <S.Item2OverflowWrapper>
//         <S.Item2Description>{description}</S.Item2Description>
//       </S.Item2OverflowWrapper>
//       <S.Item2Img src={img} />

//     </S.Item2>
//   )
// };


const HomeBackoffice = () => {
  const username = getUserName()

  return (
    <S.Container>
      <S.SubContainer1TitleWrapper>
        <S.SubContainer1TitlePart1>Hola{username && `, ${username}`}.</S.SubContainer1TitlePart1>
        <S.SubContainer1TitlePart2>¿Qué quieres hacer hoy?</S.SubContainer1TitlePart2>
      </S.SubContainer1TitleWrapper>
      <div className="modules_backoffice">
        {
          homeItems.map((item, index) => (
            <Module key={`module-home-backoffice-${index}`} title={item.title} url={item.url} description={item.description} />
          ))
        }
      </div>
    </S.Container>
  );
};

export default HomeBackoffice;
